/**
 * SIGFU REST DATA API
 * Serveur d\'api en NodeJs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DossierDto { 
    dossier: string;
    nom_localite: string;
    nom_lotissement: string;
    no_ilot: string;
    no_lot: string;
    superficie: number;
    superficie_unite: string;
    first_name?: string;
    last_name?: string;
    full_name?: string;
}

