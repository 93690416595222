<ng-container *ngIf="!readonly else readonlyMode">
  <div *ngIf="displayed" class="flex-row ion-margin-vertical"
       [ngClass]="{'mobile': plateforme.isMobile()}">
    <ion-fab-button class="icone-telechargement" >
      <ion-icon *ngIf="!lecture" [ngClass]="{'color-green': isGreen,'color-blue': isBlue}" src="./assets/svg/download.svg" (click)="modifierFichier()"></ion-icon>
      <ion-icon *ngIf="lecture" [ngClass]="{'color-green': isGreen,'color-blue': isBlue}" src="./assets/svg/view-source.svg" (click)="visualiserFichier()"></ion-icon>
    </ion-fab-button>
    <input id="{{inputId}}" type="file" [accept]="fileAccept"
           (change)="fileBrowseHandler($event.target?.['files'])"/>
    <div>
      <div class="flex-row">
        <!-- <p [ngClass]="{'typo-14': plateforme.isDesktop(), 'typo-11': plateforme.isMobile()}" *ngIf="!isMultiOptions">{{label}}</p> -->
        <p [ngClass]="{'typo-14': plateforme.isDesktop(), 'typo-11': plateforme.isMobile()}" *ngIf="!isMultiOptions" [innerHTML]="label"></p>


        <ion-radio-group *ngIf="isMultiOptions"
        (ionChange)="changeType()"
        [formControl]="fcOption"
        >
        <ion-row>
          <ion-col  *ngFor="let typeName of this.depoLigneSourceDto.lstTypeName.split('||'); let indexArray = index; let last = last" >
            <ion-item lines="none">
              <ion-label class="typo-14"
                >{{typeName}}<b>{{ !last ? '   OU ' : '' }}</b></ion-label
              >
              <ion-radio
                class="item-left"
                slot="start"
                [value]="indexArray"
              ></ion-radio>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-radio-group>


        <ion-fab-button *ngIf="viewerEnable && (fichiers[0]?.name || nomFichier) && !lecture && typeFile !== 'contactGuf' && typeFile !== 'fichierFrais'" class="icone-edition">
          <ion-icon [ngClass]="{'color-green': isGreen,'color-blue': isBlue}"
                    [src]="imageVisible ? './assets/svg/view-barre.svg' : (lecture ? './assets/svg/view-source.svg' : './assets/svg/view.svg')"
                    (click)="visualiserFichier()"></ion-icon>
        </ion-fab-button>
        <ion-fab-button *ngIf="fichiers[0]?.name || nomFichier" class="icone-edition">
          <ion-icon [ngClass]="{'color-green': isGreen,'color-blue': isBlue}" src="./assets/svg/delete.svg" (click)="supprimerFichier()"></ion-icon>
        </ion-fab-button>
      </div>
      <div *ngIf="fichiers[0]?.name || nomFichier" class="flex-row">
        <p [ngClass]="{'typo-14-green': plateforme.isDesktop(), 'typo-12-green': plateforme.isMobile()}">
          {{nomFichier ? nomFichier : fichiers[0]?.name}}</p>
        <ion-icon *ngIf="fichiers[0]?.name || nomFichier"
                  class="icone-vert ion-margin-start"
                  src="./assets/svg/check_circle.svg"></ion-icon>
      </div>
        <p *ngIf="invalidFile && typeFile === ''" class="typo-14-red">
          Fichier invalide : Merci de fournir un fichier au format png, jpeg de 10Mo maximum.</p>

          <p *ngIf="(invalidFile && typeFile === 'contactGuf') || (invalidFile && typeFile === 'fichierFrais')" class="typo-14-red">
            Fichier invalide : Merci de fournir un fichier au format xlsx de 10Mo maximum.</p>
    </div>
  </div>
  <div>
    <img *ngIf="imageVisible" class="image" src="{{imageSource}}" alt="Photo transmise par l'utilisateur">
  </div>
</ng-container>

<!-- A SUPPRIMER ? -->
<ng-template #readonlyMode>
  <div class="file-input-readonly flex-row">

    <p class="typo-14">{{nomFichier}}</p>

    <ion-icon *ngIf="nomFichier" class="icone-vert" src="./assets/svg/check_circle.svg"></ion-icon>
  </div>
</ng-template>
