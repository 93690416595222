import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {PopoverCreationCompteProComponent} from './popover-creation-compte-pro.component';



@NgModule({
  declarations: [PopoverCreationCompteProComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    PopoverCreationCompteProComponent,
  ]
})
export class PopoverCreationCompteProModule { }
