import {PopoverProps} from '../../../interfaces/popover-props';
import {PopoverController} from '@ionic/angular';
import {PopoverContentComponent} from '../popover-content/popover-content.component';
import {Subscription} from 'rxjs';
import {AbstractIonContent} from '../../content/abstract-ion-content';
import {ActionPopoverService} from '../popover-creation-compte-content/service/action-popover.service';
import {PopoverCreationCompteComponent} from '../popover-creation-compte-content/popover-creation-compte.component';
import {PopoverSendCodeComponent} from '../popover-send-code/popover-send-code.component';
import {
  PopoverModificationMotDePasseComponent
} from '../popover-modification-mot-de-passe/popover-modification-mot-de-passe.component';
import {
  PopoverErreurCreationCompteComponent
} from '../popover-erreur-creation-compte/popover-erreur-creation-compte.component';
import {
  PopoverErreurMotDePasseOublieComponent
} from '../popover-erreur-mot-de-passe-oublie/popover-erreur-mot-de-passe-oublie.component';
import {PopoverErreurConnexionComponent} from '../popover-erreur-connexion/popover-erreur-connexion.component';
import {PopoverGenericComponent} from '../popover-generic/popover-generic.component';
import { Router } from '@angular/router';

export class AbstractPopover extends AbstractIonContent {

  constructor() {
    super();
  }

  /**
   * Ouvre le popover de confirmation de création de compte.
   *
   * @param popoverController
   * @param actionPopoverService
   */
  public static async presentConfirmationCreationPopover(
    popoverController: PopoverController,
    actionPopoverService: ActionPopoverService,
    router: Router,
    username: String): Promise<void> {
    const popover = await popoverController.create({
      component: PopoverCreationCompteComponent,
      componentProps: {
        username 
      },
      backdropDismiss: false,
    });

    await popover.present();

    const actionSubscription: Subscription = actionPopoverService.actionPopoverSubject.subscribe(() => {
      popover.dismiss();
      router.navigateByUrl('/login');
      actionSubscription.unsubscribe();
    });
  }

  /**
   * Ouvre le popover d'une erreur lors de la création de compte
   */
  public static async presentErrorCreationPopover(popoverController: PopoverController): Promise<void> {
    const popup = await popoverController.create({
      component: PopoverErreurCreationCompteComponent,
    });
    await popup.present();
  }

  /**
   * Ouvre le popover d'une erreur lors de la création de compte
   */
  public static async presentErrorMotDePasseOubliePopover(popoverController: PopoverController): Promise<void> {
    const popup = await popoverController.create({
      component: PopoverErreurMotDePasseOublieComponent,
    });
    await popup.present();
  }

  /**
   * Ouvre le popover confirmant l'envoi d'un nouveau code
   */
  public static async presentConfirmationSendCode(popoverController: PopoverController): Promise<void> {
    const popup = await popoverController.create({
      component: PopoverSendCodeComponent,
    });
    await popup.present();
  }

  /**
   * Ouvre le popover confirmant la modification du mot de passe
   */
  public static async presentConfirmationPassword(popoverController: PopoverController): Promise<void> {
    const popover = await popoverController.create({
      component: PopoverModificationMotDePasseComponent,
    });
    await popover.present();
  }

  /**
   * Ouvre le popover astuce générique avec les paramètres souhaités.
   *
   * @param popoverController
   * @param props
   */
  public async presentPopover(popoverController: PopoverController, props: PopoverProps): Promise<void> {
    const popover = await popoverController.create({
      component: PopoverContentComponent,
      componentProps: props,
    });
    await popover.present();
  }

  /**
   * Ouvre le popover informant du mauvais profil sélectionné à la connexion.
   *
   * @param popoverController
   * @param profil
   */
  public async presentErreurConnexion(popoverController: PopoverController, profil: string): Promise<void> {
    const popover = await popoverController.create({
      component: PopoverErreurConnexionComponent,
      componentProps: {profil: [profil]},
      backdropDismiss: false,
    });
    await popover.present();
  }

  /**
   * Ouvre le popover generic.
   *
   * @param popoverController
   * @param props
   */
  public async showPopoverGeneric(popoverController: PopoverController, props: PopoverProps): Promise<void> {
    const popover = await popoverController.create({
      component: PopoverGenericComponent,
      componentProps: props,
    });
    await popover.present();
  }

  /**
   * Ouvre le popover generic.
   *
   * @param popoverController
   * @param props
   */
  public async showPopoverGenericWithRes(popoverController: PopoverController, props: PopoverProps): Promise<any> {
    const popover = await popoverController.create({
      component: PopoverGenericComponent,
      componentProps: props,
    });
    await popover.present();
    return popover;
  }

}
