import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'mclu-popover-erreur-creation-compte',
  templateUrl: './popover-erreur-creation-compte.component.html',
  styleUrls: ['./popover-erreur-creation-compte.component.scss'],
})
export class PopoverErreurCreationCompteComponent {

  constructor(private popover: PopoverController,
              private router: Router) { }

  public dismiss(): void {
    this.router.navigateByUrl('/creation-compte');
    this.popover.dismiss();
  }

}
