import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { KeycloakService } from 'src/app/services/keycloak/keycloak.service';

@Component({
  selector: 'mclu-popover-generic',
  templateUrl: './popover-generic.component.html',
  styleUrls: ['./popover-generic.component.scss'],
})
export class PopoverGenericComponent implements OnInit {

  @Input()
  public titre;

  @Input()
  public couleur='green';

  @Input()
  public logout= false;


  constructor(
    public popoverController: PopoverController,
    public keycloakService: KeycloakService
  ) {
  }

  ngOnInit() {
  }

  public closePopup() {
    this.popoverController.dismiss();
    if (this.logout===true) {
      this.keycloakService.logout();
    }
  }

}
