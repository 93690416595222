<div class="popover-infos-creation-compte">
  <div class="btn-close">
    <ion-icon slot="end" src="./assets/svg/close-popup.svg" (click)="closePopup()"></ion-icon>
  </div>
  <div class="flex-row-center title-popup">
    <ion-icon src="assets/svg/check_green.svg" ></ion-icon>
    <div class="typo-24-grey" [innerHTML]="titre"></div>
  </div>
    <mclu-activation-compte></mclu-activation-compte>
</div>
