import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlateformeService {

  private readonly TABLET_MIN_WIDTH = 768; // Minimum width for tablets in pixels

  /**
   * Constructeur.
   *
   * @param platform
   */
  constructor(private platform: Platform) {}

  /**
   * Retourne vrai si la plateforme est Mobile ou mobile web.
   *
   * @returns
   */
  public isMobile(): boolean {
    return (this.platform.is('capacitor') || this.platform.is('mobileweb'));
  }

  /**
   * Retourne vrai si la plateforme est Mobile ou mobile web.
   *
   * @returns
   */
  public isIOS(): boolean {
    return (this.platform.is('ios'));
  }

  /**
   * Retourne vrai si la plateforme est Mobile ou mobile web.
   *
   * @returns
   */
  public isMobileNative(): boolean {
    return (this.platform.is('capacitor') || !this.platform.is('mobileweb'));
  }

  /**
   * Retourne vrai si la plateforme est une tablette.
   *
   * @returns
   */
  public isTablet(): boolean {
    const isTablet = this.platform.is('tablet');
    const width = window.innerWidth;

    // Considérant un appareil comme une tablette s'il est identifié comme une tablette par la plateforme ou si la largeur est supérieure au seuil
    return isTablet || (width >= this.TABLET_MIN_WIDTH && this.platform.is('mobile'));
  }

  /**
   * Retourne vrai si la plateforme est DESKTOP ou une tablette.
   *
   * @returns
   */
  public isDesktop(): boolean {
    return (!this.platform.is('capacitor') && !this.platform.is('mobileweb')) || this.isTablet();
  }
}
