import { Injectable } from '@angular/core';
import { PlateformeService } from '../plateforme/plateforme.service';

@Injectable({
  providedIn: 'root'
})
export class CouleurService {

  constructor(public plateforme: PlateformeService) { }


  public getYellow(forceNormal: boolean=false): string {
    if (this.plateforme.isDesktop()===true || forceNormal===true)
    {
      return window.getComputedStyle(document.body).getPropertyValue('--mclu-yellow');
    }
    else{
      return window.getComputedStyle(document.body).getPropertyValue('--mclu-yellow-mobile');
    }
  }

  public getRed(forceNormal: boolean=true): string {
    if (this.plateforme.isDesktop()===true || forceNormal===true)
    {
      return window.getComputedStyle(document.body).getPropertyValue('--mclu-red');
    }
    else{
      return window.getComputedStyle(document.body).getPropertyValue('--mclu-red-mobile');
    }
  }

  public getBlue(forceNormal: boolean=true): string {
    if (this.plateforme.isDesktop()===true || forceNormal===true)
    {
      return window.getComputedStyle(document.body).getPropertyValue('--mclu-blue');
    }
    else{
      return window.getComputedStyle(document.body).getPropertyValue('--mclu-blue-mobile');
    }
  }

  public getGreen(forceNormal: boolean=true): string {
    if (this.plateforme.isDesktop()===true || forceNormal===true)
    {
      return window.getComputedStyle(document.body).getPropertyValue('--mclu-green');
    }
    else{
      return window.getComputedStyle(document.body).getPropertyValue('--mclu-green-mobile');
    }
  }

  public getOrange(forceNormal: boolean=true): string {
    return window.getComputedStyle(document.body).getPropertyValue('--mclu-2-orange');
  }
}

