// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: true,
  devMobile: false,

  // Recette
  //isSif: false,
  //keycloakUrl: 'https://sigfu-keycloak-dev.geofit.fr/auth',
  //keycloakRealm: 'sigfu',
  //keycloakClient: 'portail-gp-mobile-front',
  //keycloakClientPro: 'portail-gp-mobile-front-pro',

  // PREPROD
  // isSif: true,
  // keycloakUrl: 'http://160.155.87.11/keycloak',
  // keycloakRealm: 'sigfu-realm',
  // keycloakClient: 'sigfu-portail-mobile',
  // keycloakClientPro: 'sigfu-portail-mobile-pro',
  // apiBasePath: 'http://160.155.87.11/api-sigfu-portail',
  // apiFileBasePath: '/api-sigfu-portail',
  // apiFileBasePathDoawnload: '/api-sigfu-portail/api-sigfu-portail',
  // apiDataBasePath: 'http://160.155.87.11/api-sigfu-portail-data',
  // keycloakLoginPrefixMobile: 'ci.gouv.sigfu.portail:',

  // numVersionApp: '2.3.2210',

  // app: {
  //   splashScreenDelay: 1000
  // },
  // directus: {
  //   apiBasePath: 'http://160.155.87.11/directus/',
  //   apiFileBasePath: 'http://160.155.87.11/directus/',
  //   directusUrl: 'http://160.155.87.11/directus/admin/login',
  // },
  // gaTrackingId: 'G-7ZG8B0BG2L',
  // cartoActif: true,

  secureStorageKey: 'fcrpcomflqsfljcnyrhgrcnmmvivamexihidgnancvjjesrxlzjwxbhpwmfdwrfunvqyqzvurfesbeotvjsofgzcwqzzmrtavwzzgswvyyjnnmbpcgdhdapyjaxxqisn',
  //PROD
  isSif: true,
  apiBasePath: 'https://sigfu.gouv.ci/api-sigfu-portail',
  apiFileBasePath: '/api-sigfu-portail',
  apiFileBasePathDoawnload: '/api-sigfu-portail',
  apiDataBasePath: 'https://sigfu.gouv.ci/api-sigfu-portail-data',
  keycloakUrl: 'https://sigfu.gouv.ci/auth/',
  keycloakRealm: 'sigfu-realm',
  keycloakClient: 'sigfu-portail-mobile',
  keycloakClientPro: 'sigfu-portail-mobile-pro',
  keycloakLoginPrefixMobile: 'ci.gouv.sigfu.portail:',

  numVersionApp: '2.3.2210',

  app: {
    splashScreenDelay: 1000
  },

  directus: {
    apiBasePath: 'https://sigfu.gouv.ci/directus',
    apiFileBasePath: 'https://sigfu.gouv.ci/directus',
    directusUrl:'http://172.20.101.20:8055/admin/login'
  },

  cartoActif: false,

  gaTrackingId: 'G-KKGQKQ3881',
  //


  // keycloakUrl: 'http://localhost:8084/auth',
  // keycloakRealm: 'sigfu-realm',
  // keycloakClient: 'sigfu-portail-mobile',
  // keycloakClientPro: 'sigfu-portail-professionnel',

  //  keycloakUrl: 'http://localhost:8080/auth',
  //  keycloakRealm: 'client-geofit',
  //  keycloakClient: 'geofit-mclu-mobile-beta',
  //  keycloakClientPro: 'geofit-mclu-mobile-pro',

  //apiBasePath: 'https://sigfu-portal-dev.geofit.fr',
  //apiBasePath: 'http://localhost:8100',
  //apiDataBasePath: 'https://sigfu-portal-dev.geofit.fr',
  //apiDataBasePath: 'https://sigfu-portal-innola.geofit.fr',
  //apiDataBasePath: 'http://localhost:3001',

  //transactions: ['ACDHL','ACDLO','ADL','ACDREG','APL','APA','DED','TDGUF','ACDLOTM','DALA','DER','DAM','DECL','RADCL','ODA','AAA','AAAA', 'AAAL'],
  transactions: ['DED'],

  docsTelechargeable: ['source_type_15','source_type_revenue_order','source_acknowledg_rcpt','source_type_payment_receipt','source_type_land_status_historic','source_rejectltr','source_request_additional_information','source_attdom','source_type_user_notif','source_type_user_notif_ltr','source_acd','source_adcd','source_type_1'],
  ActTypeAAA: 'source_adcd,source_type_1,source_type_4,source_type_attr_letter_emph_lease,source_type_prov_conces_order_emph_lease,source_type_emphyteutic_lease',
  ActTypeAAAL: 'source_arraulot,source_apprparcplan,source_pubincdec,source_arrconjlot,source_decom_parceling_order',
  ActTypeAAAA: 'source_agent_approval_order,source_approval_order_land_dev,source_program_approv_order,source_dev_approv_order',

  ACDHL_AVEC_A: 'source_type_53, source_type_2, source_type_16',
  ACDHL_AVEC_LA: 'source_type_16, source_type_3',
  ACDHL_AVEC_TF: 'source_type_16, source_type_4',
  ACDLO_LT_A: 'source_type_3, source_type_letter_promise_award, source_type_1, source_type_16',
  ACDLO_ACP_LA_TF: 'source_type_4, source_type_25, source_type_16',
  ACDLO_REG: 'source_type_2, source_type_3, source_type_live_license',
  ACDLO_ATTESTATION: 'source_type_2, source_type_16',

  // En fonction de la plateforme, on ne redirige pas au même endroit.
  

  

    // recette
    //apiBasePath: 'https://sigfu-portal-dev.geofit.fr/directus/',
    //apiFileBasePath: 'https://sigfu-portal-dev.geofit.fr/directus/',
    //directusUrl: 'https://sigfu-portal-dev.geofit.fr/directus/admin/login',

    // apiBasePath: 'http://localhost:8055',
    // apiFileBasePath: 'http://localhost:8055',
    // directusUrl: 'http://localhost:8055/admin/login'
  

  

  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
