<ng-container *ngIf="plateforme.isDesktop(); else templateMobile">
  <mclu-loader *ngIf="loading === true"></mclu-loader>
  <!-- ######################  TEMPLATE DESKTOP  ###################### -->
  <ion-content class="templateDesktop">
    <mclu-header [isPageProfil]="true"></mclu-header>

    <div class="page">
      <div class="colonne-img">
        <img class="image-rubrique" src="{{ imageSource }}" alt="Image Rubrique" />
      </div>
      <div class="colonne-content">
        <!-- TITRE -->
        <mclu-content-header [couleurFond]="couleur.getGreen()" titre="Informations" titreBas="de mon espace personnel"
          [isPageDemande]="false"></mclu-content-header>
        <!-- CONTENU DE LA PAGE -->
        <div class="contenu">

          <router-outlet></router-outlet>
          <div *ngIf="this.router.url === '/mon-profil'" [formGroup]="profilForm">
            <div class="typo-16-bold-green identifiant ion-margin-bottom">
              Mon identifiant est {{userProfile?.username}}
            </div>

            <div class="flex-row ion-justify-content-between">
              <div>
                <p class="typo-32-yellow">Mon profil</p>
                <p class="typo-14">Tous les champs de votre profil ci-dessous sont modifiables</p>
              </div>

              <div class="bloc-info ion-align-items-end">
                <ion-item class="ion-no-padding" lines="none">
                  <ion-icon class="ion-no-margin" slot="start" src="./assets/svg/information.svg"></ion-icon>
                  <ion-label class="typo-14-bold-green ion-text-uppercase">
                    ne jamais communiquer votre identifiant et votre mot de
                    passe
                  </ion-label>
                </ion-item>
              </div>
            </div>

            <ion-button class="typo-14-white ion-text-uppercase" routerLink="/mon-profil/modification-mot-de-passe">Je
              modifie mon mot de passe
            </ion-button>

            <div class="flex-row ion-justify-content-between">
              <div class="formulaire">
                <p class="typo-16-bold-green titre-section">Votre identité</p>
                <div class="indent ion-margin-vertical">
                  <p class="typo-14-green">Merci de préciser, si vous êtes</p>

                  <mclu-bloc-radio-bouton-icone (selectButton)="changerSexe($event)"
                    [boutons]="boutonsSexe"></mclu-bloc-radio-bouton-icone>

                  <p class="typo-14">Renseignez votre identité <br>en complétant les informations suivantes :</p>

                  <p class="typo-14">Votre nom de famille</p>
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-input mcluNom formControlName='nom'></ion-input>
                  </ion-item>

                  <p class="typo-14">Votre prénom</p>
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-input mcluPrenom formControlName='prenom'></ion-input>
                  </ion-item>

                  <mclu-input-date [fc]="fcDateNaissance" label="Votre date de naissance" classLabel="typo-14"
                    [ageMin]="18"></mclu-input-date>

                  <p class="typo-14">Votre Lieu de naissance</p>
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-input formControlName='lieuNaissance'></ion-input>
                  </ion-item>

                  <p class="typo-14">Votre nationalité</p>
                  <mclu-input-autocomplete placeHolder="" [fcElement]="fcNationalite"
                    [lstElements]="options"></mclu-input-autocomplete>


                </div>
              </div>

              <div class="flex-column infos-column">
                <div class="bloc-info">
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-icon class="ion-no-margin" slot="start" src="./assets/svg/information.svg"></ion-icon>
                    <ion-label class="typo-14-bold-green ion-text-uppercase">
                      Pourquoi transmettre mon identité ?
                    </ion-label>
                  </ion-item>

                  <p class="typo-14">Vous devez transmettre
                    <br>votre identité pour sécuriser
                    <br>la gestion de vos demandes
                    <br>et pour vous prémunir des risques de fraude.
                  </p>
                </div>
              </div>
            </div>

            <div class="flex-row ion-justify-content-between">
              <div class="formulaire">

                <p class="typo-16-bold-green titre-section">Vos coordonnées de contact</p>
                <div class="indent ion-margin-vertical">

                  <p class="typo-14">Votre adresse</p>
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-input formControlName='adresse'></ion-input>
                  </ion-item>

                  <p class="typo-14">Votre e-mail de contact</p>
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-input formControlName='email' type='email'></ion-input>
                  </ion-item>

                  <p class="typo-14">N° de téléphone</p>
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-input mcluPhoneInput formControlName='numeroTelephone' type='tel'></ion-input>
                  </ion-item>
                  <p class="typo-14">Type Téléphone</p>
                  <ion-item class="ion-no-padding" lines="none">
                    <select formControlName="phoneType">
                      <option *ngFor="let phoneDto of phonesDto" [value]="phoneDto.code">{{phoneDto?.displayValue}}</option>
                    </select>
                  </ion-item>

                  <p class="typo-14">N° de téléphone 2</p>
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-input mcluPhoneInput formControlName='numeroTelephone2' type='tel'></ion-input>
                  </ion-item>
                  <p class="typo-14">Type Téléphone 2</p>
                  <ion-item class="ion-no-padding" lines="none">
                    <select formControlName="phoneType2">
                      <option *ngFor="let phoneDto of phonesDto" [value]="phoneDto.code">{{phoneDto?.displayValue}}</option>
                    </select>
                  </ion-item>


                </div>
              </div>
            </div>

            <div class="flex-row ion-justify-content-between">
              <div class="formulaire">

                <p class="typo-16-bold-green titre-section">Justificatif d'identité</p>
                <div class="indent ion-margin-vertical">
                  <p class="typo-14">Indiquez le type de votre document d'identité :</p>

                  <mclu-bloc-radio-bouton-icone (selectButton)="changerTypeJustificatifIdentite($event)"
                    [boutons]="boutonsJustificatif"></mclu-bloc-radio-bouton-icone>

                  <p class="typo-14">Merci de nous transmettre
                    <br>Votre n° de votre carte d'identité (ou de votre passeport)
                  </p>
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-input mcluIdentifiant formControlName='numeroJustificatif'></ion-input>
                  </ion-item>

                  <mclu-input-date [fc]="fcDateDelivrance" label="La date de délivrance"
                    classLabel="typo-14"></mclu-input-date>

                  <p class="typo-14">Le lieu de délivrance</p>
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-input formControlName='lieuDelivrance'></ion-input>
                  </ion-item>


                  <p *ngIf="this.typeJustificatif === 'id_card' " class="typo-14 ion-margin-top">
                    et les photos des 2 côtés de votre carte d’identité
                  </p>

                  <p *ngIf="this.typeJustificatif === 'passeport'" class="typo-14 ion-margin-top">
                    et l’image de TOUTE la page de votre passeport qui contient votre photo d’identité
                  </p>

                  <p class="typo-14-bold ion-margin-top">CHARGEZ UN DOCUMENT PARFAITEMENT LISIBLE</p>

                  <div *ngIf="this.typeJustificatif === 'id_card'">
                    <mclu-file-input 
                      (fileEnable)="changeJustificatifStatut($event, 'recto', '')"
                      [fileTypes]="authorizedFileTypes" 
                      [fileAccept]="authorizedfileExtensions" 
                      label="Face avant / Recto de votre carte" 
                      inputId="inputFile1"
                      [nomFichier]="nomFichierRecto">
                    </mclu-file-input>

                    <mclu-file-input 
                      (fileEnable)="changeJustificatifStatut($event)"
                      [fileTypes]="authorizedFileTypes" 
                      [fileAccept]="authorizedfileExtensions" 
                      label="Face arrière / Verso de votre carte" 
                      inputId="inputFile2"
                      [nomFichier]="nomFichierVerso">
                    </mclu-file-input>
                  </div>
                  <div *ngIf="this.typeJustificatif === 'passeport'">
                    <mclu-file-input 
                      (fileEnable)="changeJustificatifStatut($event,'recto','passeport')"
                      [fileTypes]="authorizedFileTypes" 
                      [fileAccept]="authorizedfileExtensions" 
                      label="Page de votre passeport avec photo" 
                      inputId="inputFile3"
                      [nomFichier]="nomFichierRecto">
                    </mclu-file-input>
                  </div>
                </div>
              </div>

              <div class="flex-column infos-column">
                <div class="bloc-info margin-bottom">
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-icon class="ion-no-margin" slot="start" src="./assets/svg/information.svg"></ion-icon>
                    <ion-label class="typo-14-bold-green ion-text-uppercase">
                      Votre numéro
                      <br>d'identité
                    </ion-label>
                  </ion-item>
                  <p class="typo-14">Saisissez correctement le numéro de votre document d'identité.</p>
                  <p class="typo-14-bold-green">
                    Attention : Les informations saisies vous engagent.
                  </p>
                </div>

                <div class="bloc-info margin-bottom">
                  <ion-item class="ion-no-padding" lines="none">
                    <ion-icon class="ion-no-margin" slot="start" src="./assets/svg/information.svg"></ion-icon>
                    <ion-label class="typo-14-bold-green ion-text-uppercase">Bien transmettre<br>vos documents
                    </ion-label>
                  </ion-item>
                  <img src="assets/jpg/transmettre-documents.jpg" alt="Image Documents" />
                </div>
              </div>
            </div>

            <div class="flex-row-center">

              <ion-button class="typo-14 ion-text-uppercase" fill="clear" (click)="location.back()">Retour
              </ion-button>
              <ion-button class="typo-14-white ion-text-uppercase" [disabled]="!isFormDirty() || !isFormValid()"
                (click)="annulerSaisie()">Annuler
              </ion-button>
              <ion-button class="typo-14-white ion-text-uppercase" [disabled]="!isFormDirty() || !isFormValid()"
                (click)="validerSaisie()">VALIDER VOS MODIFICATIONS
              </ion-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mclu-footer></mclu-footer>
  </ion-content>
</ng-container>

<!-- ######################  TEMPLATE MOBILE  ###################### -->
<ng-template #templateMobile>
  <mclu-loader *ngIf="loading === true"></mclu-loader>
  <ion-header>
    <mclu-header-mobile></mclu-header-mobile>
  </ion-header>
  <ion-content class="templateMobile">
    <div class="content-container">&nbsp;
      <div class="contenu-mobile">
        <!-- TITRE -->
        <div class="header-profil ion-text-center">
          <ion-icon class="btn-close ion-align-self-end" src="./assets/svg/close-popup.svg"
            (click)="routeAccueil()"></ion-icon>
          <p class="typo-32-bold-yellow">Informations</p>
          <p class="typo-20-bold-white">de mon espace personnel</p>
        </div>

        <div class="content flex-column-center">
          <!-- CONTENU DE LA PAGE -->
          <router-outlet></router-outlet>

          <div *ngIf="!isPasswordPageRoute()" [formGroup]="profilForm" class="flex-column-center">
            <div class="typo-16-bold-green ion-text-center identifiant">
              Mon identifiant est <br>{{userProfile?.username}}
            </div>

            <p class="typo-32-yellow">Mon profil</p>
            <p class="typo-14 ion-text-center">
              Tous les champs de votre profil
              <br>ci-dessous sont modifiables
            </p>

            <ion-button class="typo-14-white ion-text-uppercase ion-margin-bottom"
              routerLink="/mon-profil/modification-mot-de-passe">Je modifie mon mot de passe
            </ion-button>

            <p class="typo-16-bold-green ion-no-margin">Votre identité</p>

            <!-- ligne de séparation -->
            <img class="divider ion-margin-bottom" src="assets/svg/separateur_mobile_yellow.svg"
              alt="Ligne de séparation" />

            <p class="typo-14-green ion-margin-vertical">
              Merci de préciser, si vous êtes
            </p>

            <mclu-bloc-radio-bouton-icone (selectButton)="changerSexe($event)"
              [boutons]="boutonsSexe"></mclu-bloc-radio-bouton-icone>

            <div class="zone-saisie ion-align-self-start ion-justify-content-start">
              <p class="typo-14">Votre nom de famille</p>
              <ion-item class="ion-no-padding" lines="none">
                <ion-input mcluNom formControlName='nom'></ion-input>
              </ion-item>

              <p class="typo-14">Votre prénom</p>
              <ion-item class="ion-no-padding" lines="none">
                <ion-input mcluPrenom formControlName='prenom'></ion-input>
              </ion-item>

              <mclu-input-date [fc]="fcDateNaissance" label="Votre date de naissance"
                classLabel="typo-14"></mclu-input-date>




              <p class="typo-14">Votre nationalité</p>
              <mclu-input-autocomplete placeHolder="" [fcElement]="fcNationalite"
                [lstElements]="options"></mclu-input-autocomplete>

            </div>

            <p class="typo-16-bold-green ion-no-margin">Vos coordonnées de contact</p>

            <!-- ligne de séparation -->
            <img class="divider ion-margin-bottom" src="assets/svg/separateur_mobile_yellow.svg"
              alt="Ligne de séparation" />

            <div class="zone-saisie ion-align-self-start ion-justify-content-start">
              <p class="typo-14">Votre adresse</p>
              <ion-item class="ion-no-padding" lines="none">
                <ion-input formControlName='adresse'></ion-input>
              </ion-item>

              <p class="typo-14">Votre e-mail de contact</p>
              <ion-item class="ion-no-padding" lines="none">
                <ion-input formControlName='email' type='email'></ion-input>
              </ion-item>

              <p class="typo-14">N° de téléphone</p>
              <ion-item class="ion-no-padding" lines="none">
                <ion-input mcluPhoneInput formControlName='numeroTelephone' type='tel'></ion-input>
              </ion-item>
            </div>

            <p class="typo-16-bold-green ion-no-margin">Justificatif d'identité</p>

            <!-- ligne de séparation -->
            <img class="divider ion-margin-bottom" src="assets/svg/separateur_mobile_yellow.svg"
              alt="Ligne de séparation" />

            <p class="typo-12 ion-text-center">Indiquez le type <br> de votre document d'identité :</p>
            <mclu-bloc-radio-bouton-icone (selectButton)="changerTypeJustificatifIdentite($event)"
              [boutons]="boutonsJustificatif">
            </mclu-bloc-radio-bouton-icone>

            <p class="typo-12 ion-text-center">
              Merci de nous transmettre
              <br>Votre n° de votre carte d'identité (ou de votre passeport)
            </p>

            <div class="zone-saisie ion-align-self-start ion-justify-content-start">
              <ion-item class="ion-no-padding" lines="none">
                <ion-input mcluIdentifiant formControlName='numeroJustificatif'></ion-input>
              </ion-item>
            </div>

            <div class="info ion-margin-vertical ion-text-center">
              <p class="typo-12-bold-green ion-text-uppercase">Votre numéro d'identité</p>
              <p class="typo-12-green">
                Saisissez correctement le numéro
                <br>de votre document d'identité.
              </p>
              <p class="typo-14-green text-underline">Attention :</p>
              <p class="typo-14-bold-green">
                Les informations saisies
                <br>vous engagent.
              </p>
            </div>

            <p *ngIf="this.typeJustificatif === 'id_card' " class="typo-12 ion-text-center">
              + photos
              <br>des 2 côtés de votre carte d’identité
            </p>

            <p *ngIf="this.typeJustificatif === 'passeport' " class="typo-12 ion-text-center">
              + image
              <br>de TOUTE la page de votre passeport qui contient votre photo d’identité
            </p>


            <div *ngIf="typeJustificatif === 'id_card'" class="flex-column ion-align-self-start">
              <mclu-camera-input label="Face avant / Recto de votre carte" [nomFichier]="nomFichierRecto"
                (photoEnable)="changeJustificatifStatut($event, 'recto', '')">
              </mclu-camera-input>

              <mclu-camera-input label="Face arrière / Verso de votre carte" [nomFichier]="nomFichierVerso"
                (photoEnable)="changeJustificatifStatut($event)">
              </mclu-camera-input>
            </div>

            <div *ngIf="typeJustificatif === 'passeport'">
              <mclu-camera-input label="Page de votre passeport avec photo" [nomFichier]="nomFichierRecto"
                (photoEnable)="changeJustificatifStatut($event, 'recto', 'passeport')">
              </mclu-camera-input>
            </div>


            <div class="bloc-info-mobile">
              <ion-item class="ion-no-padding" lines="none">
                <ion-label class="typo-12-bold-green ion-text-uppercase text-align-center">
                  BIEN TRANSMETTRE VOS DOCUMENTS
                </ion-label>
              </ion-item>
              <div class="flex-row">
                <div class="div-icone">
                  <ion-icon class="icone icone-vert" src="./assets/svg/check_circle.svg"></ion-icon>
                </div>
                <div class="consigne">
                  <p class="typo-12">Photo droite et bien cadrée<br />Photo nette<br />Document propre</p>
                </div>
              </div>

              <div class="flex-row">
                <div class="div-icone">
                  <ion-icon class="icone icone-rouge" src="./assets/svg/cancel_circle.svg"></ion-icon>
                </div>
                <div class="consigne">
                  <p class="typo-12">
                    Photo de travers<br />Photo floue<br />Photocopie de photocopie
                    et Document sale ou taché
                  </p>
                </div>
              </div>
            </div>


            <div class="flex-row-center">
              <ion-button class="typo-14-white ion-text-uppercase" [disabled]="!isFormDirty() || !isFormValid()"
                (click)="annulerSaisie()">Annuler
              </ion-button>
              <ion-button class="typo-14-white ion-text-uppercase" [disabled]="!isFormDirty() || !isFormValid()"
                (click)="validerSaisie()">VALIDER
              </ion-button>
            </div>
          </div>
        </div>
      </div>&nbsp;
    </div>
  </ion-content>
  <ion-footer>
    <mclu-footer-mobile></mclu-footer-mobile>
  </ion-footer>
</ng-template>