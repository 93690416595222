import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PlateformeService } from 'src/app/services/plateforme/plateforme.service';

@Component({
  selector: 'mclu-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent implements OnInit {

  @Input()
  public fc: FormControl;

  @Input()
  public label: string;

  @Input()
  public classLabel: string;

  /** age minimum en année */
  @Input()
  public ageMin:number = -1;

  constructor(public plateforme: PlateformeService) {
   }

  ngOnInit() {}

}
