import { Component, Input, OnInit } from '@angular/core';
import { KeycloakService } from '../../../../services/keycloak/keycloak.service';
import { PlateformeService } from '../../../../services/plateforme/plateforme.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'mclu-popover-erreur-connexion',
  templateUrl: './popover-erreur-connexion.component.html'
})
export class PopoverErreurConnexionComponent implements OnInit {

  @Input()
  public profil;

  public message;

  constructor(private keycloakService: KeycloakService,
              private plateforme: PlateformeService,
              private popoverController: PopoverController) { }

  ngOnInit(): void {
    if (this.plateforme.isMobile()) {
      this.message = 'Vous êtes un professionnel.\n Nous vous invitons à vous connecter en tant que professionnel sur le portail du foncier.';
    } else {
      this.message = 'Veuillez s\'il vous plaît indiquer \n correctement qui vous êtes.';
    }
  }

  public async dismiss() {
    await this.keycloakService.logout();
    this.popoverController.dismiss();
    window.location.href = '/';
  }
}
