import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mclu-radio-actif',
  templateUrl: './radio-actif.component.html',
  styleUrls: ['./radio-actif.component.scss'],
})
export class RadioActifComponent implements OnInit {

  @Input()
  public isActif=true;

  @Output()
  public setActif: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {}

  changeActif(isActif: boolean){
    this.setActif.emit(isActif);

  }


}
