<div class="popover-creation-compte-header">
  <ion-icon class="ion-margin-end" src="./assets/svg/info.svg"></ion-icon>
  <div class="typo-20-bold-white">Création de compte</div>
</div>

<div class="popover-creation-compte-content">
  <div class="ion-justify-content-evenly"
       [ngClass]="{'flex-row': plateforme.isDesktop(), 'flex-column-center': plateforme.isMobile()}">
    <mclu-progress-circle pourcentage="100"></mclu-progress-circle>
    <p class="typo-18-green ion-text-center">
      Félicitations, votre compte a bien été créé.
      <br>Vous pouvez maintenant aller sur votre espace personnel en cliquant sur "Je me connecte",
      <br>puis en saisissant votre identifiant et votre mot de passe.</p>
  </div>

  <div class="buttons">
    <ion-button class="typo-16-bold-white ion-text-uppercase" (click)="dismiss()">Je me connecte</ion-button>
  </div>
</div>
