import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'mclu-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
})
export class InputAutocompleteComponent implements OnInit {

  @Input()
  public fcElement = new FormControl('');

  @Input()
  public lstElements: string[];

  @Input()
  public placeHolder= '';

  @Input()
  public disabledAutocomplete= false;

  @Input()
  public noRadiusRight= false;

  @Input()
  public isRequired= true;

  @Input()
  public isGreen= false;


  @Output()
  public changeElement: EventEmitter<string> = new EventEmitter<string>();


  public elementsObservable: Observable<string[]> | undefined;


  constructor() { }

  ngOnInit() {

    //this.fcElement.setValue('');
    //this.testElementValid('');

    if (this.lstElements){

      this.elementsObservable = this.fcElement?.valueChanges.pipe(
        startWith<string | any>(''),
        map((value) => {
          const name = value;
          return name
            ? this.filter(name as string)
            : this.lstElements.slice();
        })
      );

    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.lstElements){
      if (this.lstElements){
        this.elementsObservable = this.fcElement?.valueChanges.pipe(
          startWith<string | any>(''),
          map((value) => {
            const name = value;
            return name
              ? this.filter(name as string)
              : this.lstElements.slice();
          })
        );
      }
    }
  }

  public filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    
    return this.lstElements.filter((element) =>
    element?.toLowerCase().includes(filterValue)
    );
  }

  public elementChange() {
    this.testElementValid(this.fcElement.value);

  }

  public testElementValid(value: string): void {
    let trouve = false;
    if (this.lstElements){
      for (const element of this.lstElements) {
        if (value === element) {
          trouve = true;
          break;
        }
      }

      if (value==='' && this.isRequired===false){
        trouve=true;
      }
      if (trouve === true) {
        this.fcElement.setErrors(null);
        this.changeElement.emit(value);
      } else {
        this.fcElement.setErrors({ backend: { someProp: 'Invalid Data' } });
        this.changeElement.emit(value);

      }

    }
    else{
      this.fcElement.setErrors({ backend: { someProp: 'Invalid Data' } });
      this.changeElement.emit(value);
    }

  }

  // public elementSelection(value: string,val: number) {

  //   this.testElementValid(value);
  // }

  public deleteElement() {
    this.fcElement.setValue('');
    this.testElementValid('');
  }
}
