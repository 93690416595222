import {Injectable} from '@angular/core';
import {UserDto} from 'openapi/build';
import {Subject} from 'rxjs';
import {CreationCompteForm} from 'src/app/shared/interfaces/CreationCompteForm.model';
import {UploadFileService} from '../upload-file/upload-file.service';
import {NationaliteService} from '../nationalite/nationalite.service'

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  private utilisateurCourantSubject = new Subject<UserDto>();
  private utilisateurCourant: UserDto;

  constructor(private uploadFileService: UploadFileService,
              private nationaliteService: NationaliteService) {
  }

  /**
   * Retourne les infos de l'utilisateur en cours de création.
   *
   * @returns
   */
  public getutilisateurCourant() {
    return this.utilisateurCourant;
  }

  /**
   * Mets à jour l'utilisateur courant et informe les observeurs.
   *
   * @param user
   */
  public emitUtilisateurSubject(user: UserDto) {
    if (user) {
      this.utilisateurCourant = Object.assign(this.utilisateurCourant ? this.utilisateurCourant : {}, user);
      this.utilisateurCourantSubject.next(this.utilisateurCourant);
    }
  }

  /**
   * Convertit le formulaire en objet UserDto.
   *
   * @param compte
   * @returns
   */
  public convertCreationForm(compte: CreationCompteForm): UserDto {
    let utilisateur: UserDto;
    let dateNaissance = '';
    let dateCreation ='';

    if (compte) {

      if (compte.dateNaissance) {
        const dateNaissanceObj = new Date(compte.dateNaissance);
        dateNaissance = isNaN(dateNaissanceObj.getTime()) ? null : dateNaissanceObj.toISOString();
      }
  
      if (compte.dateCreation) {
        const dateCreationObj = new Date(compte.dateCreation);
        dateCreation = isNaN(dateCreationObj.getTime()) ? null : dateCreationObj.toISOString();
      }
      

      utilisateur = {
        forceUpdatePassword: false,
        username: compte.identifiant,
        profil: compte.profil || (this.utilisateurCourant ? this.utilisateurCourant.profil : ''),
        firstName: compte.prenom,
        lastName: compte.nom,
        password: compte.password,
        dateNaissance,
        lieuNaissance: compte.lieuNaissance,
        phoneType: compte.phoneType ? compte.phoneType : 'phone_type_mobile',
        phone2: compte?.phone2,
        phoneType2: compte.phoneType2 ? compte.phoneType2 : 'phone_type_mobile',
        denomination: compte.denomination,
        noRegistreCommerce: compte.noRegistreCommerce,
        siegeSocial: compte.siegeSocial,
        lieuEtablissement: compte.lieuEtablissement,
        civiliteRepresentant: compte.civiliteRepresentant,
        noCompteContribuable: compte.noCompteContribuable,
        dateCreation,
        paysOrigine: {
          id: Number(this.nationaliteService.getIdNationaliteByNom(compte.nationalite)),
          label: compte.nationalite
        },
        capitalSociete: compte.capitalSociete,
        typeSociete: compte.typeSociete,
        genre: {
          id: compte.sexe === 'homme' ? 1 : 2,
          label: compte.sexe === 'homme' ? 'homme' : 'femme',
        },
        pieceIdentite: {
          numeroPiece: compte.numeroJustificatif,
          typeJustificatif: compte.typeJustificatif,
          justificatifRecto: this.uploadFileService.getNomFichierJustificatif(compte.identifiant, compte.nomJustificatifRecto, 'justificatif-recto'),
          justificatifVerso: compte.typeJustificatif === 'passeport' ? '' : this.uploadFileService.getNomFichierJustificatif(compte.identifiant, compte.nomJustificatifVerso, 'justificatif-verso'),
          dateUpdate: new Date().toISOString(),
          username: compte.identifiant,
          idDocumentIssuedBy: compte.lieuDelivrance,
        },
        nationalite: {
          id: Number(this.nationaliteService.getIdNationaliteByNom(compte.nationalite)),
          label: compte.nationalite
        },
        typeUser: {
          id: 3,
          label: 'Particulier'
        },
        email: undefined,
        adresse: undefined,
        statutCompte: {
          id: 1,
        },
        dateDebutActivation: new Date().toISOString(),
        dateFinActivation: undefined,
        dateDerniereReactivation: undefined
      };

      if (compte.dateDelivrance) {
        utilisateur.pieceIdentite.idDocumentDate = new Date(compte.dateDelivrance).toISOString();
      }

      if (compte.identifiant && compte.identifiant.includes('@')) {
        utilisateur.email = compte.identifiant;
        utilisateur.phone = compte.numeroTel? compte.numeroTel : undefined;
      } else {
        utilisateur.phone = compte.numeroTel? compte.numeroTel : compte.identifiant;
        utilisateur.email = undefined;
      }
    }
    console.log('-----Utilisateur----->', utilisateur);
    return utilisateur;
  }
}
