/**
 * SIGFU REST API
 * Serveur d\'api en NodeJs
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContactGufDto {
    guichet: string;
    adresse: string;
    telephone1: number;
    telephone2: number;
    localisation: number;
    email: string;
    coordinates: string;
    distance?: number;
    distanceFormated?: string;
}

