export class AbstractIonContent {

  /**
   * Déplace le scroll en position bottom. Il est fortement conseillé de rajouter une class spécifique au composant.
   * Surtout s'il y a plusieurs ion-content dans l'app.
   *
   * @param yPoints
   * @param delay en ms
   * @param duration en ms
   */
   public scrollByPoint(yPoints= 200, delay = 200, duration = 500) {
    setTimeout(()=> {
      const c = this.getContent();
      c.scrollByPoint(0, yPoints, duration);
    }, delay);

  }

  /**
   * Retourne un ion-content element. Il est fortement conseillé de rajouter une class spécifique au composant.
   * Surtout s'il y a plusieurs ion-content dans l'app.
   *
   * @param specificClass
   * @returns
   */
  private getContent(): HTMLIonContentElement {
    return document.querySelector('ion-content');
  }

}
