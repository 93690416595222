import { Component, Input, OnInit } from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'mclu-popover-infos-creation-compte',
  templateUrl: './popover-infos-creation-compte.component.html',
  styleUrls: ['./popover-infos-creation-compte.component.scss'],
})
export class PopoverInfosCreationCompteComponent implements OnInit {

  @Input()
  public titre;

  @Input()
  public nom;

  @Input()
  public prenom;

  @Input()
  public ordre;

  constructor(public popoverController: PopoverController) { }

  ngOnInit() {
  }

  public closePopup() {
    this.popoverController.dismiss();
  }

}
