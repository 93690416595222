import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverCreationCompteComponent } from './popover-creation-compte.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ProgressCircleModule } from '../../progress-circle/progress-circle.module';



@NgModule({
  declarations: [PopoverCreationCompteComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    ProgressCircleModule,
  ],
  exports: [
    PopoverCreationCompteComponent,
  ]
})
export class PopoverCreationCompteModule { }
