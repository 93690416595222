import { Component, OnInit } from '@angular/core';
import { ConstantesLiens } from '../../../constantes/constantes-liens';
import { Constantes } from '../../../constantes/constantes';
import { KeycloakService } from '../../../../services/keycloak/keycloak.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import KeycloakProfile from 'src/app/shared/interfaces/KeycloakProfile';
import { UtilisateurService } from 'src/app/services/utilisateur/utilisateur.service';
import { UsersService } from 'openapi/build';
import { ConstantesRolesEnum } from 'src/app/shared/constantes/constantes-roles.enum';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'mclu-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public isConnected: boolean;
  public constantesLiens = ConstantesLiens;
  public constantes = Constantes;
  public numVersionApp = '1.0.0';
  public expertCarteLink: string;

  public depos: string;
  public Link: string;

  public isAdmin = false;
  public isParticulier = false;
  public isPro = false;
  public isAdminCorpo = false;

  constructor(public router: Router,
    private uploadFileService: UploadFileService,
    public keycloakService: KeycloakService,
    private alertController: AlertController,
    public usersService: UsersService) { }

    async ngOnInit() {
      this.isConnected = this.keycloakService.isAuthenticated();
      this.numVersionApp = environment.numVersionApp;
      const fileName = 'FAQ-portail-sigfu';
      this.Link = `${environment.apiBasePath + environment.apiFileBasePath}/piece-identite/FAQ/${fileName}`;      
      }
  }

