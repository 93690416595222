import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PhotoService } from '../../../services/photo/photo.service';
import { PlateformeService } from '../../../services/plateforme/plateforme.service';

@Component({
  selector: 'mclu-camera-input',
  templateUrl: './camera-input.component.html',
  styleUrls: ['./camera-input.component.scss'],
})
export class CameraInputComponent {

  @Input()
  public readonly = false;

  @Input()
  public label: string;

  @Input()
  public nomFichier: string;

  @Output()
  public photoEnable: EventEmitter<{ fichier: any; statut: boolean }> = new EventEmitter<{ fichier: any; statut: boolean }>();

  public invalidFile = false;
  public photos: File[] = [];

  public imageSource = '';
  public imageVisible = false;

  private apiBasePath = environment.apiBasePath;
  private apiFileBasePath = environment.apiFileBasePath;

  /**
   * Constructeur.
   *
   * @param photoService
   * @param plateforme
   */
  constructor(public photoService: PhotoService,
              public plateforme: PlateformeService) {
  }

  /**
   * Bouton prendre une photo avec la caméra
   */
  public async prendreUnePhoto(): Promise<void> {
    await this.supprimerPhoto();
    await this.photoService.prendreUneNouvellePhoto(this.photos)
      .then((photos: File[]) => {
        if (photos[0].size <= 10485760) {
          this.invalidFile = false;
          this.photos = photos;
          this.getImageSource(this.photos[0]);
          this.photoEnable.emit({ fichier: this.photos[0], statut: true });
        } else {
          this.photos = [];
          this.nomFichier = '';
          this.invalidFile = true;
          this.imageSource = '';
          this.imageVisible = false;
          this.photoEnable.emit({ fichier: undefined, statut: false });
        }
      }).catch(() => {
        this.photos = [];
        this.nomFichier = '';
        this.invalidFile = false;
        this.imageSource = '';
        this.imageVisible = false;
        this.photoEnable.emit({ fichier: undefined, statut: false });
      });
  }

  /**
   * Bouton modifier la photo avec la caméra.
   */
  public async modifierUnePhoto(): Promise<void> {
    await this.photoService.modifierPhoto(this.photos, 0).then((photos: File[]) => {
      this.photos = photos;
      this.getImageSource(this.photos[0]);
      this.photoEnable.emit({ fichier: this.photos[0], statut: true });
    });
  }

  /**
   * Bouton supprimer la photo.
   */
  public async supprimerPhoto(): Promise<void> {
    this.imageVisible = false;
    this.photos = [];
    this.nomFichier = '';
    this.photoEnable.emit({ fichier: this.photos[0], statut: false });
  }

  public visualiserFichier(): void {
    if (this.nomFichier) {
      this.imageSource = this.apiBasePath + this.apiFileBasePath + '/piece-identite/' + encodeURI(this.nomFichier);
    }
    this.imageVisible = !this.imageVisible;
  }

  /**
   * Récupère la source du fichier pour pouvoir afficher l'image
   *
   * @private
   */
  private getImageSource(file: File): void {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      this.imageSource = e.target.result + '';
    };
    reader.readAsDataURL(file);
  }
}
