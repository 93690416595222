import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { InputDateComponent } from './input-date.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule, } from '@angular/material-moment-adapter';
import { InputDateMobileModule } from '../input-date-mobile/input-date-mobile.module';
import { InputDateDesktopModule } from '../input-date-desktop/input-date-desktop.module';


@NgModule({
  declarations: [
    InputDateComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    InputDateMobileModule,
    InputDateDesktopModule
  ],
  exports: [
    InputDateComponent,
  ]
})
export class InputDateModule { }

