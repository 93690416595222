import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  NativeDateAdapter,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const CUSTOM_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class PickDateAdapter extends NativeDateAdapter {
  // eslint-disable-next-line @typescript-eslint/ban-types
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return formatDate(date, 'dd/mm/yyyy', this.locale);
    } else {
      return date.toDateString();
    }
  }
}

@Component({
  selector: 'mclu-input-date-mobile',
  templateUrl: './input-date-mobile.component.html',
  styleUrls: ['./input-date-mobile.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
  ],
})
export class InputDateMobileComponent implements OnInit, AfterViewInit {
  @ViewChild('ioninputdate') ioninputdate: ElementRef;

  @Output()
  public formControlChange: EventEmitter<FormControl> = new EventEmitter<FormControl>();

  @Input()
  public fc: FormControl;

  @ViewChild('picker')
  public datePicker: MatDatepicker<Date>;

  public minDate = new Date(1900, 0, 1);
  public maxDate = new Date();

  public maxDateControl: Date = new Date();
  public minDateControl: Date = new Date(1900, 0, 2);

  ngAfterViewInit() {
    this.updateState();
  }
  /**
   * Initialisation du composant.
   */
  ngOnInit() {
    this.updateState();
  }

  public inputEvent(e) {
    let day;
    let month;
    let year;

    e.target.value = e.target.value.replace(/[^/0-9]*/g, '');


    // If backspace and last char is a slash
    if (e.target.value.slice(-1) === '/') {
      e.target.value = e.target.value.slice(0, -1);
      document
        .getElementById('ioninputdate')
        .setAttribute('value', e.target.value);
      return;
    }

    // day
    if (e.target.value.length === 2) {
      day = this.frameNumber(e.target.value, 31, 1);
      e.target.value = `${day}/`;
    }
    // month
    if (e.target.value.length === 5) {
      day = this.frameNumber(e.target.value.slice(0, 2), 31, 1);
      month = this.frameNumber(e.target.value.slice(3), 12, 1);
      e.target.value = `${day}/${month}/`;
    }
    // year
    if (e.target.value.length > 9) {
      e.target.value = e.target.value.substring(0, 10);
      day = this.frameNumber(e.target.value.slice(0, 2), 31, 1);
      month = this.frameNumber(e.target.value.slice(3, 5), 12, 1);
      const currentYear = new Date().getFullYear();
      year = e.target.value.slice(6, 10);
      year = this.frameNumber(year, currentYear, 1900);
      e.target.value = `${day}/${month}/${year}`;
      e.target.blur();

      let newDate = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10)
      );
      newDate = new Date(year + '-' + month + '-' + day);
      this.fc.setValue(newDate);

      const trst = this.fc.value;
    }

    document
      .getElementById('ioninputdate')
      .setAttribute('value', e.target.value);

    this.updateState();
  }

  public updateState(): void {
    if (this.ioninputdate) {
      if (this.ioninputdate.nativeElement?.value?.length > 9) {
        const day = this.frameNumber(
          this.ioninputdate.nativeElement.value.slice(0, 2),
          31,
          1
        );
        const month = this.frameNumber(
          this.ioninputdate.nativeElement.value.slice(3, 5),
          12,
          1
        );
        const currentYear = new Date().getFullYear();
        const year = this.ioninputdate.nativeElement.value.slice(6, 10);

        const newDate = new Date(year + '-' + month + '-' + day);
        this.fc.setValue(newDate);

        const d: Date = new Date(this.fc.value);
        if (d > this.maxDateControl || d < this.minDateControl) {
          this.fc.setErrors({ backend: { someProp: 'Invalid Data' } });
        } else {
          this.fc.setErrors(null);
        }
      }
    }

    this.formControlChange.emit(this.fc);
  }

  public onFocus(e) {
    this.datePicker.close();
    // e.preventDefault();
  }

  public focusOutEvent(e) {
    this.updateState();
  }

  private frameNumber(valeur, max, min) {
    max = max || 31;
    min = min || 1;
    const val = parseInt(valeur, 10);
    let response = '';
    if (val < min) {
      response = min;
    } else {
      if (val >= max) {
        response = max;
      } else {
        response = valeur;
      }
    }

    return response.length === 1 ? `0${response}` : response;
  }
}
