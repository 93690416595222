import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mclu-popover-content',
  templateUrl: './popover-content.component.html',
  styleUrls: ['./popover-content.component.scss'],
})
export class PopoverContentComponent implements OnInit {

  @Input()
  public titre: string;
  @Input()
  public icone: string;
  @Input()
  public content: string;
  @Input()
  public image: string;
  @Input()
  public imageAlt: string;
  @Input()
  public lien: string;
  @Input()
  public buttonLabel: string;
  @Input()
  public haveModal: boolean;

  constructor() { }

  ngOnInit() { }

}


