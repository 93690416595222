import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(private http: HttpClient) { }


  /**
   * Génère et retourne le nom du fichier dans un format nomBase-Username.extension
   *
   * @param username
   * @param nomFichier
   * @param nouveauNomFichier
   */
  public getNomFichierJustificatif(username: string, nomFichier: string, nouveauNomFichier: string): string {
    let extension= '';
    if (nomFichier){
      extension = nomFichier.split('.').pop();
    }
    return `${nouveauNomFichier}-${username}.${extension}`;
  }

  public getNomFichierFrais(nomFichier: string, nouveauNomFichier: string): string{
    let extension= '';
    if (nomFichier){
      extension = nomFichier.split('.').pop();
    }
    {return `${nouveauNomFichier}.${extension}`;}
  }

  public getNomFichier(nomFichier: string, nouveauNomFichier: string): string{
    let extension= '';
    if (nomFichier){
      extension = nomFichier.split('.').pop();
    }
    {return `${nouveauNomFichier}.${extension}`;}
  }

  public getNomFichierContactGuf(nomFichier: string, nouveauNomFichier: string): string{
    let extension= '';
    if (nomFichier){
      extension = nomFichier.split('.').pop();
    }
    {return `${nouveauNomFichier}.${extension}`;}
  }

  /**
   * Upload les images des justificatifs d'identité
   *
   * @param username
   * @param fichiers
   * @public
   */
  public uploadFichierJustificatif(username: string, fichiers: File[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const formDataRecto = new FormData();
      const formDataVerso = new FormData();
      const promises: Promise<any>[] = [];

      if (fichiers[0]) {
        formDataRecto.append('pieceIdentite', fichiers[0], this.getNomFichierJustificatif(username, fichiers[0].name,'justificatif-recto'));
        promises.push(this.http.post(environment.apiBasePath + environment.apiFileBasePath + '/piece-identite/upload-file', formDataRecto,).toPromise());
      }

      if (fichiers[1]) {
        formDataVerso.append('pieceIdentite', fichiers[1], this.getNomFichierJustificatif(username, fichiers[1].name,'justificatif-verso'));
        promises.push(this.http.post(environment.apiBasePath + environment.apiFileBasePath + '/piece-identite/upload-file', formDataVerso,).toPromise());
      }

      Promise.all(promises)
        .then(() => resolve(true))
        .catch(() => reject(false));
    });
  }

  /**
   * Upload de la carte professionnelle
   *
   * @param username
   * @param fichier
   * @public
   */
  public uploadFichierCartePro(username: string, fichier: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!fichier) {
        reject(false); 
        return;
      }
  
      const formData = new FormData();
      formData.append('pieceIdentite', fichier, this.getNomFichierJustificatif(username, fichier.name,'carte-pro'));
  
      this.http.post(environment.apiBasePath + environment.apiFileBasePath + '/piece-identite/upload-file', formData)
        .toPromise()
        .then(() => {
          resolve(true); 
        })
        .catch((error) => {
          console.error('Erreur lors du téléversement de la carte professionnelle :', error);
          reject(false); 
        });
    });
  }

  public uploadFichierFrais(fichier: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!fichier) {
        reject(new Error("Le fichier est manquant."));
        return;
      }
  
      const formData = new FormData();
      formData.append('frais', fichier, this.getNomFichierFrais(fichier.name, 'source-frais'));
  
      this.http.post(environment.apiBasePath + environment.apiFileBasePath + '/piece-identite/upload-file-frais', formData)
        .toPromise()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          console.error('Erreur lors du téléversement de la carte professionnelle :', error);
          reject(error); // Rejeter la promesse avec l'erreur rencontrée
        });
    });    
  }

  public uploadFichier(fichier: File, nomFichier: string, lien: string, FileInterceptor: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!fichier) {
        reject(new Error("Le fichier est manquant."));
        return;
      }
  
      const formData = new FormData();
      formData.append(FileInterceptor, fichier, this.getNomFichier(fichier.name, nomFichier));
  
      this.http.post(environment.apiBasePath + environment.apiFileBasePath + '/' + lien, formData)
        .toPromise()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          console.error('Erreur lors du téléversement de la carte professionnelle :', error);
          reject(error); // Rejeter la promesse avec l'erreur rencontrée
        });
    });    
  }

  public uploadFichierContactGuf(fichier: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!fichier) {
        reject(new Error("Le fichier est manquant."));
        return;
      }
  
      const formData = new FormData();
      formData.append('guf', fichier, this.getNomFichierContactGuf(fichier.name, 'source-contact-guf'));
  
      this.http.post(environment.apiBasePath + environment.apiFileBasePath + '/piece-identite/upload-file-guf', formData)
        .toPromise()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          console.error('Erreur lors du téléversement de la carte professionnelle :', error);
          reject(error); // Rejeter la promesse avec l'erreur rencontrée
        });
    });    
  }


  public getFrais(image: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.head(environment.apiBasePath + environment.apiFileBasePath + `/piece-identite/${image}`)
        .toPromise()
        .then(() => {
          // Si la requête réussit (statut 200), le fichier existe
          resolve(true);
        })
        .catch(() => {
          // Si la requête échoue (statut 404), le fichier n'existe pas
          resolve(false);
        });
    });
  }

  public getFAQ(image: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.head(environment.apiBasePath + environment.apiFileBasePath + `/piece-identite/FAQ/${image}`)
        .toPromise()
        .then(() => {
          // Si la requête réussit (statut 200), le fichier existe
          resolve(true);
        })
        .catch(() => {
          // Si la requête échoue (statut 404), le fichier n'existe pas
          resolve(false);
        });
    });
  }

  public getContactGuf(image: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.head(environment.apiBasePath + environment.apiFileBasePath + `/piece-identite/contact-guf/${image}`)
        .toPromise()
        .then(() => {
          // Si la requête réussit (statut 200), le fichier existe
          resolve(true);
        })
        .catch(() => {
          // Si la requête échoue (statut 404), le fichier n'existe pas
          resolve(false);
        });
    });
  }

  public renameFile(oldName: string, newName: string): Promise<{ message: string }> {
    return new Promise<{ message: string }>((resolve, reject) => {
      this.http.put<{ message: string }>(
        `${environment.apiBasePath + environment.apiFileBasePath}/piece-identite/rename/${oldName}.xlsx/${newName}.xlsx`,
        {}
      ).toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public renameFileContactGuf(oldName: string, newName: string): Promise<{ message: string }> {
    return new Promise<{ message: string }>((resolve, reject) => {
      this.http.put<{ message: string }>(
        `${environment.apiBasePath + environment.apiFileBasePath}/piece-identite/renameContactGuf/${oldName}.xlsx/${newName}.xlsx`,
        {}
      ).toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
 
  public renameFAQ(oldName: string, newName: string): Promise<{ message: string }> {
    return new Promise<{ message: string }>((resolve, reject) => {
      this.http.put<{ message: string }>(
        `${environment.apiBasePath + environment.apiFileBasePath}/piece-identite/renameFAQ/${oldName}.pdf/${newName}.pdf`,
        {}
      ).toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
