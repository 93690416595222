import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatNativeDateModule } from '@angular/material/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DepotLigneGestionnaireFavorisComponent } from '../depot-ligne-gestionnaire-favoris/depot-ligne-gestionnaire-favoris.component';
import { InputAutocompleteModule } from '../../../input-autocomplete/input-autocomplete.module';
import { DepotLigneNoticeModule } from '../depot-ligne-notice/depot-ligne-notice.module';


@NgModule({
  declarations: [
    DepotLigneGestionnaireFavorisComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    CdkAccordionModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    InputAutocompleteModule,
    DepotLigneNoticeModule
  ],
  exports: [
    DepotLigneGestionnaireFavorisComponent
  ]
})
export class DepotLigneGestionnaireFavorisModule { }
