<div class="ion-text-center">
  <div [formGroup]="activationForm">
    <p *ngIf="isPopup" class="ion-text-uppercase typo-24-green">
      Activation du compte
    </p>
    <div class="flex-row activation-compte">
      <div class="flex-row">
        <ion-label class="typo-18-grey">Compte</ion-label>
        <mclu-radio-actif [isActif]="activation.value" (setActif)="activation.setValue($event);setValidators()"></mclu-radio-actif>
      </div>
      <div>
        <ion-item lines="none" [disabled]="activation.value">
          <ion-checkbox
            slot="start"
            formControlName="activationPlanned"
            (ionChange)="setValidators()"
          >
          </ion-checkbox>
          <ion-label class="typo-18-grey">Activation programmée</ion-label>
        </ion-item>
        <ion-item lines="none" [disabled]="activation.value">
          <ion-label class="typo-18-grey labelActivation"
            >Le compte sera ré-activé le :</ion-label
          >

          <mclu-input-date-desktop classLabel="typo-18-grey" [fc]="dateActivationProgramme" (click)="$event.stopPropagation();" [noLimit]="true" [minToday]="true" ></mclu-input-date-desktop>

        </ion-item>
      </div>
    </div>
    <div>
      <p [ngClass]="{ 'opacity-50': !activation.value }" class="typo-18-bold">Durée</p>
      <div class="flex-row-space-evenly">
        <ion-img src="assets/svg/horloge.svg"></ion-img>
        <ion-list>
          <ion-radio-group
            (ionChange)="setValidators()"
            formControlName="duree"
            value="illimitée"
          >
            <ion-item [disabled]="!activation.value" lines="none">
              <ion-label class="typo-18-grey">Illimitée - par défaut</ion-label>
              <ion-radio
                slot="start"
                value=true
                [ngClass]="{ 'radio-checked': duree.value === true }"
              ></ion-radio>
            </ion-item>
            <ion-item [disabled]="!activation.value" lines="none">
              <ion-label class="typo-18-grey">jusqu'au</ion-label>
              <ion-radio
                slot="start"
                value=false
                [ngClass]="{ 'radio-checked': duree.value === false }"
              ></ion-radio>

              <mclu-input-date-desktop classLabel="typo-18-grey" [fc]="dateFinActivation" (click)="$event.stopPropagation();" [noLimit]="true" [minToday]="true"></mclu-input-date-desktop>

            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </div>
  </div>
  <div *ngIf="isPopup" class="flex-row-center buttons-group">
    <ion-button class="typo-14-white ion-text-uppercase" (click)="return()"
      >Retour</ion-button
    >
    <ion-button
      class="typo-14-white ion-text-uppercase"
      [disabled]="isFormValid()"
      (click)="submitFormActivation()"
    >
      Valider
    </ion-button>
  </div>

  <div *ngIf="!isPopup" class="flex-row boutons">
    <ion-button class="typo-14-white ion-text-uppercase" (click)="return()"
      >Annuler</ion-button
    >
    <ion-button
      class="typo-14-white ion-text-uppercase bouton-continuer"
      [disabled]="isFormValid()"
      (click)="submitFormActivation()"
      >Valider
    </ion-button>
  </div>
</div>
