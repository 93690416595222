export enum ConstantesRolesEnum {
  admin = '-admin',
  adminCorpo = '-admin-corporation',
  adminFrais = '-admin-frais',
  adminGUF = '-admin-GUF',
  adminFAQ = '-admin-FAQ',
  adminContact = '-admin-contact',
  pro = '-professionnel',
  particulier = '-particulier',
}
