/**
 * SIGFU REST API
 * Serveur d\'api en NodeJs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FicheDemandeActeDto { 
    type_demande: string;
    bien: string;
    lotissement: string;
    localite: string;
    ilot: string;
    lot: string;
    superficie: string;
    nom_benef: string;
    prenom_benef: string;
    naissance_benef: string;
    lieu_naissance_benef: string;
    profession_benef: string;
    nationalite_benef: string;
    fixe_benef: string;
    mobile_benef: string;
    adresse_benef: string;
    email_benef: string;
    num_piece_benef: string;
    lieu_piece_benef: string;
    date_piece_benef: string;
    pere_benef: string;
    mere_benef: string;
    nom_depo: string;
    prenom_depo: string;
    naissance_depo: string;
    lieu_naissance_depo: string;
    sexe_depo: string;
    nationalite_depo: string;
    type_piece_depo: string;
    lieu_piece_depo: string;
    num_piece_depo: string;
    date_piece_depo: string;
    fixe_depo: string;
    mobile_depo: string;
    idSourceDto: number;
    reportName: string;
    idDepotDto: number;
    applicationId: string;
    transactionId: string;
    transactionNo: string;
    signature: string;
}

