import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {PopoverModificationMotDePasseComponent} from './popover-modification-mot-de-passe.component';
import {IonicModule} from '@ionic/angular';



@NgModule({
  declarations: [PopoverModificationMotDePasseComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    PopoverModificationMotDePasseComponent,
  ]
})
export class PopoverModificationMotDePasseModule { }
