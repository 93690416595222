import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[mcluPhoneInput]'


})
export class PhoneInputDirective {

  @Input() mcluPhoneInput= '';

  @HostListener('ionChange', ['$event']) onIonChange(event) {


    if (this.mcluPhoneInput!=='')
    {
      return;
    }

    if (event.target.value.toString().length===0)
    {
      return;
    }


    const firstChar = event.target.value.charAt(0);
    if (firstChar === '+') {
      event.target.value = firstChar + event.target.value.substring(1).replace(/[^0-9]*/g, '');
    } else {
      event.target.value = event.target.value.replace(/[^0-9]*/g, '');
    }
  }

}
