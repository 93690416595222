import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from '../services/keycloak/keycloak.service';
import { ConstantesRolesEnum } from '../shared/constantes/constantes-roles.enum';
import { PlateformeService } from '../services/plateforme/plateforme.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private readonly router: Router,
              readonly keycloak: KeycloakService,
              private platform: PlateformeService) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    const requiredRole: string[] = route.data.role;
    // Contrôle des rôles requis pour la route
    for (const role of requiredRole) {
      if (this.keycloak.hasAuthorization(role)) {
        return true;
      }
    }

    // Redirection selon le rôle utilisateur
    if (this.keycloak.hasAuthorization(ConstantesRolesEnum.admin)) {
      return this.router.navigateByUrl('/administateur');
    }
    if (this.keycloak.hasAuthorization(ConstantesRolesEnum.particulier)) {
      return this.router.navigateByUrl('/public');
    }
    if (this.keycloak.hasAuthorization(ConstantesRolesEnum.pro)) {
      return this.router.navigateByUrl('/professionnel');
    }
    if (this.keycloak.hasAuthorization(ConstantesRolesEnum.adminCorpo)) {
      return this.router.navigateByUrl('/administration/liste-utilisateur-pro');
    }

    await this.keycloak.logout();
    if (this.platform.isDesktop()) {
      return this.router.navigateByUrl('/accueil');
    } else {
      return this.router.navigateByUrl('/public');
    }
  }

}
