import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import KeycloakProfile from 'src/app/shared/interfaces/KeycloakProfile';
import { InnolaDictionaryDto, InnolaGetDictionaryDto, InnolaService } from 'openapi/build';
import { PopoverProps } from 'src/app/shared/interfaces/popover-props';
import { AbstractPopover } from '../../../popover/abstract-popover/abstract-popover';
import { FavorisDto } from 'openapi/build/model/favorisDto';
import { KeycloakService } from 'src/app/services/keycloak/keycloak.service';
import { FavorisService } from 'openapi/build/api/favoris.service';
import { AbstractHeader } from '../../../portail/header/abstract-header';
import { environment } from 'src/environments/environment';
import { QueryLotDto } from 'openapi/data-api/build';



export class DepotLigneTypeComponent { }

@Component({
  selector: 'app-depot-ligne-gestionnaire-favoris',
  templateUrl: './depot-ligne-gestionnaire-favoris.component.html',
  styleUrls: ['./depot-ligne-gestionnaire-favoris.component.scss'],
})

export class DepotLigneGestionnaireFavorisComponent extends AbstractPopover implements OnInit {

  @Input() favorisStr: { label: string; checked: boolean; }[] = [];

  public innolaTypes: InnolaDictionaryDto[];
  public isChecked: boolean;
  public userProfile: KeycloakProfile | null = null;

  @Input()
  url: string;



  public fcElement = new FormControl('');
  public fcElementDossier = new FormControl('');

  formattedFavoris: any[] = []; // Déclarez formattedFavoris comme un tableau vide
  favorisTab: string[] = []; // Initialisez la liste des favoris
  ajout: any[] = []; // Tableau pour stocker les éléments cochés à ajouter
  supprimer: any[] = []; // Tableau pour stocker les éléments non cochés à supprimer

  /**
   * Constructeur.
   * @param keycloakService
   * @param utilisateurService
   * @param router
   */
  constructor(
    public innolaService: InnolaService,
    private router: Router,
    public popoverController: PopoverController,
    public keycloakService: KeycloakService,
    private favorisService: FavorisService,
  ) {
    super();
  }

  async ngOnInit() {

    /*Recuperation id */
    this.userProfile = this.keycloakService.getUserProfile();

    this.favorisService.favorisControllersGetFavorisByUserId(this.userProfile.id).subscribe(
      (favorisDemande: any) => {
        this.formattedFavoris = favorisDemande.map(favorisTab => ({ nom: favorisTab.type_demande }));
      },
      error => {
        console.error('Une erreur s\'est produite lors de la récupération des favoris :', error);
      }
    );

    const typeInnolaGetDictionaryDto: InnolaGetDictionaryDto = { category: 'transaction_type', traduction: false, sort: true };
    this.innolaService.innolaControllerGetInnolaDictionaryByCategory(typeInnolaGetDictionaryDto)
      .toPromise()
      .then((innolaTypes: InnolaDictionaryDto[]) => {
        this.favorisStr = [];
        this.innolaTypes = [];
        let listTransactionAuto = environment.transactions;
        if(this.url === '/depot-ligne-type')
          {
            console.log('NONONONO');
          }
          else{
            listTransactionAuto = listTransactionAuto.filter(transaction => transaction !== 'ACDHL');
            console.log('OKOKOK');
          }
        for (const innolaType of innolaTypes) {
          if (innolaType.outgoingDocument) {
            // eslint-disable-next-line max-len
            if (innolaType.outgoingDocument.indexOf('task_review_application_online') > -1 && innolaType.code.substring(innolaType.code.length - 1) !== '0') {
              if(listTransactionAuto.includes(innolaType.code)){
                this.isChecked = false;
                if (this.formattedFavoris.some(formattedFavori => formattedFavori.nom === innolaType.displayValue)) {
                  this.isChecked = true;
                }
                this.favorisStr.push({ label: innolaType.displayValue, checked: this.isChecked });
                this.innolaTypes.push(innolaType);
              } 
            }
          }
        }
      });
  }

  ajouterFavori(idUser: string, typeDemande: string): void {
    const favorisDto: FavorisDto = {
      id_user: idUser,
      type_demande: typeDemande
    };

    this.favorisService.favorisControllersCreateFavoris(favorisDto).subscribe();
  }

  supprimerFavori(idUser: string, typeDemande: string): void {
    const favorisDto: FavorisDto = {
      id_user: idUser,
      type_demande: typeDemande
    };
    this.favorisService.favorisControllersSupprimerFavori(idUser, typeDemande).subscribe();
  }

  onCheckboxChange(type_demande: any) {
    type_demande.checked = !type_demande.checked; // Inversion de l'état de la case à cocher

    if (type_demande.checked) {
      this.ajouterFavori(this.userProfile.id, type_demande.label);
    } else if (type_demande.checked === false) {
      this.supprimerFavori(this.userProfile.id, type_demande.label)
    }
  }


  async closeModal() {
    await this.popoverController.dismiss();
  }

  public isSearchValid() {
    return this.fcElement.valid && this.fcElement.value !== '' && this.fcElementDossier.valid && this.fcElementDossier.value !== '';
  }

}
