import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {PlateformeService} from '../../../../services/plateforme/plateforme.service';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'mclu-popover-modification-mot-de-passe',
  templateUrl: './popover-modification-mot-de-passe.component.html',
  styleUrls: ['./popover-modification-mot-de-passe.component.scss'],
})
export class PopoverModificationMotDePasseComponent implements OnInit {

  constructor(private router: Router,
              public plateforme: PlateformeService,
              private popover: PopoverController) { }

  ngOnInit() {}

  public dismiss(): void {
      this.router.navigateByUrl('/mon-profil');
      this.popover.dismiss();
    }
}
