import { NgModule } from '@angular/core';
import {PopoverDetailsCompteParticulierComponent} from './popover-details-compte-particulier.component';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';

@NgModule({
  declarations: [
    PopoverDetailsCompteParticulierComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    PopoverDetailsCompteParticulierComponent
  ]
})
export class PopoverDetailsCompteParticulierModule { }
