import { Component, Input, OnInit } from '@angular/core';
import { CouleurService } from 'src/app/services/couleur/couleur.service';
import { KeycloakService } from 'src/app/services/keycloak/keycloak.service';
import { Router } from '@angular/router';


@Component({
  selector: 'mclu-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss'],
})
export class FooterMobileComponent implements OnInit {

  @Input()
  public currentPage: 'Accueil' | 'services' | 'demandes'| 'parcelles' | 'demandes-connecte' | 'suivi-public' ;

  public isConnected: boolean;


  constructor(public keycloakService: KeycloakService,
              public couleur: CouleurService,
              private router: Router) { }


  ngOnInit() {
    this.isConnected = this.keycloakService.isAuthenticated();
  }

  public routeButton(route: string): void {
    this.router.navigateByUrl(route);
  }
}
