/**
 * SIGFU REST API
 * Serveur d\'api en NodeJs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SourceFraisDto { 
    acte: string;
    superficieType: string;
    particulier: number;
    societe: number;
    associationOngCulte: number;
    region: number;
    departements: number;
    secteur: number;
    fraisPublication: number;
    fraisBornage: number;
}

