<!-- INPUT TEXT, NUMBER, DATE, MOT DE PASSE -->
<ion-input #inputdate [min]="minDate"  [max]="maxDate"
  class="inputCustomGeneric typo-14-bold-green bg-white"
  [ngClass]="{
    'invalid-input': fc?.invalid,'color-white':colorWhite
  }"
  type="date"
  [formControl]="fc"
  (ionChange)="updateState()"
  (ionBlur)="onFocusOut()"
>
</ion-input>

