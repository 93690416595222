import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  /**
   * Constructeur.
   *
   * @param loadingController
   */
  constructor(public loadingController: LoadingController) { }

  /**
   * Affiche la popup pour patienter.
   */
   public async presentLoading(delay: number = 1000) {
    const loading = await this.loadingController.create({
      cssClass: 'spinner-custom-class',
      message: 'Merci de patienter...',
      duration: delay,
      spinner: 'circular'
    });
    await loading.present();

  }
}
