import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'mclu-popover-creation-compte-pro',
  templateUrl: './popover-creation-compte-pro.component.html',
  styleUrls: ['./popover-creation-compte-pro.component.scss'],
})
export class PopoverCreationCompteProComponent implements OnInit {

  @Input()
  public prenom;

  @Input()
  public nom;

  constructor(
    public popoverController: PopoverController
  ) {
  }

  ngOnInit() {
  }

  public closePopup() {
    this.popoverController.dismiss();
  }

}
