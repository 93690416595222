import { Component, Input, OnInit } from '@angular/core';
import { KeycloakService } from '../../../services/keycloak/keycloak.service';
import KeycloakProfile from 'src/app/shared/interfaces/KeycloakProfile';
import { Router } from '@angular/router';
import { PlateformeService } from 'src/app/services/plateforme/plateforme.service';

@Component({
  selector: 'mclu-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss', './content-header.component mobile.scss'],
})
export class ContentHeaderComponent implements OnInit {

  @Input()
  icone: string;

  @Input()
  titreMobile: string;

  @Input()
  titre: string;

  @Input()
  titreBas: string;

  @Input()
  sousTitre: string;

  @Input()
  couleurFond: string;

  @Input()
  isPageDemande: boolean;

  @Input()
  isPageDemandeDetails: boolean;

  public userKeycloakProfil: KeycloakProfile | null = null;
  public connected = false;

  constructor(private keycloakService: KeycloakService,
              private readonly router: Router,
              public plateforme: PlateformeService) { }

  async ngOnInit() {
    this.connected = this.keycloakService.isAuthenticated();
    if (this.connected) {
      this.userKeycloakProfil = this.keycloakService.getUserProfile();
    }
  }

  public routeAccueil(): void {
    if (!this.isPageDemandeDetails) {
      this.router.navigateByUrl('public');
    } else {
      this.router.navigateByUrl('demarches');
    }
  }
}
