<ng-container *ngIf="plateforme.isDesktop(); else templateMobile">
  <div [style.background-color]="couleurFond" class="conteneur">
    <div class="conteneur-left">

      <ng-container>
        <p class="typo-40-bold-white titre">{{titre | uppercase}}</p>
        <p class="typo-20-bold-white">{{sousTitre}}</p>
        <p class="typo-40-bold-white">{{titreBas | uppercase}}</p>
      </ng-container>


    </div>
  </div>
</ng-container>

<!-- ######################  TEMPLATE MOBILE  ####################### -->
<ng-template #templateMobile>
  <div [style.background-color]="couleurFond" class="conteneur-mobile">
    <ion-icon class="icone" src="../../../../assets/svg/{{icone}}.svg"></ion-icon>
    <p class="typo-21-white titre">{{titre | uppercase}}</p>

    <ion-icon class="btn-close" src="../../../../assets/svg/close-popup.svg" (click)="routeAccueil()"></ion-icon>
  </div>

</ng-template>
