import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BoutonCarreIcone } from '../../interfaces/bouton-carre-icone';
import { PlateformeService } from '../../../services/plateforme/plateforme.service';

@Component({
  selector: 'mclu-bloc-radio-bouton-icone',
  templateUrl: './bloc-radio-bouton-icone.component.html',
  styleUrls: ['./bloc-radio-bouton-icone.component.scss'],
})
export class BlocRadioBoutonIconeComponent implements OnInit {

  @Input()
  public boutons: BoutonCarreIcone[] = [];

  @Input()
  public libelle: string;

  @Input()
  public readonly = false;

  @Input()
  public greenBorder = false;

  @Input()
  public yellowBG = false;

  @Output()
  public selectButton = new EventEmitter();

  public selectedButton: BoutonCarreIcone;

  constructor(public plateforme: PlateformeService) { }

  ngOnInit() {
    this.selectedButton = this.boutons.find((bouton: BoutonCarreIcone) => bouton.isSelected);
  }

  public changerSelection(boutonIcone: string): void {
    this.boutons.forEach((bouton: BoutonCarreIcone) => {
      bouton.isSelected = bouton.icone === boutonIcone;
      if (bouton.isSelected) {
        this.selectedButton = bouton;
        this.selectButton.emit(bouton.icone);
      }
    });
  }
}
