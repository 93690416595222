import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[mcluIdentifiant]',
})
export class IdentifiantDirective {
  constructor() {}

  @HostListener('ionChange', ['$event']) onIonChange(event) {
    event.target.value = event.target.value.toUpperCase();
    event.target.value = event.target.value.replace(/[^-./A-Z0-9]*/g, '');
  }
}
