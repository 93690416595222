import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserDto, UsersService } from 'openapi/build';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import KeycloakProfile from 'src/app/shared/interfaces/KeycloakProfile';
import { ConstantesRolesEnum } from 'src/app/shared/constantes/constantes-roles.enum';
import { BehaviorSubject } from 'rxjs';
import { SecureStorage } from 'src/app/services/secure-storage/SecureStorage';

const ROLES_PRO: string[] = [
  ConstantesRolesEnum.admin,
  ConstantesRolesEnum.adminCorpo,
  ConstantesRolesEnum.pro
];
const REFRESH_TOKEN = "refresh_token";

export const initializeKeycloak = (keycloak: KeycloakService) => () => keycloak.init();

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  private baseUrl: string = environment.keycloakUrl;
  private realm: string = environment.keycloakRealm;
  private userProfileSubject: BehaviorSubject<KeycloakProfile | null> = new BehaviorSubject<KeycloakProfile | null>(null);
  public userProfile$ = this.userProfileSubject.asObservable();
  private accessToken = null;

  /**
   * Constructeur.
   *
   * @param platform
   */
  constructor(private http: HttpClient, private router: Router, private usersService: UsersService, private secureStorage: SecureStorage) {
  }


  init(): void {
    this.refreshToken().then((result) => {
      console.log('Token refreshing:', result);
    }).catch(error => {
      console.error('Error refreshing token during initialization:', error);
    });
  }

  private setUserProfile(userProfile: KeycloakProfile | null): void {
    this.userProfileSubject.next(userProfile);
  }

  private isPro(): boolean {
    return window.location.pathname.includes('professionnel') ||
      window.location.pathname.includes('administrat');
  }

  /**
   * Connecte l'utilisateur auprès du service.
   *
   * @param route
   * @returns
   */
  public login(username: string, password: string): Promise<any> {
    const isPro = this.isPro();
    const clientIdDynamic = isPro ? environment.keycloakClientPro : environment.keycloakClient;

    // Formatage des données sous forme de x-www-form-urlencoded
    const body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('grant_type', 'password');
    body.set('client_id', clientIdDynamic);

    // Définition des en-têtes de la demande
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post(`${this.baseUrl}/realms/${this.realm}/protocol/openid-connect/token`, body.toString(), { headers }).toPromise().then(async (response: any) => {
      const profile = this.setAccessToken(response.access_token);
      this.setRefreshToken(response.refresh_token);

      const deposeRecu = sessionStorage.getItem('depose-recu');
      if (deposeRecu) {
        sessionStorage.removeItem('deposeRecu');
        this.router.navigateByUrl(deposeRecu);
      } else {
        this.router.navigateByUrl(isPro ? '/professionnel' : '/public');
      }

      this.usersService.userControllerGetUserByUsername(encodeURI(profile.username)).toPromise().then((user: UserDto) => {
        if (user.forceUpdatePassword) {
          this.router.navigateByUrl('/administration/modification-mot-de-passe', {
            state: [user.username]
          });
        }
      }).catch((error) => {
        console.error("we cannot find the user", error);
      });
    });
  }

  public logout(): Promise<any> {
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      const clientIdDynamic = this.isPro()
        ? environment.keycloakClientPro
        : environment.keycloakClient;
      // Formatage des données sous forme de x-www-form-urlencoded
      const body = new URLSearchParams();
      body.set('client_id', clientIdDynamic);
      body.set('refresh_token', refreshToken);

      // Définition des en-têtes de la demande
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      });

      return this.http.post(`${this.baseUrl}/realms/${this.realm}/protocol/openid-connect/logout`, body.toString(), { headers })
        .toPromise().catch((error) => {
          // Si une erreur se produit on fait supprime juste les tokens
          console.error(error);
        }).finally(() => this.clean());
    } else {
      this.clean();
    }
  }

  /**
   * Indique si l'utilisateur est connecté.
   *
   * @returns
   */
  public isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  public hasAuthorization(role: string): boolean {
    const userProfile = this.getUserProfile();
    return userProfile?.groups?.some(group => this.recupererDernierePartie(group) === role) ?? false;
  }

  public isUserPro(): boolean {
    const userProfile = this.getUserProfile();
    return userProfile?.groups?.some(group => ROLES_PRO.includes(this.recupererDernierePartie(group))) ?? false;
  }

  private recupererDernierePartie(chemin: string): string {
    return chemin.substring(chemin.indexOf('-'));
  }

  /**
   * Get the user profile
   *
   * @returns
   */
  public getUserProfile(): KeycloakProfile {
    let userProfile = null;
    const accessToken = this.getAccessToken();
    if (accessToken) {
      userProfile = this.parseJwt(accessToken);
    }
    return userProfile;
  }

  /**
   * Retourne le token access
   *
   * @returns token
   */
  public getAccessToken(): string {
    return this.accessToken;
  }

  private setAccessToken(token: string) {
    this.accessToken = token;
    const profile = this.getUserProfile();
    this.setUserProfile(profile);
    return profile;
  }

  /**
   * Retourne le token refresh
   *
   * @returns token
   */
  private getRefreshToken(): string {
    return this.secureStorage.getItem(REFRESH_TOKEN);
  }

  private setRefreshToken(token: string) {
    this.secureStorage.setItem(REFRESH_TOKEN, token);
  }

  private clean() {
    this.accessToken = null;
    this.secureStorage.removeItem(REFRESH_TOKEN);
  }

  public refreshToken(): Promise<any> {
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      const clientIdDynamic = this.isPro()
        ? environment.keycloakClientPro
        : environment.keycloakClient;
      // Formatage des données sous forme de x-www-form-urlencoded
      const body = new URLSearchParams();
      body.set('client_id', clientIdDynamic);
      body.set('refresh_token', refreshToken);
      body.set('grant_type', 'refresh_token');
      // Définition des en-têtes de la demande
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      });

      return this.http.post(`${this.baseUrl}/realms/${this.realm}/protocol/openid-connect/token`, body.toString(), { headers })
        .toPromise()
        .then((response: any) => {
          // Mise à jour du token access et du token refresh dans le stockage local
          this.setAccessToken(response.access_token);
          this.setRefreshToken(response.refresh_token);
          return response;
        })
        .catch(error => {
          console.log('Error refreshing token:', error);
          return Promise.reject(error);
        });
    } else {
      return Promise.resolve('No refresh token available');
    }
  }

  public parseJwt(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.log('Error parsing JWT:', error);
      this.clean();
      // On redirige vers l'accueil
      this.router.navigateByUrl('/');
      return null;
    }
  }
}
