import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mclu-bouton-carre-icone',
  templateUrl: './bouton-carre-icone.component.html',
  styleUrls: ['./bouton-carre-icone.component.scss'],
})
export class BoutonCarreIconeComponent {

  @Input()
  public icone: string;

  @Input()
  public libelle: string;

  @Input()
  public isSelected: boolean;

  @Input()
  public isEnabled: boolean;

  @Input()
  public greenBorder = false;

  @Input()
  public yellowBG = false;

  @Output()
  public selectButton = new EventEmitter();

  public onBoutonClick(): void {
    if (this.isEnabled) {
      this.selectButton.emit();
    }
  }
}
