import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {AlertController, PopoverController} from '@ionic/angular';
import KeycloakProfile from 'src/app/shared/interfaces/KeycloakProfile';
import { UserDto } from 'openapi/build/model/userDto';
import { UsersService } from 'openapi/build/api/users.service';
import { LabelMonProfil } from '../../shared/interfaces/label-mon-profil';
import { PopoverProps } from '../../shared/interfaces/popover-props';
import { BoutonCarreIcone } from '../../shared/interfaces/bouton-carre-icone';
import { AbstractPopover } from '../../shared/components/popover/abstract-popover/abstract-popover';
import { NationaliteService } from '../../services/nationalite/nationalite.service';
import { PlateformeService } from '../../services/plateforme/plateforme.service';
import { KeycloakService } from 'src/app/services/keycloak/keycloak.service';
import { CouleurService } from '../../services/couleur/couleur.service';
import { UploadFileService } from '../../services/upload-file/upload-file.service';
import { InnolaDictionaryDto, InnolaGetDictionaryDto, InnolaService } from 'openapi/build';
import { UtilisateurService } from '../../services/utilisateur/utilisateur.service';


@Component({
  selector: 'mclu-mon-profil',
  templateUrl: './mon-profil.component.html',
  styleUrls: [
    './mon-profil.component.scss',
    './mon-profil-mobile.component.scss',
  ],
})
export class MonProfilComponent extends AbstractPopover implements OnInit {
  public fcDateNaissance: FormControl = new FormControl('dateNaissance', [Validators.required,]);
  public fcDateCreation: FormControl = new FormControl('dateCreation', [Validators.required,]);
  public fcDateDelivrance: FormControl = new FormControl('dateDelivrance', [Validators.required,]);

  public fcNationalite: FormControl  = new FormControl("Côte d'Ivoire", [Validators.required]);


  public imageSource = 'assets/jpg/creation_compte_plateforme_SIGFU.jpg';
  public readonly photoStorageKeyIDRecto = 'photoStorageKeyIDRecto';
  public readonly photoStorageKeyIDVerseau = 'photoStorageKeyIDVerseau';

  public loading=false;


  public readonly identiteProps: PopoverProps = {
    icone: 'help',
    titre: 'Pourquoi transmettre mon identité ?',
    // eslint-disable-next-line max-len
    content:
      'Vous devez transmettre votre identité pour sécuriser la gestion de vos demandes et pour vous prémunir des risques de fraude.',
  };
  public readonly numIdentiteProps: PopoverProps = {
    icone: 'help',
    titre: 'Où trouver mon numéro de carte d’identité ?',
    content: 'Immatriculation\n ou\n numéro d’identité',
    image: 'carte_identite_recto',
    imageAlt: `Carte d'identité recto`,
  };
  public readonly boutonsSexe: BoutonCarreIcone[] = [
    {
      icone: 'femme',
      libelle: 'Femme',
      isSelected: false,
      isEnabled: true,
    },
    {
      icone: 'homme',
      libelle: 'Homme',
      isSelected: true,
      isEnabled: true,
    },
  ];
  public readonly boutonsJustificatif: BoutonCarreIcone[] = [
    {
      icone: 'id_card',
      libelle: `Carte d'identité`,
      isSelected: false,
      isEnabled: true,
    },
    {
      icone: 'passeport',
      libelle: 'Passeport',
      isSelected: true,
      isEnabled: true,
    },
  ];

  public label: LabelMonProfil = {
    nom: 'Votre nom de famille',
    prenom: 'Votre prénom',
    birthday: 'Votre date de naissance',
    nationality: 'Votre nationalité',
    justificatif: 'de votre carte d\'identité ou de votre  passeport',
    adresse: 'Votre adresse',
    email: 'Votre e-mail de contact',
    numero: 'N° de téléphone',
  };

  public hasUserInfo = false;

  public profilForm: FormGroup;

  public options: string[];

  public typeJustificatif: string;
  public justificatifRecto: any = null;
  public justificatifVerso: any = null;

  public nomFichierRecto: string;
  public profil: string;
  public nomFichierVerso: string;

  public authorizedFileTypes = ['image/png', 'image/jpeg'];
  public authorizedfileExtensions = ".png, .jpeg, .jpg";

  public isReadonly = true;
  public userProfile: KeycloakProfile | null = null;
  private user: UserDto;

  private sexe: string;
  private sexeDirty: boolean;
  private idPieceIdentite: number;
  private typeJustificatifDirty: boolean;
  private justificatifRectoDirty: boolean;
  private justificatifVersoDirty: boolean;
  private justificatifRectoValid: boolean;
  private justificatifVersoValid: boolean;
  public phonesDto: InnolaDictionaryDto[] = [];

  constructor(public alertController: AlertController,
              public nationaliteService: NationaliteService,
              public plateforme: PlateformeService,
              public couleur: CouleurService,
              private keycloak: KeycloakService,
              private usersService: UsersService,
              private uploadFileService: UploadFileService,
              public router: Router,
              public popoverController: PopoverController,
              public location: LocationStrategy,
              public utilisateurService: UtilisateurService,
              public innolaService: InnolaService
  ) {
    super();
  }

  async ngOnInit() {
    this.createProfilForm();  
    const phoneInnolaGetDictionaryDto: InnolaGetDictionaryDto = { category: 'phone_type', traduction: true, sort: true };
    await this.innolaService.innolaControllerGetInnolaDictionaryByCategory(phoneInnolaGetDictionaryDto)
      .toPromise()
      .then((phonesDto: InnolaDictionaryDto[]) => {
        this.phonesDto = phonesDto;
      });

    const isLoggedIn = this.keycloak.isAuthenticated();
    if (isLoggedIn) {
      this.userProfile = this.keycloak.getUserProfile();
      this.usersService
        .userControllerGetUserByUsername(encodeURI(this.userProfile.username))
        .toPromise()
        .then((user: UserDto) => {
          this.user = user;
          this.typeJustificatif = user.pieceIdentite.typeJustificatif;
          this.updateFields(user);
          this.profil = this.user.profil;
        });
    }
  }

  // TODO Dans un service ou directive
  public filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((country) =>
      country.toLowerCase().includes(filterValue)
    );
  }

  public changerSexe(sexeSelection: string): void {
    this.sexe = sexeSelection;
    this.sexeDirty = true;
  }

  /**
   * Sauvegarde les modifications en base de données
   */
  public validerSaisie(): void {
    const formValues = this.profilForm.getRawValue();

    const updatedUser = this.user;

    this.loading=true;

    updatedUser.genre = {
      id: this.sexe === 'homme' ? 1 : 2,
      label: this.sexe === 'homme' ? 'homme' : 'femme',
    };

    updatedUser.lastName = formValues.nom;
    updatedUser.firstName = formValues.prenom;

    updatedUser.dateNaissance = new Date(formValues.dateNaissance).toISOString(); //new Date(formValues.dateNaissance).toISOString();
    updatedUser.nationalite = {
      id: Number(this.nationaliteService.getIdNationaliteByNom(formValues.nationalite)),
      label: formValues.nationalite,
    };
    updatedUser.pieceIdentite = {
      id: this.idPieceIdentite,
      typeJustificatif: this.typeJustificatif,
      numeroPiece: formValues.numeroJustificatif,
      justificatifRecto: this.justificatifRecto
        ? this.uploadFileService.getNomFichierJustificatif(updatedUser.username, this.justificatifRecto.name,'justificatif-recto')
        : this.nomFichierRecto,
      justificatifVerso: this.typeJustificatif === 'passeport' ? ' ' : this.justificatifVerso
        ? this.uploadFileService.getNomFichierJustificatif(updatedUser.username, this.justificatifVerso.name,'justificatif-verso')
        : this.nomFichierVerso,
      dateUpdate: new Date().toISOString(),
      username: updatedUser.username,
      idDocumentDate: formValues.dateDelivrance ? formValues.dateDelivrance : null,
      idDocumentIssuedBy: formValues.lieuDelivrance ? formValues.lieuDelivrance : null
    };

    updatedUser.adresse = formValues.adresse;
    updatedUser.phone = formValues.numeroTelephone ? formValues.numeroTelephone : null;
    updatedUser.phoneType = formValues.phoneType ? formValues.phoneType : 'phone_type_mobile';
    updatedUser.email = formValues.email ? formValues.email : null;
    updatedUser.lieuNaissance = formValues.lieuNaissance ? formValues.lieuNaissance : null;
    updatedUser.phone2 = formValues.numeroTelephone2 ? formValues.numeroTelephone2 : null;
    updatedUser.phoneType2 = formValues.numeroTelephone2 && formValues.phoneType2 ? formValues.phoneType2 : 'phone_type_mobile';


    this.usersService.userControllerUpdateUser(updatedUser).toPromise()
      .then(() => {
        this.uploadFileService.uploadFichierJustificatif(updatedUser.username,
          [this.justificatifRecto, this.justificatifVerso,])
          .then(() => {
            this.presentAlertConfirmationUpdate().then();
            this.user = updatedUser;
            this.userProfile = this.keycloak.getUserProfile();

            this.annulerSaisie();
            this.loading=false;
          }).catch(() => {
            this.loading=false;
          });
      }).catch(() => {
        this.loading=false;
        const infosProps: PopoverProps = {
          titre: '<a class="typo-20-bold">Un compte utilise déjà l\'identifiant ou le n° du document transmis.</a>',
          couleur: 'red'
        };
        this.showPopoverGeneric(this.popoverController, infosProps).then();
      });
  }

  /**
   * Annule les saisies et restaure les valeurs initiales dans le formulaire
   */
  public annulerSaisie(): void {
    this.sexeDirty = false;
    this.typeJustificatifDirty = false;
    this.justificatifRectoDirty = false;
    this.justificatifVersoDirty = false;

    this.profilForm.reset({
      nom: '',
      prenom: '',
      dateNaissance: '',
      nationalite: '',
      adresse: '',
      email: '',
      numeroTelephone: '',
      numeroJustificatif: '',
      numeroTelephone2: '',
    });

    this.updateFields(this.user);
  }

  public changerTypeJustificatifIdentite(typeJustificatif: string): void {
    this.profilForm.patchValue({ numeroJustificatif: '' });
    this.typeJustificatif = typeJustificatif;
    this.typeJustificatifDirty = true;
    this.nomFichierRecto = null;
    this.nomFichierVerso = null;
    this.justificatifRecto = null;
    this.justificatifVerso = null;
    this.justificatifVersoValid = false;
    this.justificatifRectoValid = false;

    switch (typeJustificatif) {
      case 'id_card': {
        this.label.justificatif = `N° carte d'identité`;
        break;
      }
      case 'passeport': {
        this.label.justificatif = 'Numéro de passeport';
        break;
      }
    }
  }

  /**
   * Met à jour le fichier fourni pour le justificatif
   *
   * @param event
   * @param face
   * @param type
   */
  public changeJustificatifStatut(event: { fichier: any; statut: boolean }, face = '', type = ''): void {
    if (face === 'recto') {
      this.justificatifRecto = event.fichier;
      this.justificatifRectoDirty = true;
      this.justificatifRectoValid = event.statut;
    } else if(type === 'passeport') {
      this.justificatifVerso = null;
      this.justificatifVersoDirty = false;
      this.justificatifVersoValid = true;
    } else {
      this.justificatifVerso = event.fichier;
      this.justificatifVersoDirty = true;
      this.justificatifVersoValid = event.statut;
    }
  }

  public isFormValid(): boolean {
    if (this.typeJustificatif === 'id_card') {
      return (
        this.profilForm.valid &&
        this.justificatifRectoValid &&
        this.justificatifVersoValid
      );
    } else if (this.typeJustificatif === 'passeport') {
      return(this.profilForm.valid &&
        this.justificatifRectoValid);
    }
  }

  /**
   * Retourne true si un champ a été modifié
   */
  public isFormDirty(): boolean {
    return (
      this.sexeDirty ||
      this.typeJustificatifDirty ||
      this.justificatifRectoDirty ||
      this.justificatifVersoDirty ||
      this.profilForm.dirty
    );
  }

  public routeAccueil(): void {
    this.router.navigateByUrl('public');
  }

  /**
   * Retourne vrai si la route de la page correspond à la mise à jour du mot de passe
   */
  public isPasswordPageRoute() {

    if (this.router.url.includes('/mon-profil/modification-mot-de-passe')
      || this.router.url.includes('/mon-profil/mot-de-passe/nouveau')) {
      return true;
    }

    return false;
  }

  private createProfilForm(): void {
    this.profilForm = new FormGroup({
      denomination: new FormControl('', [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(/[A-z\s]+/)]),
      civiliteRepresentant: new FormControl('', [Validators.required]),
      typeSociete: new FormControl(),
      noCompteContribuable: new FormControl('', [
        //Validators.required,
        Validators.maxLength(40)]),
        //Validators.pattern(/[A-z\s]+/)]);
      noRegistreCommerce: new FormControl('', [
        //Validators.required,
        Validators.maxLength(40)]),
        //Validators.pattern(/[A-z\s]+/)]);
      siegeSocial: new FormControl('', [
        //Validators.required,
        Validators.maxLength(40)]),
        //Validators.pattern(/[A-z\s]+/)]);
      lieuEtablissement: new FormControl('', [
        //Validators.required,
        Validators.maxLength(40)]),
        //Validators.pattern(/[A-z\s]+/)]);
      capitalSociete: new FormControl('', [
        Validators.pattern(/^(\d{1,3}(?:\s\d{3})*|(\d+))(\,\d{2})?$/)]),
      nom: new FormControl('', [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(/[A-z\s]+/),
      ]),
      prenom: new FormControl('', [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(/[A-z\s]+/),
      ]),
      dateNaissance: this.fcDateNaissance,
      dateCreation: this.fcDateCreation,
      nationalite: this.fcNationalite,
      adresse: new FormControl(''),
      email: new FormControl('', [
        // eslint-disable-next-line max-len
        Validators.pattern(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        ),
      ]),
      numeroTelephone: new FormControl('', [
        Validators.pattern(/^(\+[0-9]{11,15})$|^(0[0-9]{9})$/),
      ]),
      numeroJustificatif: new FormControl('', [
        Validators.required,
        Validators.maxLength(40),
        Validators.minLength(6)
      ]),
      lieuNaissance: new FormControl('',Validators.required),
      lieuDelivrance: new FormControl('',Validators.required),
      phoneType: new FormControl('',Validators.required),
      dateDelivrance: this.fcDateDelivrance,
      numeroTelephone2: new FormControl('', [
        Validators.pattern(/^(\+[0-9]{11,15})$|^(0[0-9]{9})$/),
      ]),
      phoneType2: new FormControl(''),
    });

    if (this.profil = 'Particulier') {
      console.log('Particulier 2');
      this.profilForm.get('denomination').disable();
      this.profilForm.get('civiliteRepresentant').disable();
      this.profilForm.get('typeSociete').disable();
      this.profilForm.get('noCompteContribuable').disable();
      this.profilForm.get('noRegistreCommerce').disable();
      this.profilForm.get('siegeSocial').disable();
      this.profilForm.get('lieuEtablissement').disable();
      this.profilForm.get('capitalSociete').disable();
      this.profilForm.get('dateCreation').disable();
    }

    this.options = this.nationaliteService.getListeNationalitesOptions();

  }

  /**
   * Met à jour le libellé du champ numero de piece d'identité.
   *
   * @param justificatif
   */
  private setTypeJustificatifIdentite(justificatif: string): void {
    switch (justificatif) {
      case 'id_card': {
        this.label.justificatif = `N° carte d'identité`;
        this.boutonsJustificatif[0].isSelected = true;
        this.boutonsJustificatif[1].isSelected = false;
        break;
      }
      case 'passeport': {
        this.label.justificatif = 'Numéro de passeport';
        this.boutonsJustificatif[1].isSelected = true;
        this.boutonsJustificatif[0].isSelected = false;
        break;
      }
    }

    this.hasUserInfo = true;
  }

  private setSexe(sexe: string): void {
    this.sexe = sexe;
    switch (sexe) {
      case 'femme': {
        this.boutonsSexe[0].isSelected = true;
        this.boutonsSexe[1].isSelected = false;
        break;
      }
      case 'homme': {
        this.boutonsSexe[1].isSelected = true;
        this.boutonsSexe[0].isSelected = false;
        break;
      }
    }
  }

  /**
   * Préremplie les champs du formulaire.
   *
   * @param user
   */
  private updateFields(user: UserDto): void {
    // Changement du format de la date de naissance pour l'insérer dans le formulaire
    console.log('Particulier');

    const dateNaissance = user.dateNaissance.toString();
    const birthdayReformated = dateNaissance.split('T')[0];
    this.fcDateNaissance.setValue(birthdayReformated);
    const dateDelivrance = user.pieceIdentite.idDocumentDate?.toString();
    const dateDelivranceReformated = dateDelivrance?.split('T')[0];
    this.fcDateDelivrance.setValue(dateDelivranceReformated);

    this.setSexe(user.genre.label);
    this.profilForm.setValue({
      denomination: user.denomination,
      noRegistreCommerce: user.noRegistreCommerce,
      siegeSocial: user.siegeSocial,
      lieuEtablissement: user.lieuEtablissement,
      civiliteRepresentant: user.civiliteRepresentant,
      noCompteContribuable: user.noCompteContribuable,
      dateCreation: user.dateCreation,
      capitalSociete: user.capitalSociete,
      typeSociete: user.typeSociete,
      nom: user.lastName,
      prenom: user.firstName,
      dateNaissance: birthdayReformated,
      nationalite: user.nationalite.label,
      adresse: user.adresse,
      email: user.email ? user.email : '',
      numeroTelephone: user.phone ? user.phone : '',
      phoneType: user.phoneType ? user.phoneType : 'phone_type_mobile',
      numeroJustificatif: user.pieceIdentite.numeroPiece,
      lieuNaissance: user.lieuNaissance,
      dateDelivrance: dateDelivranceReformated ? dateDelivranceReformated : '',
      lieuDelivrance: user.pieceIdentite.idDocumentIssuedBy,
      numeroTelephone2: user.phone2 ? user.phone2 : '',
      phoneType2: user.phone2 && user.phoneType2 ? user.phoneType2 : 'phone_type_mobile'
    });

    this.setTypeJustificatifIdentite(user.pieceIdentite.typeJustificatif);
    this.idPieceIdentite = user.pieceIdentite.id;

    this.nomFichierRecto = user.pieceIdentite.justificatifRecto;
    this.justificatifRectoValid = true;
    this.nomFichierVerso = user.pieceIdentite.justificatifVerso;
    this.justificatifVersoValid = true;

    this.fcDateNaissance.setErrors(null);
    this.fcDateDelivrance.setErrors(null);
  }

  private async presentAlertConfirmationUpdate(): Promise<void> {
    const alert = await this.alertController.create({
      header: '',
      message:
        '<p>Les modifications <br>de votre profil <br>ont bien été prises <br>en compte.</p>',
      cssClass: 'updateConfirmAlert',
      buttons: ['FERMER'],
    });

    await alert.present();
  }
}
