import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PopoverInfosCreationCompteComponent} from './popover-infos-creation-compte.component';
import {IonicModule} from '@ionic/angular';
import {ActivationCompteModule} from '../../admin/activation-compte/activation-compte.module';



@NgModule({
  declarations: [
    PopoverInfosCreationCompteComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ActivationCompteModule
  ],
  exports: [
    PopoverInfosCreationCompteComponent
  ]
})
export class PopoverInfosCreationCompteModule { }
