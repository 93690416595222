<div class="footer">

  <div class="button-menu separator"
       (click)="routeButton('/public')"
       [style.background-color]="currentPage==='Accueil' ? couleur.getGreen(): null">
    <ion-icon src="assets/svg/accueil.svg"></ion-icon>
    <div class="content-label">
      <p class="typo-11-bold-white">Accueil</p>
    </div>
  </div>

  <div class="button-menu separator"
       (click)="routeButton('services')"
       [style.background-color]="currentPage==='services' ? couleur.getYellow(): null">
    <ion-icon src="assets/svg/bulle-mystere.svg"></ion-icon>
    <div class="content-label">
      <p class="typo-11-bold-white">Demandes & Services</p>
    </div>
  </div>

  <div class="button-menu"
       (click)="routeButton('parcelles')"
       [style.background-color]="currentPage==='parcelles' ? couleur.getRed(): null">
    <ion-icon src="assets/svg/map.svg"></ion-icon>
    <div class="content-label">
      <p class="typo-11-bold-white">Parcelles</p>
    </div>
  </div>

  <div class="button-menu separator"
       (click)="routeButton(isConnected?'/demarches-prive':'/suivi-public')"
       [style.background-color]="currentPage==='demandes-connecte' || currentPage==='suivi-public' ? couleur.getBlue(): null">
    <ion-icon src="assets/svg/processus.svg"></ion-icon>
    <div class="content-label">
      <p class="typo-11-bold-white">Demandes</p>
    </div>
  </div>


</div>
