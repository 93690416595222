<ion-header>
  <ion-toolbar>
    <ion-title>Liste des types de demande </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">Fermer</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
       <ion-col size="6" *ngFor="let type_demande of favorisStr; let i = index">
        <ion-item>
          <ion-checkbox slot="start" (ionChange)="onCheckboxChange(type_demande)" [checked]="type_demande.checked"></ion-checkbox>
          <ion-label>{{ type_demande.label }}</ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

