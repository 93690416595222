/**
 * Cette classe comporte les constantes concernant les liens issus du header et du footer
 */
export class ConstantesLiens {

  public static FACEBOOK = 'https://fr-fr.facebook.com/ministereCLU/';

  public static TWITTER = 'https://twitter.com/mclu_gouv';

  public static YOUTUBE = 'https://www.youtube.com/channel/UCyuBoODjMi_XJp6cjk27TPg';

  public static GOUVERNEMENT = 'https://www.gouv.ci/Main.php';

  public static MCLU = 'http://construction.gouv.ci/';

  public static ADMINISTRATION = 'https://servicepublic.gouv.ci/';

  public static SERVICES_PUBLICS = 'http://annuaire.gouv.ci/';

  public static PARTICIPATION_CITOYENNE = 'https://www.ansut.ci/fr/participation/';

  public static OPEN_DATA = 'https://data.gouv.ci/';

  public static TELECOMMUNICATIONS = 'https://www.ansut.ci/fr/home/';

  public static EMAIL = 'mailto: centredappelmclu@construction.gouv.ci';

}
