import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PlateformeService } from 'src/app/services/plateforme/plateforme.service';
import { KeycloakService } from '../../../../services/keycloak/keycloak.service';
import { Router } from '@angular/router';
import { ConstantesLiens } from '../../../constantes/constantes-liens';
import { AbstractHeader } from './abstract-header';
import { AdministrateursService, UserDto, UsersService } from 'openapi/build';
import { PopoverController } from '@ionic/angular';
import {
  PopoverDetailsCompteParticulierComponent
} from '../../popover/popover-details-compte-particulier/popover-details-compte-particulier.component';

@Component({
  selector: 'mclu-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header.component-mobile.scss'],
})
export class HeaderComponent extends AbstractHeader implements OnInit {

  @Input()
  public showButtons = true;

  @Input()
  public isPageProfil = false;

  @Input()
  public currentPage: 'accueil' | 'accueil-particulier' | 'accueil-pro' | 'services' | 'demandes' | 'parcelles'
    | 'demandes-connecte' | 'creationprofil' | 'pro' | 'suivi-public' | 'login';

  public constantes = ConstantesLiens;
  public user: UserDto;
  /**
   * Constructeur.
   *
   * @param keycloakService
   * @param utilisateurService
   * @param plateforme
   * @param administrateursService
   * @param router
   * @param usersService
   * @param popoverController
   */
  constructor(public keycloakService: KeycloakService,
              public plateforme: PlateformeService,
              public administrateursService: AdministrateursService,
              public router: Router,
              public usersService: UsersService,
              public popoverController: PopoverController) {
    super(keycloakService, router, administrateursService, plateforme, usersService,popoverController);
  }

  public ngOnInit() {
    this.getProfil();
  }

  public connection(): void {
    this.router.navigateByUrl(this.isPro ? '/professionnel/login' : '/login');
  }

  public override async afficherMonProfil(): Promise<void> {


    if (this.currentPage === 'accueil-particulier' || this.currentPage === 'services'
    || this.currentPage === 'demandes' || this.currentPage === 'parcelles' || this.currentPage === 'demandes-connecte' ){
      this.router.navigateByUrl('mon-profil', { state: [this.router.url] });
    }
  else{
    const username = this.keycloakService.getUserProfile().username;

    this.usersService.userControllerGetUserByUsername(encodeURI(username))
      .toPromise()
      .then((user: UserDto) => {
        this.user = user;
        this.popoverDetailsCompte(this.popoverController, user).then();
      });
    }

  }

  private async popoverDetailsCompte(popoverController: PopoverController, user: UserDto): Promise<void> {
    const popup = await popoverController.create({
      component: PopoverDetailsCompteParticulierComponent,
      cssClass: 'popover-detail-particulier',
      componentProps: {user}
    });
    await popup.present();
  }

}
