/* eslint-disable max-len */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlateformeService } from '../../../services/plateforme/plateforme.service';
import { DepotLigneSourceDto } from 'openapi/build';
import { FormControl } from '@angular/forms';
import { PhotoService } from 'src/app/services/photo/photo.service';

@Component({
  selector: 'mclu-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit {

  @Input()
  public label: string;
  @Input()
  public nomFichier: string;
  @Input()
  public readonly = false;
  @Input()
  public lecture = false;
  @Input()
  public index: number;
  @Input()
  public inputId: string;

  @Input()
  public typeFile: string;

  @Input()
  public isGreen = false;

  @Input()
  public isBlue = false;

  @Input()
  public depoLigneSourceDto: DepotLigneSourceDto = null;

  @Input()
  public fileTypes = ['image/png', 'image/jpeg'];

  @Input()
  public fileAccept = "image/png, image/jpeg";

  @Input()
  public maxFileSize = 10485760;

  @Input()
  public viewerEnable = true;

  @Input()
  public displayed = true;

  @Output()
  public fileEnable: EventEmitter<{ change: boolean; fichier: any; statut: boolean; idOption: number }> = new EventEmitter<{ change: boolean; fichier: any; statut: boolean; idOption: number }>();

  public fcOption = new FormControl('');
  public isMultiOptions = false;

  public invalidFile = false;

  public imageSource = '';

  public imageVisible = false;

  public fichiers: any[] = [];

  private apiBasePath = environment.apiBasePath;
  private apiFileBasePath = environment.apiFileBasePath;

  constructor(public plateforme: PlateformeService, private photoService: PhotoService) {
  }

  ngOnInit() {
    if (this.depoLigneSourceDto) {
      if (this.label === "Piece d'Identité") {
        this.label = "Justificatif d'identité";
      }

      if (this.depoLigneSourceDto.lstTypeCode.split('||').length > 1) {
        this.isMultiOptions = true;
      }
      this.fcOption.setValue(0);
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < this.depoLigneSourceDto?.lstTypeCode.split('||').length; i++) {
        if (this.depoLigneSourceDto.lstTypeCode.split('||')[i] === this.depoLigneSourceDto.typeCode) {
          this.fcOption.setValue(i);
          break;
        }
      }

      if (this.depoLigneSourceDto) {
        if (this.depoLigneSourceDto.file) {
          this.fileEnable.emit({ change: false, fichier: this.fichiers[0], statut: true, idOption: this.fcOption.value });
        }
        else {
          this.fileEnable.emit({ change: false, fichier: this.fichiers[0], statut: false, idOption: this.fcOption.value });
        }
      }
    }
  }



  /**
   * Vérifie si le fichier ne dépasse pas la taille max et si le type mime est correct
   * puis appel la méthode pour traiter le fichier
   *
   * @param files
   */
  public fileBrowseHandler(files: Array<File>): void {
    if (!files[0]) {
      this.imageVisible = false;
      this.invalidFile = true;
      this.fileEnable.emit({ change: true, fichier: undefined, statut: false, idOption: this.fcOption.value });
    } else if (files[0].size <= this.maxFileSize
      && this.hasTypeFile(files[0].type)) {
      this.invalidFile = false;
      this.prepareFilesList(files[0]);
    } else {
      this.invalidFile = true;
      this.fileEnable.emit({ change: true, fichier: undefined, statut: false, idOption: this.fcOption.value });
    }
  }

  /**
   * Retourne vrai si le type du fichier fait parti de la liste des types autorisés.
   * @param typeFile 
   * @returns 
   */
  private hasTypeFile(typeFile: string) {
    if (typeFile && this.fileTypes) {
      return this.fileTypes.includes(typeFile);
    }

    return false;
  }

  /**
   * Ajoute chaque fichier dans notre tableau de fichier
   */
  public async prepareFilesList(file: File) {
    if (this.fileAccept === "image/png, image/jpeg" || this.fileAccept === "image/png" || this.fileAccept === "image/jpeg") {
      this.photoService.compressBlobToFile(file).then(compressedFile => {
        this.fichiers.push(compressedFile);
      }).catch(err => {
        console.error('Compression Error:', err);
        // So we take the file not compressed
        this.fichiers.push(file);
      });
    } else {
      this.fichiers.push(file);
    }

    const reader = new FileReader();
    reader.onloadend = (e) => {
      this.imageSource = e.target.result + '';
    };
    reader.readAsDataURL(file);

    this.fileEnable.emit({ change: true, fichier: this.fichiers[0], statut: true, idOption: this.fcOption.value });
  }


  public supprimerFichier(): void {
    if (!this.lecture) {
      this.imageVisible = false;
      this.invalidFile = true;
      this.fichiers = [];
      this.nomFichier = '';
      this.fileEnable.emit({ change: true, fichier: this.fichiers[0], statut: false, idOption: this.fcOption.value });
      const elementInput = document.getElementById(this.inputId) as HTMLInputElement;
      elementInput.value = '';
    }
  }

  public modifierFichier(): void {
    if (!this.lecture) {
      this.supprimerFichier();
      document.getElementById(this.inputId).click();
    }
  }

  public visualiserFichier(): void {
    if (this.lecture) {
      window.open(this.apiBasePath + this.apiFileBasePath + '/piece-identite/visualiser/' + encodeURI(this.depoLigneSourceDto.file), '_blank');
    } else {
      if (this.nomFichier) {
        this.imageSource = this.apiBasePath + this.apiFileBasePath + '/piece-identite/' + encodeURI(this.nomFichier);
      }
      this.imageVisible = !this.imageVisible;
    }
  }

  public changeType() {
    this.fileEnable.emit({ change: true, fichier: this.fichiers[0], statut: !this.invalidFile, idOption: this.fcOption.value });
  }

}

