/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NationaliteService {

  private listeNationalites = [
    {
      "id": 49,
      "label": "Côte d'Ivoire"
    },
    {
      "id": 1,
      "label": "Afghanistan"
    },
    {
      "id": 2,
      "label": "Afrique du Sud"
    },
    {
      "id": 3,
      "label": "Albanie"
    },
    {
      "id": 4,
      "label": "Algérie"
    },
    {
      "id": 5,
      "label": "Allemagne"
    },
    {
      "id": 6,
      "label": "Andorre"
    },
    {
      "id": 7,
      "label": "Angola"
    },
    {
      "id": 8,
      "label": "Antigua-et-Barbuda"
    },
    {
      "id": 9,
      "label": "Arabie saoudite"
    },
    {
      "id": 10,
      "label": "Argentine"
    },
    {
      "id": 11,
      "label": "Arménie"
    },
    {
      "id": 12,
      "label": "Australie"
    },
    {
      "id": 13,
      "label": "Autriche"
    },
    {
      "id": 14,
      "label": "Azerbaïdjan"
    },
    {
      "id": 15,
      "label": "Bahamas"
    },
    {
      "id": 16,
      "label": "Bahreïn"
    },
    {
      "id": 17,
      "label": "Bangladesh"
    },
    {
      "id": 18,
      "label": "Barbade"
    },
    {
      "id": 19,
      "label": "Belgique"
    },
    {
      "id": 20,
      "label": "Bélize"
    },
    {
      "id": 21,
      "label": "Bénin"
    },
    {
      "id": 22,
      "label": "Bhoutan"
    },
    {
      "id": 23,
      "label": "Biélorussie"
    },
    {
      "id": 24,
      "label": "Birmanie"
    },
    {
      "id": 25,
      "label": "Bolivie"
    },
    {
      "id": 26,
      "label": "Bosnie-Herzégovine"
    },
    {
      "id": 27,
      "label": "Botswana"
    },
    {
      "id": 28,
      "label": "Brésil"
    },
    {
      "id": 29,
      "label": "Brunei"
    },
    {
      "id": 30,
      "label": "Bulgarie"
    },
    {
      "id": 31,
      "label": "Burkina"
    },
    {
      "id": 32,
      "label": "Burundi"
    },
    {
      "id": 33,
      "label": "Cambodge"
    },
    {
      "id": 34,
      "label": "Cameroun"
    },
    {
      "id": 35,
      "label": "Canada"
    },
    {
      "id": 36,
      "label": "Cap-Vert"
    },
    {
      "id": 37,
      "label": "Centrafrique"
    },
    {
      "id": 38,
      "label": "Chili"
    },
    {
      "id": 39,
      "label": "Chine"
    },
    {
      "id": 40,
      "label": "Chypre"
    },
    {
      "id": 41,
      "label": "Colombie"
    },
    {
      "id": 42,
      "label": "Comores"
    },
    {
      "id": 43,
      "label": "Congo"
    },
    {
      "id": 44,
      "label": "République démocratique du Congo"
    },
    {
      "id": 45,
      "label": "Îles Cook"
    },
    {
      "id": 46,
      "label": "Corée du Nord"
    },
    {
      "id": 47,
      "label": "Corée du Sud"
    },
    {
      "id": 48,
      "label": "Costa Rica"
    },
    {
      "id": 50,
      "label": "Croatie"
    },
    {
      "id": 51,
      "label": "Cuba"
    },
    {
      "id": 52,
      "label": "Danemark"
    },
    {
      "id": 53,
      "label": "Djibouti"
    },
    {
      "id": 54,
      "label": "République dominicaine"
    },
    {
      "id": 55,
      "label": "Dominique"
    },
    {
      "id": 56,
      "label": "Égypte"
    },
    {
      "id": 57,
      "label": "Émirats arabes unis"
    },
    {
      "id": 58,
      "label": "Équateur"
    },
    {
      "id": 59,
      "label": "Érythrée"
    },
    {
      "id": 60,
      "label": "Espagne"
    },
    {
      "id": 61,
      "label": "Estonie"
    },
    {
      "id": 62,
      "label": "Eswatini"
    },
    {
      "id": 63,
      "label": "États-Unis"
    },
    {
      "id": 64,
      "label": "Éthiopie"
    },
    {
      "id": 65,
      "label": "Fidji"
    },
    {
      "id": 66,
      "label": "Finlande"
    },
    {
      "id": 67,
      "label": "France"
    },
    {
      "id": 68,
      "label": "Gabon"
    },
    {
      "id": 69,
      "label": "Gambie"
    },
    {
      "id": 70,
      "label": "Géorgie"
    },
    {
      "id": 71,
      "label": "Ghana"
    },
    {
      "id": 72,
      "label": "Grèce"
    },
    {
      "id": 73,
      "label": "Grenade"
    },
    {
      "id": 74,
      "label": "Guatémala"
    },
    {
      "id": 75,
      "label": "Guinée"
    },
    {
      "id": 76,
      "label": "Guinée équatoriale"
    },
    {
      "id": 77,
      "label": "Guinée-Bissao"
    },
    {
      "id": 78,
      "label": "Guyana"
    },
    {
      "id": 79,
      "label": "Haïti"
    },
    {
      "id": 80,
      "label": "Honduras"
    },
    {
      "id": 81,
      "label": "Hongrie"
    },
    {
      "id": 82,
      "label": "Inde"
    },
    {
      "id": 83,
      "label": "Indonésie"
    },
    {
      "id": 84,
      "label": "Irak"
    },
    {
      "id": 85,
      "label": "Iran"
    },
    {
      "id": 86,
      "label": "Irlande"
    },
    {
      "id": 87,
      "label": "Islande"
    },
    {
      "id": 88,
      "label": "Israël"
    },
    {
      "id": 89,
      "label": "Italie"
    },
    {
      "id": 90,
      "label": "Jamaïque"
    },
    {
      "id": 91,
      "label": "Japon"
    },
    {
      "id": 92,
      "label": "Jordanie"
    },
    {
      "id": 93,
      "label": "Kazakhstan"
    },
    {
      "id": 94,
      "label": "Kénya"
    },
    {
      "id": 95,
      "label": "Kirghizstan"
    },
    {
      "id": 96,
      "label": "Kiribati"
    },
    {
      "id": 97,
      "label": "Kosovo"
    },
    {
      "id": 98,
      "label": "Koweït"
    },
    {
      "id": 99,
      "label": "Laos"
    },
    {
      "id": 100,
      "label": "Lésotho"
    },
    {
      "id": 101,
      "label": "Lettonie"
    },
    {
      "id": 102,
      "label": "Liban"
    },
    {
      "id": 103,
      "label": "Libéria"
    },
    {
      "id": 104,
      "label": "Libye"
    },
    {
      "id": 105,
      "label": "Liechtenstein"
    },
    {
      "id": 106,
      "label": "Lituanie"
    },
    {
      "id": 107,
      "label": "Luxembourg"
    },
    {
      "id": 108,
      "label": "Macédoine du Nord"
    },
    {
      "id": 109,
      "label": "Madagascar"
    },
    {
      "id": 110,
      "label": "Malaisie"
    },
    {
      "id": 111,
      "label": "Malawi"
    },
    {
      "id": 112,
      "label": "Maldives"
    },
    {
      "id": 113,
      "label": "Mali"
    },
    {
      "id": 114,
      "label": "Malte"
    },
    {
      "id": 115,
      "label": "Maroc"
    },
    {
      "id": 116,
      "label": "Îles Marshall"
    },
    {
      "id": 117,
      "label": "Maurice"
    },
    {
      "id": 118,
      "label": "Mauritanie"
    },
    {
      "id": 119,
      "label": "Mexique"
    },
    {
      "id": 120,
      "label": "Micronésie"
    },
    {
      "id": 121,
      "label": "Moldavie"
    },
    {
      "id": 122,
      "label": "Monaco"
    },
    {
      "id": 123,
      "label": "Mongolie"
    },
    {
      "id": 124,
      "label": "Monténégro"
    },
    {
      "id": 125,
      "label": "Mozambique"
    },
    {
      "id": 126,
      "label": "Namibie"
    },
    {
      "id": 127,
      "label": "Nauru"
    },
    {
      "id": 128,
      "label": "Népal"
    },
    {
      "id": 129,
      "label": "Nicaragua"
    },
    {
      "id": 130,
      "label": "Niger"
    },
    {
      "id": 131,
      "label": "Nigéria"
    },
    {
      "id": 132,
      "label": "Niue"
    },
    {
      "id": 133,
      "label": "Norvège"
    },
    {
      "id": 134,
      "label": "Nouvelle-Zélande"
    },
    {
      "id": 135,
      "label": "Oman"
    },
    {
      "id": 136,
      "label": "Ouganda"
    },
    {
      "id": 137,
      "label": "Ouzbékistan"
    },
    {
      "id": 138,
      "label": "Pakistan"
    },
    {
      "id": 139,
      "label": "Palaos"
    },
    {
      "id": 140,
      "label": "Panama"
    },
    {
      "id": 141,
      "label": "Papouasie-Nouvelle-Guinée"
    },
    {
      "id": 142,
      "label": "Paraguay"
    },
    {
      "id": 143,
      "label": "Pays-Bas"
    },
    {
      "id": 144,
      "label": "Pérou"
    },
    {
      "id": 145,
      "label": "Philippines"
    },
    {
      "id": 146,
      "label": "Pologne"
    },
    {
      "id": 147,
      "label": "Portugal"
    },
    {
      "id": 148,
      "label": "Qatar"
    },
    {
      "id": 149,
      "label": "Roumanie"
    },
    {
      "id": 150,
      "label": "Royaume-Uni"
    },
    {
      "id": 151,
      "label": "Russie"
    },
    {
      "id": 152,
      "label": "Rwanda"
    },
    {
      "id": 153,
      "label": "Saint-Christophe-et-Niévès"
    },
    {
      "id": 154,
      "label": "Sainte-Lucie"
    },
    {
      "id": 155,
      "label": "Saint-Marin"
    },
    {
      "id": 156,
      "label": "Saint-Vincent-et-les-Grenadines"
    },
    {
      "id": 157,
      "label": "Salomon"
    },
    {
      "id": 158,
      "label": "Salvador"
    },
    {
      "id": 159,
      "label": "Samoa"
    },
    {
      "id": 160,
      "label": "Sao Tomé-et-Principe"
    },
    {
      "id": 161,
      "label": "Sénégal"
    },
    {
      "id": 162,
      "label": "Serbie"
    },
    {
      "id": 163,
      "label": "Seychelles"
    },
    {
      "id": 164,
      "label": "Sierra Leone"
    },
    {
      "id": 165,
      "label": "Singapour"
    },
    {
      "id": 166,
      "label": "Slovaquie"
    },
    {
      "id": 167,
      "label": "Slovénie"
    },
    {
      "id": 168,
      "label": "Somalie"
    },
    {
      "id": 169,
      "label": "Soudan"
    },
    {
      "id": 170,
      "label": "Soudan du Sud"
    },
    {
      "id": 171,
      "label": "Sri Lanka"
    },
    {
      "id": 172,
      "label": "Suède"
    },
    {
      "id": 173,
      "label": "Suisse"
    },
    {
      "id": 174,
      "label": "Suriname"
    },
    {
      "id": 175,
      "label": "Syrie"
    },
    {
      "id": 176,
      "label": "Tadjikistan"
    },
    {
      "id": 177,
      "label": "Tanzanie"
    },
    {
      "id": 178,
      "label": "Tchad"
    },
    {
      "id": 179,
      "label": "Tchéquie"
    },
    {
      "id": 180,
      "label": "Thaïlande"
    },
    {
      "id": 181,
      "label": "Timor oriental"
    },
    {
      "id": 182,
      "label": "Togo"
    },
    {
      "id": 183,
      "label": "Tonga"
    },
    {
      "id": 184,
      "label": "Trinité-et-Tobago"
    },
    {
      "id": 185,
      "label": "Tunisie"
    },
    {
      "id": 186,
      "label": "Turkménistan"
    },
    {
      "id": 187,
      "label": "Turquie"
    },
    {
      "id": 188,
      "label": "Tuvalu"
    },
    {
      "id": 189,
      "label": "Ukraine"
    },
    {
      "id": 190,
      "label": "Uruguay"
    },
    {
      "id": 191,
      "label": "Vanuatu"
    },
    {
      "id": 192,
      "label": "Vatican"
    },
    {
      "id": 193,
      "label": "Vénézuéla"
    },
    {
      "id": 194,
      "label": "Vietnam"
    },
    {
      "id": 195,
      "label": "Yémen"
    },
    {
      "id": 196,
      "label": "Zambie"
    },
    {
      "id": 197,
      "label": "Zimbabwé"
    }
  ];

  constructor() { }

  public getListeNationalitesOptions(): string[] {
    return this.listeNationalites.map((nationalite) => nationalite.label);
  }

  public getIdNationaliteByNom(nationalite: string): string {
    const nation = this.listeNationalites.find((element) => element.label === nationalite);

    if (nation?.id) {
      return nation?.id.toString();
    } else {
      return '';
    }
  }
}
