/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './core/authentication.guard';
import { ConstantesRolesEnum } from './shared/constantes/constantes-roles.enum';
import { MonProfilComponent } from './modules/mon-profil/mon-profil.component';



const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash-screen',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: 'professionnel/login',
    loadChildren: () =>
      import('./modules/login/login.module').then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: 'reset-credentials',
    loadChildren: () =>
      import('./modules/reset-credentials/reset-credentials.module').then(
        (m) => m.ResetCredentialsPageModule
      ),
  },
  {
    path: 'qr-code',
    loadChildren: () =>
      import('./modules/qr/qr.module').then(
        (m) => m.QrPageModule
      ),
  },
  {
    path: 'splash-screen',
    loadChildren: () =>
      import('./modules/splash-screen/splash-screen.module').then(
        (m) => m.SplashScreenPageModule
      ),
  },
  {
    path: 'accueil',
    loadChildren: () =>
      import('./modules/accueil-principal/accueil-principal.module').then(
        (m) => m.AccueilPrincipalPageModule
      ),
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./modules/accueil-public/accueil-public.module').then(
        (m) => m.AccueilPublicPageModule
      ),
  },
  {
    path: 'professionnel',
    loadChildren: () =>
      import('./modules/pro/accueil/accueil.module').then(
        (m) => m.AccueilPageModule
      ),
  },
  {
    path: 'professionnel/lotissements',
    loadChildren: () =>
      import('./modules/pro/pro-lotissements/pro-lotissements.module').then(
        (m) => m.ProLotissementsPageModule
      ),
  },
  {
    path: 'professionnel/demandes',
    loadChildren: () =>
      import('./modules/pro/pro-demandes/pro-demandes.module').then(
        (m) => m.ProDemandesPageModule
      ),
  },
  {
    path: 'professionnel/types-demande',
    loadChildren: () =>
      import('./modules/pro/pro-types-demande/pro-types-demande.module').then(
        (m) => m.ProTypesDemandePageModule
      ),
  },
  {
    path: 'professionnel/depot-ligne',
    loadChildren: () =>
      import('./modules/pro/pro-depot-ligne/pro-depot-ligne.module').then(
        (m) => m.ProDepotLignePageModule
      ),
  },
  {
    path: 'professionnel/repertoire-expert',
    loadChildren: () =>
      import('./modules/pro/pro-repertoire-expets/pro-repertoire-expets.module').then(
        (m) => m.ProRepertoireExpetsPageModule
      ),
  },
  {
    path: 'professionnel/suivi-demandes',
    loadChildren: () =>
      import('./modules/pro/pro-suivi-demandes/pro-suivi-demandes.module').then(
        (m) => m.ProSuiviDemandesPageModule
      ),
  },
  {
    path: 'professionnel/cartographie',
    loadChildren: () => import('./modules/cartographie/cartographie.module').then(m => m.CartographiePageModule)
  },
  {
    path: 'parcelles',
    loadChildren: () =>
      import('./modules/parcelles/parcelles-recherche/parcelles-recherche.module').then(
        (m) => m.ParcellesRecherchePageModule
      ),
  },
  {
    path: 'services',
    loadChildren: () => import('./modules/services/service-demande/service-demande.module').then(m => m.ServiceDemandePageModule)
  },
  {
    path: 'repertoires-expert',
    loadChildren: () => import('./modules/repertoires-expert/public-repertoires-expert.module').then(m => m.PublicRepertoiresExpertModules)
  },

  {
    path: 'service-demande',
    loadChildren: () => import('./modules/services/service-demande/service-demande.module').then(m => m.ServiceDemandePageModule)
  },
  {
    path: 'demarches-prive',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.particulier] },
    loadChildren: () =>
      import('./modules/demarches-prive/demarches-prive.module').then(
        (m) => m.DemarchesPrivePageModule
      ),
  },
  {
    path: 'demarches-prive/details',
    loadChildren: () => import('./modules/demarches-prive/demarches-prive-details/demarches-prive-details.module').then(m => m.DemarchesPriveDetailsModule),
  },
  {
    path: 'creation-compte',
    loadChildren: () =>
      import('./modules/creation-compte/creation-compte.module').then(
        (m) => m.CreationComptePageModule
      ),
  },
  {
    path: 'mon-profil',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.particulier] },
    loadChildren: () =>
      import('./modules/mon-profil/mon-profil.module').then(
        (m) => m.MonProfilModule
      ),
  },
  {
    path: 'content',
    loadChildren: () =>
      import('./modules/directus/article/article.module').then(
        (m) => m.ArticlePageModule
      ),
  },
  {
    path: 'mon-profil/mot-de-passe',
    component: MonProfilComponent,
    children: [
      {
        path: 'nouveau/:token',
        loadChildren: () => import('./modules/modification-mot-de-passe/modification-mot-de-passe.module')
          .then(m => m.ModificationMotDePassePageModule)
      }
    ]
  },
  {
    path: 'administration/liste-utilisateur-pro',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.adminCorpo] },
    // eslint-disable-next-line max-len
    loadChildren: () =>
      import(
        './modules/admin/liste-utilisateur-pro/liste-utilisateur-pro.module'
      ).then((m) => m.ListeUtilisateurProPageModule),
  },
  {
    path: 'administration/creation-compte',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.adminCorpo] },
    loadChildren: () =>
      import('./modules/admin/creation-compte/creation-compte.module').then(
        (m) => m.ComptePageModule
      ),
  },
  {
    path: 'administration/modification-compte',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.adminCorpo, ConstantesRolesEnum.pro] },
    loadChildren: () =>
      import(
        './modules/admin/modification-compte/modification-compte.module'
      ).then((m) => m.ModificationCompteModule),
  },
  {
    path: 'administration/modification-mot-de-passe',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.adminCorpo, ConstantesRolesEnum.pro] },
    // eslint-disable-next-line max-len
    loadChildren: () =>
      import(
        './modules/admin/modification-mot-de-passe/modification-mot-de-passe.module'
      ).then((m) => m.ModificationMotDePasseModule),
  },
  {
    path: 'administration/activation-compte',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.adminCorpo] },
    loadChildren: () =>
      import('./modules/admin/activation-compte/activation-compte.module').then(
        (m) => m.ActivationComptePageModule
      ),
  },
  {
    path: 'administration/suppression-compte',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.adminCorpo] },
    loadChildren: () =>
      import(
        './modules/admin/suppression-compte/suppression-compte.module'
      ).then((m) => m.SuppressionCompteModule),
  },
  {
    path: 'administrateur',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin, ConstantesRolesEnum.adminFrais, ConstantesRolesEnum.adminGUF, ConstantesRolesEnum.adminFAQ] },
    loadChildren: () =>
      import('./modules/super-admin/accueil-sa/accueil-sa.module').then(
        (m) => m.AccueilSaPageModule
      ),
  },
  {
    path: 'administrateur/liste-utilisateur-particulier',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin] },
    loadChildren: () =>
      import(
        './modules/super-admin/liste-utilisateur-particulier/liste-utilisateur-particulier.module'
      ).then((m) => m.ListeUtilisateurParticulierPageModule),
  },
  {
    path: 'administrateur/suppression-compte-particulier',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin] },
    loadChildren: () =>
      import(
        './modules/super-admin/suppression-compte-particulier/suppression-compte-particulier.module'
      ).then((m) => m.SuppressionCompteParticulierPageModule),
  },
  {
    path: 'administrateur/liste-utilisateur-corporation/:type',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin] },
    loadChildren: () =>
      import(
        './modules/super-admin/liste-utilisateur-corporation/liste-utilisateur-corporation.module'
      ).then((m) => m.ListeUtilisateurCorporationPageModule),
  },
  {
    path: 'administrateur/suppression-compte-corporation',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin] },
    loadChildren: () =>
      import(
        './modules/super-admin/suppression-compte-corporation/suppression-compte-corporation.module'
      ).then((m) => m.SuppressionCompteCorporationPageModule),
  },
  {
    path: 'administrateur/modification-compte-corporation',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin, ConstantesRolesEnum.adminCorpo] },
    loadChildren: () =>
      import(
        './modules/super-admin/modification-compte-corporation/modification-compte-corporation.module'
      ).then((m) => m.ModificationCompteCorporationPageModule),
  },
  {
    path: 'administrateur/modification-mot-de-passe-corporation',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin] },
    loadChildren: () =>
      import(
        './modules/super-admin/modification-mot-de-passe-corporation/modification-mot-de-passe-corporation.module'
      ).then((m) => m.ModificationMotDePasseCorporationPageModule),
  },
  {
    path: 'administrateur/activation-compte-corporation',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin] },
    loadChildren: () =>
      import(
        './modules/super-admin/activation-compte-corporation/activation-compte-corporation.module'
      ).then((m) => m.ActivationCompteCorporationPageModule),
  },
  {
    path: 'administrateur/creation-compte-corporation/:type',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin] },
    loadChildren: () =>
      import(
        './modules/super-admin/creation-compte-corporation/creation-compte-corporation.module'
      ).then((m) => m.CreationCompteCorporationPageModule),
  },
  {
    path: 'administrateur/modification-activation-compte',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin] },

    // eslint-disable-next-line max-len
    loadChildren: () => import('./modules/super-admin/modification-activation-compte/modification-activation-compte.module').then(m => m.ModificationActivationComptePageModule)
  },
  {
    path: 'administrateur/monitoring-comptes',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin] },

    // eslint-disable-next-line max-len
    loadChildren: () => import('./modules/super-admin/monitoring-comptes/monitoring-comptes.module').then(m => m.MonitoringComptesPageModule)
  },
  {
    path: 'mentions-legales',
    loadChildren: () => import('./modules/mention-legales/mention-legales.module').then(m => m.MentionLegalesPageModule)
  },
  {
    path: 'pro-lotissements',
    loadChildren: () => import('./modules/pro/pro-lotissements/pro-lotissements.module').then(m => m.ProLotissementsPageModule)
  },
  {
    path: 'pro-types-demande',
    loadChildren: () => import('./modules/pro/pro-types-demande/pro-types-demande.module').then(m => m.ProTypesDemandePageModule)
  },
  {
    path: 'pro-demandes',
    loadChildren: () => import('./modules/pro/pro-demandes/pro-demandes.module').then(m => m.ProDemandesPageModule)
  },
  {
    path: 'pro-suivi-demandes',
    loadChildren: () => import('./modules/pro/pro-suivi-demandes/pro-suivi-demandes.module').then(m => m.ProSuiviDemandesPageModule)
  },
  {
    path: 'professionnel/contact-guf',
    loadChildren: () => import('./modules/pro/pro-contact-guf/pro-contact-guf.module').then(m => m.ProContactGufPageModule)
  },
  {
    path: 'pro-repertoire-expets',
    // eslint-disable-next-line max-len
    loadChildren: () => import('./modules/pro/pro-repertoire-expets/pro-repertoire-expets.module').then(m => m.ProRepertoireExpetsPageModule)
  },
  {
    path: 'pro-depot-ligne',
    loadChildren: () => import('./modules/pro/pro-depot-ligne/pro-depot-ligne.module').then(m => m.ProDepotLignePageModule)
  },
  {
    path: 'pro-demandes',
    loadChildren: () => import('./modules/pro/pro-demandes/pro-demandes.module').then(m => m.ProDemandesPageModule)
  },
  {
    path: 'pro-etat-depots',
    loadChildren: () => import('./modules/pro/pro-etat-depots/pro-etat-depots.module').then(m => m.ProEtatDepotsPageModule)
  },
  {
    path: 'pro-formulaire',
    loadChildren: () => import('./modules/pro/pro-formulaire/pro-formulaire.module').then(m => m.ProFormulairePageModule)
  },
  {
    path: 'pro-formulaire/:trid',
    loadChildren: () => import('./modules/pro/pro-formulaire/pro-formulaire.module').then(m => m.ProFormulairePageModule)
  },
  {
    path: 'pro-documents',
    loadChildren: () => import('./modules/pro/pro-documents/pro-documents.module').then(m => m.ProDocumentsPageModule)
  },
  {
    path: 'pro-depot-validation-list',
    loadChildren: () => import('./modules/pro/pro-depot-validation-list/pro-depot-validation-list.module').then(m => m.ProDepotValidationListPageModule)
  },
  {
    path: 'pro-depot-formulaire-modif',
    loadChildren: () => import('./modules/pro/pro-depot-formulaire-modif/pro-depot-formulaire-modif.module').then(m => m.ProDepotFormulaireModifPageModule)
  },
  {
    path: 'pro-depot-formulaire-lecture',
    loadChildren: () => import('./modules/pro/pro-depot-formulaire-lecture/pro-depot-formulaire-lecture.module').then(m => m.ProDepotFormulaireLecturePageModule)
  },
  {
    path: 'pro-depot-paiement/:trid',
    loadChildren: () => import('./modules/pro/pro-depot-paiement/pro-depot-paiement.module').then(m => m.ProDepotPaiementPageModule)
  },
  {
    path: 'pro-depot-paiement/retour/:reference',
    loadChildren: () => import('./modules/pro/pro-depot-paiement/pro-depot-paiement.module').then(m => m.ProDepotPaiementPageModule)
  },
  {
    path: 'contacts-guf',
    loadChildren: () => import('./modules/public/public-contacts-guf/public-contacts-guf.module').then(m => m.PublicContactsGufPageModule)
  },
  {
    path: 'administrateur/gestion-faq',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin, ConstantesRolesEnum.adminFAQ] },
    loadChildren: () => import('./modules/super-admin/gestion-de-faq/gestion-de-faq.module').then(m => m.GestionDeFaqModule)
  },
  {
    path: 'administrateur/gestion-contact-guf',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin, ConstantesRolesEnum.adminGUF] },
    loadChildren: () => import('./modules/super-admin/gestion-contact-guf/gestion-contact-guf.module').then(m => m.GestionContactGufModule)
  },
  {
    path: 'cartographie',
    loadChildren: () => import('./modules/cartographie/cartographie.module').then(m => m.CartographiePageModule)
  },
  {
    path: 'depot-ligne-type',
    loadChildren: () => import('./modules/public/public-depot-ligne/public-depot-ligne.module').then(m => m.PublicDepotLignePageModule)
  },
  {
    path: 'depot-ligne-type/:idLot',
    loadChildren: () => import('./modules/public/public-depot-ligne/public-depot-ligne.module').then(m => m.PublicDepotLignePageModule)
  },
  {
    path: 'etat-depots',
    loadChildren: () => import('./modules/public/public-etat-depots/public-etat-depots.module').then(m => m.PublicEtatDepotsPageModule)
  },
  {
    path: 'depot-ligne-formulaire',
    loadChildren: () => import('./modules/public/public-depot-ligne-formulaire/public-depot-ligne-formulaire.module').then(m => m.PublicDepotLigneFormulairePageModule)
  },
  {
    path: 'depot-ligne-formulaire/:trid',
    loadChildren: () => import('./modules/public/public-depot-ligne-formulaire/public-depot-ligne-formulaire.module').then(m => m.PublicDepotLigneFormulairePageModule)
  },
  {
    path: 'depot-ligne-lecture',
    loadChildren: () => import('./modules/public/public-depot-ligne-lecture/public-depot-ligne-lecture.module').then(m => m.PublicDepotLigneLecturePageModule)
  },
  {
    path: 'depot-ligne-modification',
    loadChildren: () => import('./modules/public/public-depot-ligne-modification/public-depot-ligne-modification.module').then(m => m.PublicDepotLigneModificationPageModule)
  },
  {
    path: 'depot-ligne-paiement/:trid',
    loadChildren: () => import('./modules/public/public-depot-ligne-paiement/public-depot-ligne-paiement.module').then(m => m.PublicDepotLignePaiementPageModule)
  },
  {
    path: 'depot-ligne-paiement/retour/:reference',
    loadChildren: () => import('./modules/public/public-depot-ligne-paiement/public-depot-ligne-paiement.module').then(m => m.PublicDepotLignePaiementPageModule)
  },
  {
    path: 'depot-ligne-liste',
    loadChildren: () => import('./modules/public/public-depot-ligne-liste/public-depot-ligne-liste.module').then(m => m.PublicDepotLigneListePageModule)
  },
  {
    path: 'depot-ligne-documents',
    loadChildren: () => import('./modules/public/public-depot-ligne-documents/public-depot-ligne-documents.module').then(m => m.PublicDepotLigneDocumentsPageModule)
  },
  {
    path: 'suivi-public',
    loadChildren: () => import('./modules/suivi-public/suivi-public.module').then(m => m.SuiviPublicPageModule)
  },
  {
    path: 'recuperation-id',
    loadChildren: () => import('./modules/super-admin/recuperation-identifiant/recuperation-identifiant.module').then(m => m.RecuperationIdentifiantModule)
  },
  {
    path: 'administrateur/choix-type-corporation',
    canActivate: [AuthenticationGuard],
    data: { role: [ConstantesRolesEnum.admin] },
    loadChildren: () => import('./modules/super-admin/choix-type-corporation/choix-type-corporation.module').then( m => m.ChoixTypeCorporationPageModule)
  },
  {
  path: 'administrateur/gestion-des-frais',
  canActivate: [AuthenticationGuard],
  data: { role: [ConstantesRolesEnum.admin, ConstantesRolesEnum.adminFrais] },
  loadChildren: () => import('./modules/super-admin/gestion-de-frais/gestion-de-frais.module').then( m => m.GestionDeFraisModule)
  },
  {
    path: 'pro-contact-guf',
    loadChildren: () => import('./modules/pro/pro-contact-guf/pro-contact-guf.module').then( m => m.ProContactGufPageModule)
  },
  {
    path: 'professionnel/authentification-document',
    loadChildren: () => import('./modules/pro/pro-authentification-document/pro-authentification-document.module').then( m => m.ProAuthentificationDocumentPageModule)
  },
  {
    path: 'reclamation',
    loadChildren: () => import('./modules/reclamation/reclamation.module').then( m => m.ReclamationPageModule)
  },
  {
    path: 'questionnaire-de-satisfaction',
    loadChildren: () => import('./modules/questionnaire-de-satisfaction/questionnaire-de-satisfaction.module').then( m => m.QuestionnaireDeSatisfactionModule)
  },
  {
    path: 'public/authentification-document',
    loadChildren: () => import('./modules/public/public-authentification-document/public-authentification-document.module').then( m => m.PublicAuthentificationDocumentPageModule)
  },
  {
    path:'near-guf',
    loadChildren: () => import('./modules/public/public-near-guf/public-near-guf.module').then(m => m.PublicNearGufPageModule)
  },
  {
    path: 'near-guf-map/:libel/:latitude/:longitude',
    loadChildren: () => import('./modules/public/public-contacts-guf-map/public-contacts-guf-map.module').then( m => m.PublicContactsGufMapPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
