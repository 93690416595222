import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {environment} from "../../../environments/environment";
import {Capacitor} from "@capacitor/core";

@Injectable({
  providedIn: 'root'
})
export class PlateformeService {

  private readonly TABLET_MIN_WIDTH = 768; // Minimum width for tablets in pixels

  /**
   * Constructeur.
   *
   * @param platform
   */
  constructor(private platform: Platform) {
  }

  /**
   * Retourne vrai si la plateforme est Mobile ou mobile web.
   *
   * @returns
   */
  public isMobile(): boolean {
    if (this.platform.is('capacitor') || environment.devMobile) {
      return true;
    }
    return (this.platform.is('capacitor') || this.platform.is('mobile') || this.platform.is('mobileweb')) && !this.hasDesktopOs();
  }

  public isMobileWeb(): boolean {
    return this.platform.is('mobileweb');
  }

  /**
   * Retourne vrai si la plateforme est Mobile ou mobile web.
   *
   * @returns
   */
  public isIOS(): boolean {
    return (this.platform.is('ios'));
  }

  /**
   * Retourne vrai si la plateforme est Mobile ou mobile web.
   *
   * @returns
   */
  public isMobileNative(): boolean {
    return (this.platform.is('capacitor') || !this.platform.is('mobileweb'));
  }

  public isNative(): boolean {
    return this.platform.is('capacitor');
  }

  /**
   * Retourne vrai si la plateforme est une tablette.
   *
   * @returns
   */
  public isTablet(): boolean {
    const isTablet = this.platform.is('tablet');
    const width = window.innerWidth;

    // Retirer par width les nouveau mobile en + de width que certain pc
    return isTablet && !this.hasDesktopOs();// || (width >= this.TABLET_MIN_WIDTH && this.isMobile());
  }

  public isTabletWeb(): boolean {
    return this.platform.is('tablet') && this.platform.is('mobileweb') && !this.hasDesktopOs();
    //return isTablet && !this.hasDesktopOs();// || (width >= this.TABLET_MIN_WIDTH && this.isMobile());
  }

  public hasDesktopOs(): boolean {
    if (environment.devMobile) {
      return false;
    }
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('windows')
      || userAgent.includes('macintosh')
      || (userAgent.includes('linux') && !userAgent.includes('android'));
  }

  public isDesktopTouch() {
    // Vérifier si c'est un desktop en fonction de l'OS ou de la taille de l'écran
    const isDesktop = !(/mobile/i.test(navigator.userAgent)) &&
      (navigator.userAgent.includes('Windows') && !navigator.userAgent.includes('Touch')) ||
      navigator.userAgent.includes('Macintosh');

    const hasTouchCapabilities = 'ontouchstart' in window ||
      navigator.maxTouchPoints > 0;

    return isDesktop && hasTouchCapabilities;
  }

  /**
   * Ancienne logique //Retourne vrai si la plateforme est DESKTOP ou une tablette.*
   * 11/09/2024 : Changement de logique, Tablette sera considéré comme mobile meme sur web
   * @returns
   */
  public isDesktop(): boolean {
    /*console.log('Is mobile ' + this.isMobile());
    console.log('Is Tablet Web ' + this.isTabletWeb());*/
    return this.isTabletWeb() || !this.isMobile();
  }
}
