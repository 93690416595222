/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Cette classe contient les constantes applicatives (chaine, timer, etc)
 */
 export class Constantes {


  public static BTN_NAV_SERVICES_TITRE = 'SERVICES';
  public static BTN_NAV_SERVICES_SOUSTITRE = 'Services et informations';
  public static BTN_NAV_SERVICES_TEXT = 'Consultez les types de demandes et les frais associés';
  public static BTN_NAV_SERVICES_ICON = 'bulle-mystere';
  public static BTN_NAV_SERVICES_LINK_TITRE = 'Consulter';
  public static BTN_NAV_SERVICES_LINK = '/services';
 }
