import { PlateformeService } from './../../../../services/plateforme/plateforme.service';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import KeycloakProfile from 'src/app/shared/interfaces/KeycloakProfile';
import {
  AdministrateursService,
  UserAdministrateurDto,
  UserDto,
  UsersService,
} from 'openapi/build';
import { KeycloakService } from 'src/app/services/keycloak/keycloak.service';
import { PopoverProps } from 'src/app/shared/interfaces/popover-props';
import { ConstantesRolesEnum } from '../../../constantes/constantes-roles.enum';
import { AbstractPopover } from '../../popover/abstract-popover/abstract-popover';

export class AbstractHeader extends AbstractPopover {
  public isConnected = false;
  public isPro = false;
  public userKeycloakProfil: KeycloakProfile | null = null;
  public userAdministrateurDto: UserAdministrateurDto;
  public user: UserDto;

  constructor(public keycloakService: KeycloakService,
              public router: Router,
              public administrateursService: AdministrateursService,
              public plateformeService: PlateformeService,
              public usersService: UsersService,
              public popoverController: PopoverController) {
    super();
  }

  public  getProfil(): void {
     this.keycloakService.userProfile$.subscribe(() => {
      this.updateProfil();
    });
  }

  private updateProfil():void {
    this.userKeycloakProfil = this.keycloakService.getUserProfile();
    this.isConnected = !!this.userKeycloakProfil;  
    this.isPro = this.keycloakService.isUserPro();
    if (this.isConnected) {
      if (this.keycloakService.hasAuthorization(ConstantesRolesEnum.adminCorpo)) {
        this.administrateursService
          .userAdministrateurControllerGetAdminByUsername(this.userKeycloakProfil.username)
          .toPromise()
          .then((userAdministrateurDto: UserAdministrateurDto) => {
            this.userAdministrateurDto = userAdministrateurDto;
          });
      }
      if (!this.keycloakService.hasAuthorization(ConstantesRolesEnum.admin)) {
        this.usersService.userControllerGetUserByUsername(encodeURI(this.userKeycloakProfil.username))
        .toPromise()
        .then((user: UserDto) => {
          this.user = user;
          let message = '';
          if (this.user.statutCompte.id !== 1) {

            if (this.user.statutCompte.id === 2) {
              message = '<a class="typo-20-bold">Votre compte est inatif.</a>';
            }

            if (this.user.statutCompte.id === 3) {
              message = '<a class="typo-20-bold">Votre compte est supprimé.</a>';
            }

            const infosProps: PopoverProps = {
              // eslint-disable-next-line max-len
              titre: message,
              couleur: 'red',
              logout: true
            };
            this.showPopoverGeneric(this.popoverController, infosProps).then();
          }
        });
      }
    }
  }

  public afficherMonProfil(): void {
    this.router.navigateByUrl('mon-profil', { state: [this.router.url] });
  }

  public async deconnexion(): Promise<void> {
    const homeUrl = this.plateformeService.isDesktop() ? '/accueil' : (this.isPro?'/professionnel':'/public');
    this.keycloakService.logout().finally(() => {
      window.location.href = homeUrl;
    });
  }

  public isIndividual():boolean{
    return !this.user?.denomination;
  }
}
