<div>
  <div class="footer">
    <div class="align-center">
      <div class="container-logo">
        <img src="assets/svg/logo_mclu_white.svg" height="87px" alt="Logo MCLU">
        <img src="assets/svg/armoiries_rci.svg" height="100px" alt="Armoiries de la Côte d'Ivoire">
      </div>
      <p class="typo-14-bold-white titre-uppercase">République de Côte d’ivoire</p>
    </div>

    <div>
      <p class="typo-16-bold-yellow">Sites du gouvernement</p>
      <ul>
        <a [href]="constantesLiens.GOUVERNEMENT" target="_blank">
          <li class="typo-14-white">Gouvernement</li>
        </a>
        <a [href]="constantesLiens.MCLU" target="_blank">
          <li class="typo-14-white">MCLU</li>
        </a>
        <a [href]="constantesLiens.ADMINISTRATION" target="_blank">
          <li class="typo-14-white">Administration Ivoirienne</li>
        </a>
        <a [href]="constantesLiens.SERVICES_PUBLICS" target="_blank">
          <li class="typo-14-white">Annuaire des Services Publics</li>
        </a>
        <a [href]="constantesLiens.PARTICIPATION_CITOYENNE" target="_blank">
          <li class="typo-14-white">Participation Citoyenne</li>
        </a>
        <a [href]="constantesLiens.OPEN_DATA" target="_blank">
          <li class="typo-14-white">Open Data Côte d'Ivoire</li>
        </a>
        <a [href]="constantesLiens.TELECOMMUNICATIONS" target="_blank">
          <li class="typo-14-white">Service Universel des Télécommunications</li>
        </a>
      </ul>
    </div>

    <div>
      <p class="typo-16-bold-yellow">Accès rapide</p>
      <ul>
        <li routerLink="/creation-compte" class="typo-14-white">Créer un compte</li>
        <!-- <li routerLink="/demarches-prive" class="typo-14-white">Suivi des demandes</li> -->
        <li routerLink="/suivi-public" class="typo-14-white">Suivre une demande</li>
        <li routerLink="/depot-ligne-type" *ngIf="isConnected" class="typo-14-white">Déposer une demande</li>
        <li routerLink="/services" class="typo-14-white">Services</li>
        <li routerLink="/parcelles" class="typo-14-white">Parcelles</li>
        <li class="typo-14-white"> <a href="{{Link}}" target="_blank">Questions fréquentes</a></li>
        <li routerLink="/questionnaire-de-satisfaction" class="typo-14-white">Questionnaire de satisfaction</li>
        <li routerLink="/reclamation" class="typo-14-white">Déposer une réclamation</li>
        <!-- <li routerLink="/mentions-legales" class="typo-14-white">Mentions légales</li> -->
      </ul>
      <ul>
        <li routerLink="/public" class="typo-14-medium-yellow">Accueil Particuliers</li>
        <li routerLink="/professionnel" class="typo-14-medium-yellow">Accueil Professionnels</li>
      </ul>
    </div>

    <div>
      <p class="typo-16-bold-yellow">Suivez-nous</p>
      <div class="ion-margin-bottom">
        <a [href]="constantesLiens.FACEBOOK" target="_blank">
          <ion-icon src="assets/svg/facebook.svg"></ion-icon>
        </a>
        <a [href]="constantesLiens.TWITTER" target="_blank">
          <ion-icon src="assets/svg/twitterFooter.svg"></ion-icon>
        </a>
        <a [href]="constantesLiens.YOUTUBE" target="_blank">
          <ion-icon src="assets/svg/youtube.svg"></ion-icon>
        </a>
      </div>

      <p class="typo-16-bold-yellow ">Contactez-nous</p>
      <a [href]="constantesLiens.EMAIL">
        <p class="typo-14-white">call.center@construction.gouv.ci</p>
      </a>
      <p class="typo-14-white">Contacts Guichet Unique du foncier : 07 68 42 50 65 / 07 79 59 92 03</p>
      <p class="typo-14-white">Call Center MCLU (numéro vert) : 1378</p>
      <!-- <a href = "mentions-legales">
        <p class="typo-14-white">Mentions légales</p>
      </a> -->
      <ul>
        <li routerLink="/mentions-legales" class="typo-14-white">Mentions légales</li>
      </ul>
      <!--div class="numVersion typo-12-white">Version {{numVersionApp}}</div-->
    </div>
  </div>
  <div class="typo-12-white ion-text-center mentions">©2024 - Ministère de la Construction, du Logement et de l’Urbanisme, tous droits réservés. Version {{numVersionApp}}</div>
</div>

