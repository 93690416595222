import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mclu-popover-erreur-mot-de-passe-oublie',
  templateUrl: './popover-erreur-mot-de-passe-oublie.component.html',
  styleUrls: ['./popover-erreur-mot-de-passe-oublie.component.scss'],
})
export class PopoverErreurMotDePasseOublieComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
