<div class="conteneur">
  <div class="formGroup">

    <input #ioninputdate id="ioninputdate" class="inputDate typo-14-bold-green"  [min]="minDate" [max]="maxDate"
          type="tel" pattern="[0-9]*"
           [ngClass]="{'invalid-input': fc?.invalid , 'isInvalid':fc?.invalid}"
           placeholder="jj/mm/aaaa"
           matInput #inputDate [matDatepicker]="picker"
           (focusout)="focusOutEvent($event)"
           (input)="inputEvent($event)" type="text"

           (click)="onFocus($event); picker.close()"
           (keyup)="onFocus($event)"
           (onfocus)="onFocus($event)"
           (focus)="picker.close();picker.disabled"
           (onchange)="updateState()"
           [formControl]="fc"
           (dateChange)="updateState()"
           >
  </div>
  <mat-datepicker (onchange)="updateState()"
  #picker touchUi></mat-datepicker>

  <ion-icon class="ion-margin-start" (click)="picker.open()"
            src="./assets/svg/calendar.svg"></ion-icon>

</div>
