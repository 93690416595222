import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import { PopoverGenericComponent } from './popover-generic.component';



@NgModule({
  declarations: [PopoverGenericComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    PopoverGenericComponent,
  ]
})
export class PopoverGenericModule { }
