import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressCircleService {

  private progressSubjet = new Subject<string>();

  public progression$ = this.progressSubjet.asObservable();

  public emitProgression(pourcentage: string): void {
    this.progressSubjet.next(pourcentage);
  }
}
