import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SecureStorage {
  private encryptionKey:string;

  constructor() {
    this.encryptionKey = environment.secureStorageKey;
  }

  setItem(key: string, value: string): void {
    const encryptedValue = this.encrypt(value);
    if (encryptedValue) {
      sessionStorage.setItem(key, encryptedValue);
    }
  }

  getItem(key: string): string | null {
    const encryptedValue = sessionStorage.getItem(key);
    return encryptedValue ? this.decrypt(encryptedValue) : null;
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  private encrypt(value: string): string | null {
    try {
      return AES.encrypt(value, this.encryptionKey).toString();
    } catch (error) {
      console.error('Encryption failed:', error);
      return null;
    }
  }

  private decrypt(value: string): string | null {
    try {
      return AES.decrypt(value, this.encryptionKey).toString(enc.Utf8);
    } catch (error) {
      console.error('Decryption failed:', error);
      return null;
    }
  }
}