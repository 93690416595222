<div class="flex-row-center">
  <img *ngIf="image && imageAlt" src="assets/svg/{{image}}.svg" alt="{{imageAlt}}">
  <p class="typo-20-bold-green">{{content | uppercase}}</p>
</div>
<div class="buttons" *ngIf="buttonLabel && lien">
  <ion-button class="typo-14-bold" color="secondary" fill="outline" href="{{lien}}">{{buttonLabel}}</ion-button>
</div>
<div class="buttons" *ngIf="buttonLabel && haveModal">
  <ion-button id="modal-trigger-astuce-photo" class="typo-14-bold" color="secondary" fill="outline">{{buttonLabel}}</ion-button>
  <ion-modal trigger="modal-trigger-astuce-photo" [breakpoints]="[0, 0.3, 0.6]" [initialBreakpoint]="0.6">
    <ng-template>
      <ion-content>
        <div class="flex-row-center">
          <ion-icon class="icone-vert" src="assets/svg/astuce.svg"></ion-icon>
          <p class="typo-16-bold">Les astuces pour la photo</p>
        </div>
        <div class="flex-row start">
          <div class="flex-column">
            <div class="flex-row-center center">
              <ion-icon class="icone-vert" src="assets/svg/check_circle.svg"></ion-icon>
              <p class="typo-16-bold">Oui</p>
            </div>
            <div class="flex-row">
              <img src="assets/svg/prise_ok_1.png" alt="Image d'un document bien cadré"/>
              <p class="typo-14">Photo droite et bien cadré</p>
            </div>
            <div class="flex-row">
              <img src="assets/svg/prise_ok_2.png" alt="Image d'un document net"/>
              <p class="typo-14">Photo nette</p>
            </div>
            <div class="flex-row">
              <img src="assets/svg/prise_ok_3.png" alt="Image d'un document propre"/>
              <p class="typo-14">Document propre</p>
            </div>
          </div>
          <div class="divider"></div>
          <div class="flex-column">
            <div class="flex-row-center center">
              <ion-icon class="icone-rouge" src="assets/svg/cancel_circle.svg"></ion-icon>
              <p class="typo-16-bold">Non</p>
            </div>
            <div class="flex-row">
              <img src="assets/svg/prise_ko_1.png" alt="Image d'un document bien cadré"/>
              <p class="typo-14">Photo de travers</p>
            </div>
            <div class="flex-row">
              <img src="assets/svg/prise_ko_2.png" alt="Image d'un document net"/>
              <p class="typo-14">Photo floue</p>
            </div>
            <div class="flex-row">
              <img src="assets/svg/prise_ko_3.png" alt="Image d'un document propre"/>
              <p class="typo-14">Photocopie de photocopie <br> Document sale ou taché</p>
            </div>
          </div>
        </div>
      </ion-content>
    </ng-template>
  </ion-modal>
</div>
