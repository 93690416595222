import { environment } from './../environments/environment';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { PwaService } from './services/service-worker/pwa.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { SpinnerService } from './services/infrastucture/spinner.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  /**
   * Constructeur
   *
   * @param pwaService
   * @param router
   * @param zone
   * @param spinnerService
   */
  constructor(private pwaService: PwaService,
    private router: Router,
    private zone: NgZone,
    private spinnerService: SpinnerService) {

    this.addGoogleAnalyticsScript();
  }

  /**
   * Hook d'init du composant.
   */
  ngOnInit() {
    this.initializeApp();
    this.pwaService.subscribeUpdate();
    SplashScreen.hide();
  }

  ngOnDestroy(): void {
    App.removeAllListeners();
  }

  /**
   * Gestion du deep links
   */
  private initializeApp() {
    App.removeAllListeners();
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        App.removeAllListeners();
        this.interceptorApp(event);
      });
    });
  }

  /**
   * Intercepte les url Universal Link
   * @param event
   */
  private interceptorApp(event: URLOpenListenerEvent) {
    const domain = environment.keycloakLoginPrefixMobile || 'ci.sigfu.mclu:';

    if (event?.url?.includes(domain)) {

      const pathArray = event.url.split(domain);
      const appPath = pathArray.pop();

      if (appPath) {
        if (appPath.includes('demarches-prive')) {
          this.spinnerService.presentLoading(2000);
          setTimeout(() => {
            this.router.navigateByUrl('/demarches-prive');
          }, 2000);
        } else {
          this.spinnerService.presentLoading();
          this.router.navigateByUrl(appPath);
        }

        setTimeout(() => {
          this.initializeApp();
        }, 5000);
      }
    }
  }

  /**
   * Inject le script de google analytics avec le trackingId selon l'environnement.
   */
  private addGoogleAnalyticsScript() {
    const script1 = document.createElement('script');
    script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaTrackingId);
    script1.setAttribute('async', '');
    script1.setAttribute('defer', '');

    const script2 = document.createElement('script');
    script2.textContent = `window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', '${environment.gaTrackingId}');`;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }

}
