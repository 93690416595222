import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActivationCompteComponent} from './activation-compte.component';
import {IonicModule} from '@ionic/angular';
import {ReactiveFormsModule} from '@angular/forms';
import { InputDateModule } from '../../date/input-date/input-date.module';
import { InputDateDesktopModule } from '../../date/input-date-desktop/input-date-desktop.module';
import { RadioActifModule } from '../../radio-actif/radio-actif.module';



@NgModule({
  declarations: [
    ActivationCompteComponent
  ],
  exports: [
    ActivationCompteComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    InputDateModule,
    InputDateDesktopModule,
    RadioActifModule
  ]
})
export class ActivationCompteModule { }
