<form class="form bc-red"  novalidate >
  <input #inputTypeDemande
    class="mclu-select typo-14-bold"
    type="text"
    matInput
    placeholder="{{placeHolder}}"
    [matAutocomplete]="auto"
    [formControl]="fcElement"
    (input)="elementChange()"
    (focusout)="elementChange()"
    [matAutocomplete]="auto"
    [readOnly]="disabledAutocomplete"
    [ngClass]="{'bc-gray': disabledAutocomplete,'  no-radius-right':noRadiusRight, 'isGreen':isGreen}"

  />

  <mat-autocomplete #auto="matAutocomplete"  (optionSelected)="elementChange()" >

    <mat-option
      *ngFor="let option of elementsObservable | async"
      [value]="option"
      >
      {{option}}
    </mat-option>

  </mat-autocomplete>
  <i *ngIf="inputTypeDemande.value!==''" class="material-icons img-delete" (click)="deleteElement()">close</i>
</form>
