import { Injectable } from '@angular/core';
import { Configuration, ConfigurationParameters } from 'openapi/build/configuration';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiDataConfigurationService {

  constructor() { }
}

/**
 * Retourne les paramêtres de configuration de l'API.
 */
 // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
 export function apiDataConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
    basePath: environment.apiDataBasePath,
    // TODO : activer les credentials pour l'authentification sur l'API.
    withCredentials: false,
  };

  return new Configuration(params);
}
