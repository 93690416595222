import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdministrateursService } from './api/administrateurs.service';
import { DefaultService } from './api/default.service';
import { DemandeUserService } from './api/demandeUser.service';
import { DemandesService } from './api/demandes.service';
import { DeposerReclamationService } from './api/deposerReclamation.service';
import { DepotLigneService } from './api/depotLigne.service';
import { FavorisService } from './api/favoris.service';
import { FonctionService } from './api/fonction.service';
import { InnolaService } from './api/innola.service';
import { PieceIdentiteService } from './api/pieceIdentite.service';
import { ProfessionnelsService } from './api/professionnels.service';
import { QuestionnaireSatisfactionService } from './api/questionnaireSatisfaction.service';
import { SmsService } from './api/sms.service';
import { TypeCorporationService } from './api/typeCorporation.service';
import { TypesDemandesService } from './api/typesDemandes.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
