import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionPopoverService {

  // Observe les actions des boutons dans les popovers
  public actionPopoverSubject = new Subject<void>();

  constructor() { }

  /**
   * Active l'action du bouton d'un popover
   *
   * @param actionPopover
   */
  public emitActionPopoverSubject() {
    this.actionPopoverSubject.next();
  }
}
