export * from './administrateurs.service';
import { AdministrateursService } from './administrateurs.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './demandeUser.service';
import { DemandeUserService } from './demandeUser.service';
export * from './demandes.service';
import { DemandesService } from './demandes.service';
export * from './deposerReclamation.service';
import { DeposerReclamationService } from './deposerReclamation.service';
export * from './depotLigne.service';
import { DepotLigneService } from './depotLigne.service';
export * from './favoris.service';
import { FavorisService } from './favoris.service';
export * from './fonction.service';
import { FonctionService } from './fonction.service';
export * from './innola.service';
import { InnolaService } from './innola.service';
export * from './pieceIdentite.service';
import { PieceIdentiteService } from './pieceIdentite.service';
export * from './professionnels.service';
import { ProfessionnelsService } from './professionnels.service';
export * from './questionnaireSatisfaction.service';
import { QuestionnaireSatisfactionService } from './questionnaireSatisfaction.service';
export * from './sms.service';
import { SmsService } from './sms.service';
export * from './typeCorporation.service';
import { TypeCorporationService } from './typeCorporation.service';
export * from './typesDemandes.service';
import { TypesDemandesService } from './typesDemandes.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [AdministrateursService, DefaultService, DemandeUserService, DemandesService, DeposerReclamationService, DepotLigneService, FavorisService, FonctionService, InnolaService, PieceIdentiteService, ProfessionnelsService, QuestionnaireSatisfactionService, SmsService, TypeCorporationService, TypesDemandesService, UsersService];
