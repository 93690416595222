import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  /**
   * Constructeur.
   *
   * @param swUpdate
   * @param toastController
   */
  constructor(
    private swUpdate: SwUpdate,
    private toastController: ToastController
  ) { }

  /**
   * Vérifie si une nouvelle version de la pwa est disponible.
   */
  public subscribeUpdate() {
    this.swUpdate.versionUpdates.subscribe(evt => {
      console.log('versionUpdates : ', evt);
      this.showUpdateToast();
    });
  }

  private async showUpdateToast() {
    const toast = await this.toastController.create({
      message: 'Nouvelle version disponible. L\'application va se recharger.',
      position: 'middle',
      color: 'warning',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });
    toast.present();
  }
}
