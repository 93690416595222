import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import Compressor from 'compressorjs';

export interface UserPhoto {
  filepath: string;
  webviewPath: string;
}


@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor() {
  }

  public async prendreUneNouvellePhoto(photos: File[]): Promise<File[]> {
    // Prend une photo
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 90
    });

    const imageFile = await this.compressImageToFile(capturedPhoto);
    photos.unshift(imageFile);
    return photos;
  }

  public async modifierPhoto(photos: File[], indexPhoto: number): Promise<File[]> {
    photos.splice(indexPhoto, 1);
    return await this.prendreUneNouvellePhoto(photos);
  }

  private async compressImageToFile(photo: Photo): Promise<File> {
    const response = await fetch(photo.webPath!);
    const blob = await response.blob();
    return this.compressBlobToFile(blob);
  }

  public compressBlobToFile(blob: Blob): Promise<File> {
    let filename;
    if (blob instanceof File) {
      filename = blob.name;
    } else {
      filename = `${new Date().getTime()}.jpeg`;
    }
    return new Promise((resolve, reject) => {
      new Compressor(blob, {
        quality: 0.6,
        maxHeight: 1024,
        maxWidth: 1024,
        success(result) {
          const file = new File([result], filename, {
            type: result.type,
            lastModified: Date.now()
          });
          resolve(file);
        },
        error(err) {
          console.error(err.message);
          reject(err);
        },
      });
    });
  }
}
