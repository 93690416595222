import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PopoverErreurCreationCompteComponent } from './popover-erreur-creation-compte.component';



@NgModule({
  declarations: [PopoverErreurCreationCompteComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    PopoverErreurCreationCompteComponent,
  ]
})
export class PopoverErreurCreationCompteModule { }
