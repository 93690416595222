import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule, } from '@angular/material-moment-adapter';
import { InputDateMobileComponent } from './input-date-mobile.component';


@NgModule({
  declarations: [
    InputDateMobileComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatMomentDateModule,
  ],
  exports: [
    InputDateMobileComponent,
  ]
})
export class InputDateMobileModule { }

