/**
 * SIGFU REST API
 * Serveur d\'api en NodeJs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InnolaSearchResponseSpaunitRecordsDto { 
    city: string;
    commune: string;
    legalArea: number;
    county: string;
    transactionNo: string;
    suid: string;
    type: string;
    surveyArea: number;
    surveyAreaUnit: string;
    gisAreaUnit: string;
    subPrefecture: string;
    lot: number;
    uid: string;
    legalAreaUnit: string;
    block: string;
    id: string;
    department: string;
    village: string;
    suspension: string;
    lotissementType: string;
    village2: string;
    locality: string;
    allotment: string;
    version: number;
    transactionId: string;
    idufci: string;
    district: string;
    geometry: string;
    gisArea: number;
    region: string;
    statusPlan: string;
    status: string;
}

