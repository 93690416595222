import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[mcluNom]',
})
export class NomDirective {
  constructor() {}

  @HostListener('ionBlur', ['$event']) onIonBlur(event) {
    event.target.value = event.target.value.trim();
  }

  @HostListener('ionChange', ['$event']) onIonChange(event) {
    if (event.target.value.toString().length === 0) {
      return;
    }

    //Pas de caratere "-" ou " " en premier
    if (
      event.target.value[0].toUpperCase() === ' ' ||
      event.target.value[0].toUpperCase() === '-'
    ) {
      event.target.value = event.target.value.substring(1);
    }

    //Majuscule
    event.target.value = event.target.value.toUpperCase();
    //Suppression des caracteres non authorisés
    //event.target.value = event.target.value.replace(/[^- A-Z]*/g, '');

    //Ne garder que le premier tiret
    if (event.target.value.indexOf('-') > 0) {
      event.target.value =
        event.target.value.substring(0, event.target.value.indexOf('-') + 1) +
        event.target.value
          .substring(event.target.value.indexOf('-') + 1)
          .replace('-', '');
      event.target.value = event.target.value.replace(' ', '');
    }
    //Ou le premier espace (2 max) si pas de tiret
    if (event.target.value.indexOf(' ') > 0) {
      let nbEspace = event.target.value.split(' ').length - 1;
      if (nbEspace > 1) {
        nbEspace = 2;
      }

      event.target.value =
        event.target.value.substring(
          0,
          event.target.value.split(' ', nbEspace).join(' ').length + 1
        ) +
        event.target.value
          .substring(event.target.value.split(' ', nbEspace).join(' ').length + 1)
          .replace(' ', '');
    }


  }
}
