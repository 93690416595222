import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonInput, PopoverController } from '@ionic/angular';
import { PlateformeService } from 'src/app/services/plateforme/plateforme.service';
import { PopoverProps } from '../../../../shared/interfaces/popover-props';
import { AbstractPopover } from '../../popover/abstract-popover/abstract-popover';

@Component({
  selector: 'mclu-input-date-desktop',
  templateUrl: './input-date-desktop.component.html',
  styleUrls: ['./input-date-desktop.component.scss'],
})
export class InputDateDesktopComponent extends AbstractPopover implements OnInit {
  @ViewChild(IonInput) inputdate?: IonInput;

  @Input()
  public fc: FormControl;

  @Input()
  public colorWhite: boolean;

  @Input()
  public noLimit = false;

  @Input()
  public minToday = false;

  /** age minimum en année */
  @Input()
  public ageMin: number = -1;

  @Output()
  public formControlChange: EventEmitter<FormControl> = new EventEmitter<FormControl>();
  //public onFocusOutEvent: EventEmitter<FormControl> = new EventEmitter<FormControl>();


  public maxDate: any = new Date().toISOString().split('T')[0];
  public minDate: any = new Date(1900, 0, 2).toISOString().split('T')[0];

  public maxDateControl = new Date().toISOString();
  public minDateControl = new Date(1900, 0, 2).toISOString();

  private alert;

  constructor(
    public plateforme: PlateformeService,
    public popoverController: PopoverController,
    ) {
    super();
  }

  /**
   * Initialisation du composant.
   */
  ngOnInit() {
    if (this.noLimit === true) {
      this.maxDateControl = new Date(2100, 0, 2).toISOString();
      this.maxDate = new Date(2100, 0, 2).toISOString().split('T')[0];
    }

    if (this.minToday) {
      //const aDate = new Date();
      //aDate.setDate(aDate.getDate() - 1);
      this.minDateControl = new Date().toISOString();
      this.minDate = new Date().toISOString().split('T')[0];
    }
  }

  /**
   * Retourne le formcontrol via le parametre de sortie formControlChange.
   */
  public updateState(): void {
    if (
      this.inputdate.value > this.maxDateControl ||
      this.inputdate.value < this.minDateControl
    ) {
      this.fc.setErrors({ backend: { someProp: 'Invalid Data' } });
    } else {
      if (this.ageMin > -1) {
        let dateNaissance = new Date(this.fc.value);
        if(!this.ageMinValid(dateNaissance)) {
          this.fc.setErrors({ backend: { someProp: 'Invalid Data' } });
          const infosProps: PopoverProps = {
            titre: '<a class="typo-20-bold">L\'utilisateur doit avoir au moins 18 ans</a>',
            couleur: 'red',
          };
          if(this.alert == undefined || (this.alert.className && this.alert.className.includes('hidden'))) {
            this.alert = 'block double apparition du pop-up lors de l\'erreur';
            this.showPopoverGenericWithRes(this.popoverController, infosProps).then(pop=>{
              this.alert = pop;
            });
          }
        }
      } else {
        this.fc.setErrors(null);
      }
    }
    this.formControlChange.emit(this.fc);
  }

  public onFocusOut(): void {
    this.updateState();
  }

  public ageMinValid(dateNaissance: Date) {
    let dateDuJour = new Date();
    let jj = dateDuJour.getDate();
    let jm = dateDuJour.getMonth();
    let ja = dateDuJour.getFullYear();
    let nj = dateNaissance.getDate();
    let nm = dateNaissance.getMonth();
    let na = dateNaissance.getFullYear();

    let annee = ja - na;
    if (annee < this.ageMin) {
      return false;
    } else if (annee == this.ageMin && jm < nm) {
      return false;
    } else if (annee == this.ageMin && nm == jm && jj < nj) {
      return false;
    }
    return true;
  }

}
