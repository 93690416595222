<ng-container *ngIf="plateforme.isDesktop(); else templateMobile">
  <!-- ######################  TEMPLATE DESKTOP  ####################### -->
  <div>
    <div   class="search-bar"  [ngClass]="{'jaune':!isConnected,'orange':isPro,'vert':isConnected}">
      <div class="reseaux-sociaux">
        <a [href]="constantes.FACEBOOK" target="_blank">
          <ion-icon src="assets/svg/facebook.svg"></ion-icon>
        </a>
        <a [href]="constantes.TWITTER" target="_blank">
          <ion-icon src="assets/svg/twitterHeader.svg"></ion-icon>
        </a>
        <a [href]="constantes.YOUTUBE" target="_blank">
          <ion-icon src="assets/svg/youtube.svg"></ion-icon>
        </a>
      </div>

      <!-- NE PAS SUPPRIMER LE CHAMP DE RECHERCHE !!-->
      <!--  <ion-item lines="none">-->
      <!--    <ion-input type="text"></ion-input>-->
      <!--    <ion-icon slot="end" src="assets/svg/search.svg"></ion-icon>-->
      <!--  </ion-item>-->
    </div>

    <div class="header">
      <img class="logo-mclu" src="assets/svg/logo_mclu.svg" alt="Logo MCLU">
      <a href="/accueil">
        <img class="logo-sigfu" src="assets/svg/logo_sigfu.svg" alt="Logo SIGFU">
      </a>
      <div class="titre">
        <h2 class="typo-16-bold-green">République de Côte d’ivoire</h2>
        <h1 class="typo-36-montserrat">Système intégré <br/>de gestion du foncier urbain</h1>
      </div>
      <img class="logo-armoiries" src="assets/svg/armoiries_rci.svg" alt="Armoiries de la Côte d'Ivoire">

      <ng-container *ngIf="!isPro">
        <ion-button *ngIf="currentPage!== 'accueil' && currentPage!== 'accueil-particulier'" color="#000000"
                    class="typo-16-bold-white btn-header btn-accueil"
                    [ngClass]="{'vert':!isConnected,'jaune':isConnected}"
                    routerLink="/public">Accueil
        </ion-button>
        <ion-button *ngIf="!isConnected && currentPage!== 'accueil' && currentPage!== 'accueil-particulier' && currentPage!== 'login'" color="#000000"
                    class="typo-16-bold-white btn-header vert btn-connexion"
                    (click)="connection()">Connexion
        </ion-button>

        <div *ngIf="isConnected" class="flex-row pointer btn-connexion btn-deconnexion cursor-pointer"
            (click)="deconnexion()">
          <a class="typo-14-bold-white">Je me déconnecte</a>
          <ion-icon src="assets/svg/deconnecte.svg" class="ico-deconnexion"></ion-icon>
        </div>
      </ng-container>

    </div>

    <div class="buttons-bottom" *ngIf="isPro">

      <ion-button color="#000000" *ngIf="currentPage!== 'accueil-pro'"
                  class="typo-16-bold-white btn-header vert btn-accueil"
                  routerLink="/professionnel">Accueil pro
      </ion-button>
      <ion-button color="#000000" *ngIf="!isConnected && currentPage!== 'login'"
                  class="typo-16-bold-white btn-header orange btn-connexion"
                  (click)="connection()">Connexion
      </ion-button>

      <ion-button color="#000000" *ngIf="isConnected"
        class="typo-16-bold-white btn-header vert btn-connexion"
        (click)="deconnexion()">Déconnexion
      </ion-button>


    </div>

    <div class="header-connect" *ngIf="isConnected" [ngClass]="{'orange':isPro}">
      <div class="conteneur-left">

          <div class="conteneur-bienvenue">
            <p class="typo-36-medium-yellow bienvenue" [ngClass]="{'typo-36-medium-white':isPro}">Bienvenue</p>
            <p class="typo-36-medium-white">{{this.user?.genre?.label === 'homme' ? 'M.' : 'Mme'}} {{userKeycloakProfil?.name}}</p>
          </div>
          <p class="typo-12-white espace">Vous êtes sur votre espace personnel et sécurisé</p>

      </div>

      <div  *ngIf="!isPageProfil" class="conteneur-right jaune" [ngClass]="{'orange':isPro,'bc-blanc':isPro}">

        <div  class=" pointer mon-compte" (click)="afficherMonProfil()">
          <ion-icon class='mon-compte-icon' src="assets/svg/profil.svg"></ion-icon>
          <div class="mon-compte-content-label">
            <div  class="typo-11-bold-white mon-compte-label">Profil</div>
          </div>
        </div>

      </div>
    </div>

  </div>
</ng-container>


<!-- ######################  TEMPLATE MOBILE  ####################### -->
<ng-template #templateMobile>
  <div class="templateMobile">
    <div class="logos">
      <img class="logo" src="assets/svg/logo_mclu.svg" alt="Logo MCLU">
      <img class="logo" src="assets/svg/logo_sigfu.svg" alt="Logo SIGFU">
      <img class="logo" src="assets/svg/armoiries_rci.svg" alt="Armoiries de la Côte d'Ivoire">
    </div>

    <h1 class="typo-19-semibold-green pt-19">Système intégré de<br>gestion du foncier urbain</h1>

  </div>
</ng-template>
