import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RadioActifComponent } from './radio-actif.component';



@NgModule({
  declarations: [
    RadioActifComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [
    RadioActifComponent,
  ]
})
export class RadioActifModule { }
