import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UserDto } from '../../../../../../openapi/build';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'mclu-popover-details-compte-particulier',
  templateUrl: './popover-details-compte-particulier.component.html',
  styleUrls: ['./popover-details-compte-particulier.component.scss'],
})
export class PopoverDetailsCompteParticulierComponent implements OnInit {
  @Input()
  user: UserDto;

  public fileRecto = '';
  public fileVerso = '';

  public imageVisible = false;

  private apiBasePath = environment.apiBasePath;
  private apiFileBasePath = environment.apiFileBasePath;

  constructor(public popoverController: PopoverController, private router: Router) { }

  ngOnInit() {
    this.fileRecto =
      this.apiBasePath +
      this.apiFileBasePath +
      '/piece-identite/' +
      encodeURI(this.user?.pieceIdentite?.justificatifRecto);
    this.fileVerso =
      this.apiBasePath +
      this.apiFileBasePath +
      '/piece-identite/' +
      encodeURI(this.user?.pieceIdentite?.justificatifVerso);
  }

  public closePopup() {
    this.popoverController.dismiss().then();
  }

  public goToLink(document: string) {
    window.open(document, '_blank');
  }

  public modifierCompte() {
    this.closePopup();
    if (this.user.userAdministrateur) {
      this.router.navigateByUrl('administrateur/modification-compte-corporation', {
        state: [this.user.username],
      });
    } else if (this.user.userProfessionnel) {
      this.router.navigateByUrl('administration/modification-compte', {
        state: [this.user.username],
      });
    }
  }

  modifierMotDePasse() {
    this.closePopup();
    this.router.navigateByUrl('administration/modification-mot-de-passe', {
      state: [this.user.username],
    });
  }

}
