import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { ActivationDto, UserDto, UsersService } from 'openapi/build';
import { PopoverProps } from 'src/app/shared/interfaces/popover-props';
import { AbstractPopover } from '../../popover/abstract-popover/abstract-popover';

@Component({
  selector: 'mclu-activation-compte',
  templateUrl: './activation-compte.component.html',
  styleUrls: ['./activation-compte.component.scss'],
})
export class ActivationCompteComponent extends AbstractPopover implements OnInit {
  @Input()
  public typeAdmin: 'professionel' | 'particulier' | 'admin-corporation' = 'professionel';

  @Input()
  public isPopup = true;

  @Input()
  public user: UserDto;

  public maxDateControl = new Date(2100, 0, 2).toISOString();
  public minDateControl = new Date().toISOString();
  public url;

  public activationForm: FormGroup;

  public dateActivationProgramme = new FormControl();

  public activationPlanned = new FormControl();

  public duree = new FormControl();
  public dateFinActivation = new FormControl();
  public activation = new FormControl({ value: true });

  public radioName: string;

  constructor(public popoverController: PopoverController,
              public router: Router,
              public usersService: UsersService) {
    super();
  }

  ngOnInit() {
    this.createActivationCompteForm();
    this.duree.setValue(true);
    this.activationPlanned.setValue(false);
    this.userUpdate();
  }

  public userUpdate() {
    if (!this.isPopup) {
      if (this.user) {
        if (this.user.statutCompte?.id === 1) {
          this.activation.setValue(true);
        } else {
          this.activation.setValue(false);
        }

        if (this.user.dateDebutActivation && this.user.statutCompte?.id === 2) {
          this.activationPlanned.setValue(true);

          this.dateActivationProgramme.setValue(
            formatDate(this.user.dateDebutActivation, 'yyyy-MM-dd', 'en-US')
          );
        }
        if (this.user.dateFinActivation && this.user.statutCompte?.id === 1) {
          this.duree.setValue(false);
          this.dateFinActivation.setValue(
            formatDate(this.user.dateFinActivation, 'yyyy-MM-dd', 'en-US')
          );
        } else {
          this.duree.setValue(true);
        }
      }
    }
  }

  public isFormValid(): boolean {
    return !this.activationForm.valid;
  }

  public setValidators() {

    this.dateFinActivation.removeValidators(Validators.required);
    this.dateActivationProgramme.removeValidators(Validators.required);

    if (this.activationForm.value.activation === false) {
      if (this.activationPlanned.value === true) {
        this.dateActivationProgramme.addValidators(Validators.required);
      }
    } else {
      if (this.duree.value === 'false') {
        this.dateFinActivation.addValidators(Validators.required);
      }
    }

    this.dateActivationProgramme.updateValueAndValidity();
    this.dateFinActivation.updateValueAndValidity();

    if (this.activationForm.value.activation === false) {
      if (this.activationPlanned.value === true) {
        this.controlLimitDate(this.dateActivationProgramme);
      }
    } else {
      if (this.duree.value === 'false') {

        this.controlLimitDate(this.dateFinActivation);
      }
    }
  }

  public controlLimitDate(fc: FormControl): void {
    if (fc.value > this.maxDateControl || fc.value < this.minDateControl || fc.value === null) {
      fc.setErrors({ backend: { someProp: 'Invalid Data' } });
    } else {
      fc.setErrors(null);
    }
  }

  public createActivationCompteForm(): void {
    this.activationForm = new FormGroup({
      activation: this.activation,
      activedDate: this.dateActivationProgramme,
      activationPlanned: this.activationPlanned,
      duree: this.duree,
      dateFinActivation: this.dateFinActivation,
    });
  }

  public submitFormActivation(): void {
    let debutActivation;
    let derniereActivation;
    let dateFinActivationVal;
    if (this.activation.value) {
      debutActivation = new Date();
      derniereActivation = new Date();
      if (this.duree.value === false || this.duree.value === 'false') {
        dateFinActivationVal = this.dateFinActivation.value;
      }
    } else if (this.activationPlanned.value) {
      debutActivation = this.dateActivationProgramme.value;
      derniereActivation = this.dateActivationProgramme.value;
      dateFinActivationVal = new Date();
    } else {
      dateFinActivationVal = new Date();
    }

    // Si popup lors de la création d'un compte, sinon page d'activation/désactivation de compte
    if (this.isPopup) {
      const data = {
        statutCompte: this.activationForm.value.activation ? 1 : 2,
        dateDebutActivation: debutActivation,
        dateDerniereReactivation: derniereActivation,
        dateFinActivation: dateFinActivationVal,
        duration: this.activationForm.value.duration,
      };
      this.popoverController.dismiss(data).then();
    } else {
      // Si administrateur de corporation, idCorporation est renseigné pour vérifier le nombre d'admin actif.
      const activation: ActivationDto = {
        dateDebutActivation: this.setNullDate(debutActivation),
        dateFinActivation: this.setNullDate(dateFinActivationVal),
        statutCompte: this.activationForm.value.activation ? 1 : 2,
        username: this.user.username,
        dateDerniereReactivation: this.setNullDate(derniereActivation),
        idCorporation: this.user.userAdministrateur ? this.user.userAdministrateur.typeCorporation.id : null
      };

      let message =
        '<a class="typo-20-bold">Le compte de </a><a class="typo-20-bold-green">' +
        (this.user?.genre?.label === 'homme' ? 'Mr' : 'Mme') +
        ' ' +
        this.user?.firstName +
        ' ' +
        this.user?.lastName;
      if (activation.statutCompte === 1) {
        message += '</a><br>' + '<a class="typo-20-bold">est activé</a>';
        if (activation.dateDebutActivation) {
          //message+='<br>' + 'à partir du ' + formatDate(activation.dateDebutActivation,'dd/MM/yyyy','en-US');
        }
      } else {
        message += '<br><a class="typo-20-bold">' + 'est désactivé</a>';
      }

      if (debutActivation && activation.statutCompte === 2) {
        message +=
          '.<br>' +
          '<a class="typo-20-bold">Il sera réactivé à partir du ' +
          formatDate(debutActivation, 'dd/MM/yyyy', 'en-US') + '</a>';
      }

      if (activation.dateFinActivation && activation.statutCompte === 1) {
        message +=
          '<br>' +
          '<a class="typo-20-bold">jusqu\'au ' +
          formatDate(activation.dateFinActivation, 'dd/MM/yyyy', 'en-US') + '</a>';
      }

      // if (activation.dateFinActivation && activation.statutCompte===2 && activation.dateFinActivation)  {
      //   message +=
      //     '<br>' +
      //     'jusqu\'au ' +
      //     formatDate(activation.dateFinActivation, 'dd/MM/yyyy', 'en-US');
      // }

      message += '.';

      const infosProps: PopoverProps = {
        titre: message,
        couleur: this.typeAdmin === 'admin-corporation' ? 'blue'
          : this.typeAdmin === 'particulier' ? 'yellow' : 'green'
      };
      this.usersService.userControllerUpdateActivation(activation)
        .toPromise()
        .then(() => {
          this.showPopoverGeneric(this.popoverController, infosProps).then();

          this.return();
        })
        .catch();
    }
  }

  public return(): void {
    if (this.isPopup) {
      this.popoverController.dismiss().then();
    } else {
      if (this.typeAdmin === 'admin-corporation') {
        this.url = this.user.userAdministrateur.typeCorporation.id;
        this.router.navigateByUrl('/administrateur/liste-utilisateur-corporation/'+this.url);
      } else {
        this.router.navigateByUrl('/administration/liste-utilisateur-pro');
      }
    }
  }

  public setNullDate(dt: any): any {
    if (dt === undefined) {
      dt = null;
    }
    if (dt === '') {
      dt = null;
    }

    return dt;
  }
}
