<div class="content">
  <div (click)="closePopup()" class="btn-close">
    <ion-icon slot="end" src="./assets/svg/close-popup.svg"></ion-icon>
  </div>
  <div class="flex-row-center">
    <ion-icon src="assets/svg/details_compte.svg"></ion-icon>
    <h1 class="typo-34-medium-green">Détails de votre compte</h1>
  </div>

  <div class="bg-green">
    <h2 class="ion-text-center typo-24-medium-white">Votre identifiant est : <b>{{user?.username}}</b></h2>
  </div>
  <div class="content-infos">
    <h3 class="ion-text-center typo-18-grey border-bottom">Compte : <b>{{user?.statutCompte?.label}}</b></h3>
    <div class="border-bottom">
      <h3 class="ion-text-center typo-18-grey-bold">Identité de votre compte</h3>
      <div class="flex-row-space-evenly typo-14-grey">
        <ul>
          <li> {{user?.genre?.label=== 'homme' ? 'Monsieur' : 'Madame'}} </li>
          <li>Votre nom de famille : <b>{{user?.lastName}} </b></li>
          <li>Votre prénom : <b>{{user?.firstName}}</b></li>
        </ul>
        <ul>
          <li>Votre date de naissance : <b>{{user?.dateNaissance | date :'MM/dd/yyyy'}}</b></li>
          <li>Votre nationalité : <b>{{user?.nationalite?.label}}</b></li>
          <li>Carte d'identité : <b>{{user?.pieceIdentite?.numeroPiece}}</b></li>
        </ul>
      </div>
      <div class="button-close">
        <ion-button *ngIf="user.userAdministrateur || user.userProfessionnel" (click)="modifierMotDePasse()">MOT DE PASSE</ion-button>
      </div>
    </div>
    <div class="border-bottom">
      <h3 class="ion-text-center typo-18-grey-bold">Contact du compte</h3>
      <div class="flex-row-space-evenly typo-14-grey">
        <ul>
          <li>N° de téléphone : <b>{{user?.phone}}</b></li>
          <li>Votre e-mail de contact : <b>{{user?.email}}</b></li>
        </ul>
        <ul>
          <li>Votre adresse : <b>{{user?.adresse}}</b></li>
        </ul>
      </div>
    </div>

    <div *ngIf="user.userProfessionnel" class="border-bottom">
      <h3 class="ion-text-center typo-18-grey-bold">Votre agrément</h3>
      <div class="flex-row-space-evenly typo-14-grey">
        <ul>
          <li>Numéro/ID de l'arrêté portant agrément <br>OU de la prestation de serment :
            <b>{{user?.userProfessionnel?.typeDocument?.noDocument}}</b></li>
        </ul>
        <ul>
          <li>Date de signature de l'arrêté portant agrément <br>OU date de prestation de serment :
            <b>{{user?.userProfessionnel.typeDocument?.dateSignature | date :'MM/dd/yyyy'}}</b></li>
        </ul>
      </div>
    </div>
    <div *ngIf="user.userAdministrateur" class="border-bottom">
      <h3 class="ion-text-center typo-18-grey-bold">Votre agrément</h3>
      <div class="flex-row-space-evenly typo-14-grey">
        <ul>
          <li>Numéro/ID de l'arrêté portant agrément <br>OU de la prestation de serment :
            <b>{{user?.userAdministrateur?.typeDocument?.noDocument}}</b></li>
        </ul>
        <ul>
          <li>Date de signature de l'arrêté portant agrément <br>OU date de prestation de serment :
            <b>{{user?.userAdministrateur.typeDocument?.dateSignature | date :'MM/dd/yyyy'}}</b></li>
        </ul>
      </div>
    </div>

    <div *ngIf="user.userProfessionnel" class="border-bottom">
      <h3 class="ion-text-center typo-18-grey-bold">Votre société</h3>
      <div class="flex-row-space-evenly typo-14-grey">
        <ul>
          <li>Nom : <b>{{user?.userProfessionnel?.societe?.name}}</b></li>
          <li>N° de téléphone : <b>{{user?.userProfessionnel?.societe?.phone}}</b></li>
        </ul>
        <ul>
          <li>Identifiant/numéro d'enregistrement :<b>{{user?.userProfessionnel?.societe?.noSociete}}</b></li>
          <li>Email :<b>{{user?.userProfessionnel?.societe?.email}}</b></li>
        </ul>
      </div>
      <div class="flex-row-center typo-14-grey">
        <ul>
          <li>Adresse : <b>{{user?.userProfessionnel?.societe?.adresse}}</b></li>
        </ul>
      </div>
    </div>

    <div *ngIf="user.userAdministrateur" class="border-bottom">
      <h3 class="ion-text-center typo-18-grey-bold">Votre société</h3>
      <div class="flex-row-space-evenly typo-14-grey">
        <ul>
          <li *ngIf="!user?.userAdministrateur?.autreFonction">Votre fonction :<b>{{user?.userAdministrateur?.fonction.label}}</b></li>
          <li *ngIf="user?.userAdministrateur?.autreFonction">Votre fonction :<b>{{user?.userAdministrateur?.autreFonction}}</b></li>
          <li>Identifiant / n° d’enregistrement de votre société :
            <b>{{user?.userAdministrateur?.societe?.noSociete}}</b></li>
        </ul>
        <ul>
          <li>Numéro/ID de l'arrêté portant agrément <br>OU de la prestation de serment :
            <b>{{user?.userAdministrateur?.typeDocument?.noDocument}}</b></li>
          <li>Date de signature de l'arrêté portant agrément <br>OU date de prestation de serment :
            <b>{{user?.userAdministrateur.typeDocument?.dateSignature | date :'MM/dd/yyyy'}}</b></li>
        </ul>
      </div>
    </div>

    <div>
      <div class="flex-row-center">
        <a class="typo-18-grey-bold">Votre document d'identité</a>
        <ion-icon class="icon-view" [src]="imageVisible ? './assets/svg/oeil_barre.svg' : './assets/svg/view.svg'"
                  (click)="imageVisible=!imageVisible"></ion-icon>
      </div>

      <div *ngIf="user.pieceIdentite.typeJustificatif === 'id_card' && imageVisible"
           class="flex-row-space-evenly typo-14-grey">
        <ul>
          <li>Face avant / Recto de votre carte :</li>
          <img class="image" [src]="fileRecto" (click)="goToLink(fileRecto)" alt="Photo transmise par l'utilisateur">
        </ul>

        <ul>
          <li>Face arrière / Verso de votre carte :</li>
          <img class="image" [src]="fileVerso" (click)="goToLink(fileVerso)" alt="Photo transmise par l'utilisateur">
        </ul>
      </div>
      <div *ngIf="user.pieceIdentite.typeJustificatif === 'passeport' && imageVisible"
           class="flex-row-space-evenly typo-14-grey">
        <ul>
          <li>Page de votre passeport avec photo :</li>
          <img class="image" [src]="fileRecto" (click)="goToLink(fileRecto)" alt="Photo transmise par l'utilisateur">
        </ul>
      </div>
    </div>
    <div class="button-close">
      <ion-button (click)="closePopup()">FERMER</ion-button>
      <ion-button *ngIf="user.userAdministrateur || user.userProfessionnel" (click)="modifierCompte()">MODIFIER</ion-button>
    </div>
  </div>
</div>
