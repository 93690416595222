import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  /**
   * Constructeur.
   *
   * @param swUpdate
   * @param toastController
   */
  constructor(
    private swUpdate: SwUpdate,
    private toastController: ToastController
  ) { }

  /**
   * Vérifie si une nouvelle version de la pwa est disponible.
   */
  // public subscribeUpdate() {
  //   this.swUpdate.versionUpdates.subscribe(evt => {
  //     console.log('versionUpdates : ', evt);
  //     let eventTemp: any = evt;
  //     if(!eventTemp.error)
  //       this.showUpdateToast();
  //   });
  // }

  public subscribeUpdate() {
    this.swUpdate.versionUpdates.subscribe(async evt => {
      console.log('versionUpdates : ', evt);
      let eventTemp: any = evt;
      if (eventTemp.error) {
        console.error('Erreur de version : ', eventTemp.error);
        await this.clearCacheAndReload();
      } else {
        this.showUpdateToast();
      }
    });
  }

  private async showUpdateToast() {
    const toast = await this.toastController.create({
      message: 'Nouvelle version disponible. L\'application va se recharger.',
      position: 'middle',
      color: 'warning',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });
    toast.present();
  }

  private async clearCacheAndReload() {
    if ('caches' in window) {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
      window.location.reload();
    }
  }
}
