/**
 * SIGFU REST DATA API
 * Serveur d\'api en NodeJs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DepotLigneAddressDto { 
    idAddress: number;
    addressLine1?: string;
    addressLine2?: string;
    adminUnit?: string;
    cadDistrict?: string;
    city?: string;
    commune?: string;
    country?: string;
    county?: string;
    department?: string;
    description?: string;
    district?: string;
    fullAddress?: string;
    id?: string;
    locality?: string;
    poBox?: string;
    postalCode?: string;
    subPrefecture?: string;
    type?: string;
    usualResidence?: string;
    versionRev?: number;
    village?: string;
    village2?: string;
}

