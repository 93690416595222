import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { KeycloakService, initializeKeycloak } from './services/keycloak/keycloak.service';
import { apiConfigFactory } from './services/api/api-configuration.service';
import { apiDataConfigFactory } from './services/api/api-data-configuration.service';
import { TokenizedInterceptorService } from './services/api/tokenized-interceptor.service';
import {
  PopoverCreationCompteModule
} from './shared/components/popover/popover-creation-compte-content/popover-creation-compte.module';
import {
  PopoverModificationMotDePasseModule
} from './shared/components/popover/popover-modification-mot-de-passe/popover-modification-mot-de-passe.module';
import {
  PopoverErreurCreationCompteModule
} from './shared/components/popover/popover-erreur-creation-compte/popover-erreur-creation-compte.module';
import {
  PopoverErreurMotDePasseOublieModule
} from './shared/components/popover/popover-erreur-mot-de-passe-oublie/popover-erreur-mot-de-passe-oublie.module';
import {
  PopoverErreurConnexionModule
} from './shared/components/popover/popover-erreur-connexion/popover-erreur-connexion.module';
import {
  PopoverCreationCompteProModule
} from './shared/components/popover/popover-creation-compte-pro/popover-creation-compte-pro.module';
import {
  PopoverInfosCreationCompteModule
} from './shared/components/popover/popover-infos-creation-compte/popover-infos-creation-compte.module';
import { PopoverGenericModule } from './shared/components/popover/popover-generic/popover-generic.module';
import { ApiModule } from 'openapi/build';
import { ApiModule as DataApiModule } from 'openapi/data-api/build';
import { PopoverDetailsCompteParticulierModule
} from './shared/components/popover/popover-details-compte-particulier/popover-details-compte-particulier.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DepotLigneGestionnaireFavorisModule} from '../app/shared/components/demandes/depot-ligne/depot-ligne-gestionnaire-favoris/depot-ligne-gestionnaire-favoris.module'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ApiModule.forRoot(apiConfigFactory),
    DataApiModule.forRoot(apiDataConfigFactory),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

    BrowserAnimationsModule,
    PopoverCreationCompteModule,
    PopoverModificationMotDePasseModule,
    PopoverErreurCreationCompteModule,
    PopoverErreurMotDePasseOublieModule,
    PopoverErreurConnexionModule,
    PopoverCreationCompteProModule,
    PopoverInfosCreationCompteModule,
    PopoverGenericModule,
    PopoverDetailsCompteParticulierModule,
    MatAutocompleteModule,
    DepotLigneGestionnaireFavorisModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenizedInterceptorService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [KeycloakService],
      multi: true
    },
    KeycloakService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
