<div class="header">
  <div class="losange"></div>
  <div class="titre">
    <h2 class="typo-14-bold-green">
      Système intégré de<br />gestion du foncier urbain
    </h2>
  </div>

  <div class="connexion vert" *ngIf="!isConnected" (click)="connection()">
    <ion-icon class="image-connexion" src="assets/svg/profil.svg"></ion-icon>
    <div >
      <div class="typo-10-medium-white">Profil</div>
    </div>
  </div>

  <div class="connexion" *ngIf="isConnected" (click)="deconnexion()">
    <ion-icon
      class="image-deconnexion"
      src="assets/svg/disconnect.svg"
    ></ion-icon>
  </div>
</div>
<div class="bienvenue" *ngIf="isConnected">
  <div class="connexionb jaune" (click)="afficherMonProfil()">
    <ion-icon class="image-connexion" src="assets/svg/profil.svg"></ion-icon>
    <div>
      <div class="typo-10-medium-white">Profil</div>
    </div>
  </div>

  <div>
    <div class="conteneur-bienvenue">
      <p class="typo-14-medium-yellow texte-bienvenue">Bienvenue</p>
      <p class="typo-14-medium-white texte-bienvenue">
        {{ userKeycloakProfil?.name }}
      </p>
    </div>
    <p class="typo-11-white texte-securise">
      Vous êtes sur votre espace personnel et sécurisé
    </p>
  </div>
</div>
