import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakService } from '../keycloak/keycloak.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenizedInterceptorService implements HttpInterceptor {

  constructor(private authService: KeycloakService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.shouldIntercept(request)) {
      return next.handle(request);
    }

    const token = this.authService.getAccessToken();
    if (!token) {
      return next.handle(request);
    }

    if (this.isTokenExpired(token)) {
      return new Observable(observer => {
        this.authService.refreshToken().then((newToken: string) => {
          const modifiedRequest = this.setAuthorizationHeader(request, newToken);
          next.handle(modifiedRequest).subscribe({
            next: event => observer.next(event),
            error: err => observer.error(err),
            complete: () => observer.complete()
          });
        }).catch(error => {
          observer.error(error);
        });
      });
    } else {
      const modifiedRequest = this.setAuthorizationHeader(request, token);
      return next.handle(modifiedRequest);
    }
  }

  private shouldIntercept(request: HttpRequest<any>): boolean {
    const excludedUrls = [
      'apiBasePathDirectus',
      'apiFileBasePathDirectus',
      'upload-file',
      'geoserver',
      'openid-connect/token',
      'openid-connect/logout'
    ];

    return !excludedUrls.some(url => request.url.includes(url));
  }

  private setAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    let auth = `Bearer ${token}`;
    if (request.url.indexOf(environment.directus.apiBasePath) != -1) {
      auth = '';
    }
    return request.clone({
      setHeaders: {
        'content-type': 'application/json',
        Authorization: auth
      }
    });
  }


  public isTokenExpired(token: string): boolean {
    if (!token) {
      return true; // Le jeton est considéré comme expiré s'il est absent
    }
    const tokenPayload = this.authService.parseJwt(token);
    if (!tokenPayload || !tokenPayload.exp) {
      return true; // Le jeton est considéré comme expiré si la date d'expiration est absente
    }
    const expirationTime = tokenPayload.exp * 1000; // Convertir l'expiration en millisecondes
    const currentTime = new Date().getTime();
    return currentTime > expirationTime; // Le jeton est expiré si l'heure actuelle est supérieure à l'heure d'expiration
  }
}
