<!-- ######################  UNIQUEMENT SUR MOBILE  ####################### -->
<ng-container *ngIf="!readonly else readonlyMode">

  <div class="flex-row ion-margin-vertical"
       [ngClass]="{'mobile': plateforme.isMobile()}">
    <ion-fab-button class="icone-telechargement" (click)="prendreUnePhoto()">
      <ion-icon src="./assets/svg/download.svg"></ion-icon>
    </ion-fab-button>
    <div>
      <div class="flex-row">
        <p [ngClass]="{'typo-14': plateforme.isDesktop(), 'typo-11': plateforme.isMobile()}">{{label}}</p>
        <ion-fab-button *ngIf="photos.length !== 0 || nomFichier" class="icone-edition">
          <ion-icon src="./assets/svg/view.svg"
                    [src]="imageVisible ? './assets/svg/view-barre.svg' : './assets/svg/view.svg'"
                    (click)="visualiserFichier()"></ion-icon>
        </ion-fab-button>
        <ion-fab-button *ngIf="photos.length !== 0 || nomFichier" class="icone-edition">
          <ion-icon src="./assets/svg/delete.svg" (click)="supprimerPhoto()"></ion-icon>
        </ion-fab-button>
      </div>
      <div *ngIf="photos.length !== 0 || nomFichier" class="flex-row">
        <p [ngClass]="{'typo-14-green': plateforme.isDesktop(), 'typo-12-green': plateforme.isMobile()}">
          {{nomFichier ? nomFichier : photos[0]?.name}}</p>
        <ion-icon *ngIf="photos[0]?.name || nomFichier"
                  class="icone-vert ion-margin-start"
                  [src]="!invalidFile ? './assets/svg/check_circle.svg' : './assets/svg/cancel_circle.svg'"></ion-icon>
      </div>
      <p *ngIf="invalidFile" class="typo-12-red">
        Fichier invalide : Merci de fournir un fichier au format png ou jpeg de 10Mo maximum.</p>
    </div>

  </div>
  <div>
    <img *ngIf="imageVisible" class="image" src="{{imageSource}}"
         alt="Photo transmise par l'utilisateur">
  </div>
</ng-container>

<!-- MODE READONLY -->
<ng-template #readonlyMode>
  <div class="camera-input-readonly flex-row">
    <p class="typo-14">{{nomFichier}}</p>
    <div class="flex-row-right">
      <ion-icon class="icone-vert" *ngIf="nomFichier" src="./assets/svg/check_circle.svg"></ion-icon>
      <ion-icon class="icone-rouge" *ngIf="!nomFichier" src="./assets/svg/cancel_circle.svg"></ion-icon>
    </div>
  </div>
</ng-template>
