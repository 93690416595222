import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressCircleService } from '../../progress-circle/service/progress-circle.service';
import { PlateformeService } from '../../../../services/plateforme/plateforme.service';
import { ActionPopoverService } from './service/action-popover.service';

@Component({
  selector: 'mclu-popover-creation-compte',
  templateUrl: './popover-creation-compte.component.html',
  styleUrls: ['./popover-creation-compte.component.scss'],
})
export class PopoverCreationCompteComponent implements OnInit {

  /**
   * Constructeur.
   *
   * @param router
   * @param progressService
   * @param plateforme
   */
  @Input() username!: string;
  constructor(private progressService: ProgressCircleService,
    private actionPopoverService: ActionPopoverService,
    public plateforme: PlateformeService) { }

  ngOnInit() {
    this.progressService.emitProgression('100');
  }

  public dismiss(): void {
    this.actionPopoverService.emitActionPopoverSubject();
  }
}
