<div class="popover-erreur-creation-compte-content">
  <p class="typo-18-green ion-text-center">
    Une erreur s'est produite lors de la création de votre compte. <br>
    Veuillez recommencer.
  </p>

  <div class="buttons">
    <ion-button class="typo-16-bold-white ion-text-uppercase" (click)="dismiss()">Fermer</ion-button>
  </div>
</div>
