import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mclu-popover-send-code',
  templateUrl: './popover-send-code.component.html',
  styleUrls: ['./popover-send-code.component.scss'],
})
export class PopoverSendCodeComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
