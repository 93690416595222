import { Component, Input } from '@angular/core';
import { ProgressCircleService } from './service/progress-circle.service';
import { PlateformeService } from '../../../services/plateforme/plateforme.service';

@Component({
  selector: 'mclu-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent {

  @Input()
  public pourcentage: string;

  constructor(private progressService: ProgressCircleService,
              public plateforme: PlateformeService) {
    this.progressService.progression$.subscribe(pourcentage => {
      this.pourcentage = pourcentage;
    });
  }

}
