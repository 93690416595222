<div class="content">
  <div *ngIf="showTitle" class="content-top">
    <div class="notice typo-16-white">étapes</div>
    <div  class="title typo-16-semibold-pro-blue">du dépôt de la demande à l’enregistrement</div>
  </div>
  <div class="content-bottom"  *ngIf="etat!==5">
    <div [ngClass]="{'item-number-select': idEtape===1}" class="item-number typo-20-bold-orange">1</div>
    <div [ngClass]="{'item-text-select': idEtape===1}" class="typo-16">Formulaire</div>
    <div class="item_separator"></div>
    <div [ngClass]="{'item-number-select': idEtape===2}" class="item-number typo-20-bold-orange">2</div>
    <div [ngClass]="{'item-text-select': idEtape===2}" class="typo-16">Documents requis</div>
    <div class="item_separator"></div>
    <div [ngClass]="{'item-number-select': idEtape===3}" class="item-number typo-20-bold-orange">3</div>
    <div [ngClass]="{'item-text-select': idEtape===3}" class="typo-16">Paiement</div>
    <div class="item_separator"></div>
    <div [ngClass]="{'item-number-select': idEtape===4}" class="item-number typo-20-bold-orange">4</div>
    <div [ngClass]="{'item-text-select': idEtape===4}" class="typo-16">Vérification</div>
    <div class="item_separator"></div>
    <div [ngClass]="{'item-number-select': idEtape===5}" class="item-number typo-20-bold-orange">5</div>
    <div [ngClass]="{'item-text-select': idEtape===5}" class="typo-16">Enregistrement</div>
  </div>

  <div class="content-bottom_lecture"  *ngIf="etat===5">
    <div [ngClass]="{'item-number-select': idEtape===1}" class="item-number typo-20-bold-orange">1</div>
    <div [ngClass]="{'item-text-select': idEtape===1}" class="typo-16">Formulaire</div>
    <div class="item_separator"></div>
    <div [ngClass]="{'item-number-select': idEtape===2}" class="item-number typo-20-bold-orange">2</div>
    <div [ngClass]="{'item-text-select': idEtape===2}" class="typo-16">Documents requis</div>
    <div class="item_separator"></div>
    <div [ngClass]="{'item-number-select': idEtape===3}" class="item-number typo-20-bold-orange">3</div>
    <div [ngClass]="{'item-text-select': idEtape===3}" class="typo-16">Paiement</div>
    <div class="item_separator"></div>
    <div [ngClass]="{'item-number-select': idEtape===4}" class="item-number typo-20-bold-orange">4</div>
    <div [ngClass]="{'item-text-select': idEtape===4}" class="typo-16">Vérification</div>
    <div class="item_separator"></div>
    <div [ngClass]="{'item-number-select': idEtape===5}" class="item-number typo-20-bold-orange">5</div>
    <div [ngClass]="{'item-text-select': idEtape===5}" class="typo-16">Enregistrement</div>
  </div>

</div>
