/**
 * SIGFU REST API
 * Serveur d\'api en NodeJs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface QuestionnaireSatisfactionDto { 
    nom: string;
    prenom: string;
    email: string;
    numero: string;
    commentaire: string;
    typeUser: string;
    Q1: string;
    Q2: string;
    Q3: string;
    Q4: string;
    Q5: string;
}

