/**
 * SIGFU REST DATA API
 * Serveur d\'api en NodeJs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface QueryLotDto { 
    id: string;
    allotment_name: string;
    block_number: string;
    lot_number: string;
    label: string;
    locality: string;
    locality_display: string;
    locality_type: string;
    survey_area: string;
    survey_area_unit_type: string;
    survey_area_unit_type_display: string;
    lot_sub_type: string;
    land_circonscription_display: string;
    suspension: string;
    suspension_display: string;
    approval_order: string;
    approval_order_date: string;
    lotissement_sub_type: string;
    idufci: string;
}

