import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AdministrateursService, UsersService } from 'openapi/build';
import { KeycloakService } from 'src/app/services/keycloak/keycloak.service';
import { PlateformeService } from 'src/app/services/plateforme/plateforme.service';
import { AbstractHeader } from '../header/abstract-header';

@Component({
  selector: 'mclu-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent extends AbstractHeader implements OnInit {

  @Input()
  public currentPage:
    | 'suivi-public'
    | 'accueil'
    | 'services'
    | 'demandes'
    | 'parcelles'
    | 'demandes-connecte'
    | 'creationprofil';

  /**
   * Constructeur.
   * @param keycloakService
   * @param utilisateurService
   * @param router
   */
  constructor(
    public keycloakService: KeycloakService,
    public administrateursService: AdministrateursService,
    public plateformeService: PlateformeService,
    public readonly router: Router,
    public usersService: UsersService,
    public popoverController: PopoverController
  ) {
    super(keycloakService, router, administrateursService, plateformeService, usersService, popoverController);
  }

  async ngOnInit() {
    this.getProfil();
  }

  public connection(): void {
    this.router.navigateByUrl('/login');
  }
}
