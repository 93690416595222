import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[mcluPrenom]',
})
export class PrenomDirective {
  constructor() {}


  @HostListener('ionBlur', ['$event']) onIonBlur(event) {
    event.target.value = event.target.value.trim();
  }


  @HostListener('ionChange', ['$event']) onIonChange(event) {
    //Pas de caratere "-" ou " " en premier
    if (event.target.value.toString().length === 0) {
      return;
    }

    if (
      event.target.value[0].toUpperCase() === ' ' ||
      event.target.value[0].toUpperCase() === '-'
    ) {
      event.target.value = event.target.value.substring(1);
    }

    //Suppression des caracteres non authorisés
    // event.target.value = event.target.value.replace(
    //   /[^- a-zA-Zçàâäéèêëôöï]*/g,
    //   ''
    // );
    //Premier caractère en majuscule
    event.target.value =
      event.target.value[0].toUpperCase() +
      event.target.value.slice(1).toLowerCase();

    //Ne garder que le premier tiret
    if (event.target.value.indexOf('-') > 0) {
      event.target.value =
        event.target.value.substring(0, event.target.value.indexOf('-') + 1) +
        event.target.value
          .substring(event.target.value.indexOf('-') + 1)
          .replace('-', '');
      event.target.value = event.target.value.replace(' ', '');

      const ioChar = event.target.value.indexOf('-');
      if (event.target.value.toString().length > ioChar + 1) {
        let suffixe = '';
        if (event.target.value.toString().length > ioChar + 2) {
          suffixe = event.target.value.substring(ioChar + 2);
        }

        event.target.value =
          event.target.value.substring(0, ioChar + 1) +
          event.target.value.substring(ioChar + 1, ioChar + 2).toUpperCase() +
          suffixe;
      }
    }

    //Ou espace (2 max) si pas de tiret
    if (event.target.value.indexOf(' ') > 0) {
      let nbEspace = event.target.value.split(' ').length - 1;
      if (nbEspace > 1) {
        nbEspace = 2;
      }

      event.target.value =
        event.target.value.substring(
          0,
          event.target.value.split(' ', nbEspace).join(' ').length + 1
        ) +
        event.target.value
          .substring(
            event.target.value.split(' ', nbEspace).join(' ').length + 1
          )
          .replace(' ', '');

      for (let i = 0; i < nbEspace; i++) {
        const ioChar = event.target.value.split(' ', i + 1).join(' ').length;

        if (event.target.value.toString().length > ioChar + 1) {
          let suffixe = '';
          if (event.target.value.toString().length > ioChar + 2) {
            suffixe = event.target.value.substring(ioChar + 2);
          }

          event.target.value =
            event.target.value.substring(0, ioChar + 1) +
            event.target.value.substring(ioChar + 1, ioChar + 2).toUpperCase() +
            suffixe;
        }
      }
    }
  }
}
