/**
 * SIGFU REST API
 * Serveur d\'api en NodeJs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InnolaDictionaryDto { 
    amount: number;
    versionRev: number;
    value: string;
    manager: string;
    category: string;
    code: string;
    kind: string;
    valueShort: string;
    hidden: boolean;
    sortorder: number;
    incomingDocument: string;
    primaryDocument: string;
    normalDuration: number;
    mandatoryDocument: string;
    displayGroup: string;
    displayDescription: string;
    displayValueShort: string;
    displayValue: string;
    parentCode: string;
    transaction: string;
    description: string;
    outgoingDocument: string;
    hiddenGateway: boolean;
}

