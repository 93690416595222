import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mclu-depot-ligne-notice',
  templateUrl: './depot-ligne-notice.component.html',
  styleUrls: ['./depot-ligne-notice.component.scss'],
})
export class DepotLigneNoticeComponent implements OnInit {

  @Input()
  public showTitle=false;

  @Input()
  public idEtape=0;

  @Input()
  public etat=0;

  constructor() { }

  ngOnInit() {}

}
