<div *ngIf="!readonly" [ngClass]="{'ion-justify-content-center': plateforme.isMobile()}" class="ion-margin-vertical container">
  <mclu-bouton-carre-icone *ngFor="let bouton of boutons"
                           [icone]="bouton.icone"
                           [libelle]="bouton.libelle"
                           [isSelected]="bouton.isSelected"
                           [isEnabled]="bouton.isEnabled"
                           [greenBorder]="greenBorder"
                           [yellowBG]="yellowBG"
                           (selectButton)="changerSelection(bouton.icone)"></mclu-bouton-carre-icone>
</div>
<ion-item *ngIf="readonly" class="generic-input" lines="none">
  <ion-label position="floating">{{libelle}}</ion-label>
  <ion-input class="inputCustomGeneric"
             [ngClass]="{'readonly': readonly}"
             [readonly]="readonly"
             [value]="selectedButton.libelle">
    <ion-icon slot="start" src="./assets/svg/{{selectedButton.icone}}.svg"></ion-icon>
  </ion-input>
</ion-item>
